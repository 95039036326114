@import (once) url('rootImports.less');

.language-switch {
  position: relative;
  cursor: pointer;
  display: inline;

  .language-switch-button {
    padding: 9px 16px;
    background-color: @white;
    color: @brown-dark;
    border-radius: 20px;
    display: inline-flex;
    align-items: center;
    font-weight: 600;
    border: solid 1px @dark-grey;
    padding-left: 40px;
    background-position: 15px 140%;
    background-size: 30px 30px;
    background-repeat: no-repeat;
    span {
      margin-right: 0.4em;
    }
  }

  .language-switch-options {
    @border-color: rgba(0, 0, 0, 0.2);
    @box-shadow-params: 0 1px 5px rgba(0, 0, 0, 0.3);

    position: absolute;
    background-color: @white;
    box-shadow: @box-shadow-params;
    padding-top: 8px;
    padding-bottom: 8px;
    right: 4px;
    bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    border: 1px solid @border-color;
    border-radius: 4px;

    &.expand-down {
      bottom: unset;
      top: 50px;
    }

    // Give arrow a shadow if the browser supports it, box-shadow ignores pseudo-elements
    @supports (filter: drop-shadow(@box-shadow-params)) {
      filter: drop-shadow(@box-shadow-params);
      box-shadow: none;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
    }

    // Add border around arrow
    &::before {
      right: 7px;
      width: 0;
      height: 0;
      border: 11px solid transparent;
    }
    &:not(.expand-down):before {
      margin-bottom: -11px;
      bottom: 0;
      border-bottom: 0;
      border-top-color: @border-color;
    }

    &.expand-down:before {
      bottom: unset;
      top: -11px;
      border-bottom-color: @border-color;
      border-top: 0;
    }

    // White part of arrow
    &::after {
      right: 8px;
      width: 0;
      height: 0;
      border: 10px solid transparent;
    }
    &:not(.expand-down):after {
      border-top-color: #fff;
      bottom: 0;
      border-bottom: 0;
      margin-bottom: -10px;
    }

    &.expand-down:after {
      bottom: unset;
      border-top: 0;
      border-bottom-color: #fff;
      top: -10px;
    }

    z-index: 1000001; // Ensures language picker falls over chat button.
  }

  .language {
    width: 100%;
    padding-left: 1em;
    padding-right: 1em;
    padding-top: 8px;
    padding-bottom: 8px;
    color: @brown-dark;

    &:hover {
      background-color: @white-2;
    }
  }

  .language-selected {
    color: @blue;
  }

  .picker-chevron {
    transition: transform 0.1s ease-in-out;
    margin: auto;

    path {
      fill: @brown-dark;
    }
  }

  &.expanded {
    .picker-chevron {
      transform: translateY(-1px) scaleY(-1);
    }
  }

  @media (min-width: @mq-md-min) {
    display: flex;
    right: unset;
  }
}

@assetsUrl: /search/public;