@import (once) url('rootImports.less');

@media (min-width: @mq-md-min) {
  .login-page {
    padding-top: 6rem;
  }
}

.require-login-message {
  text-align: center;
  margin: 2rem 0;
}

.login-select-application {
  display: flex;
  max-width: 1000px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  &.stack {
    width: 100%;

    @media (min-width: @mq-sm-min) {
      margin-left: -20px;

      > * {
        margin-left: 20px;
      }
    }
  }

  .button-bar {
    display: flex;
    flex-direction: column;

    &.stack > * {
      margin-bottom: 1rem;
    }

    @media (min-width: @mq-xs-min) {
      flex-direction: row;
      justify-content: center;

      .button-grow {
        flex-grow: 1;
      }

      &.stack > * {
        margin: 0 1rem;
      }
    }
  }
}

@media (min-width: @mq-sm-min) {
  .login-options {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    width: 700px;
  }

  .align-end {
    align-items: flex-end;
  }
}

.back-link {
  text-align: center;
}

@assetsUrl: /search/public;