.component-container.component {
  .drag-question-object-outline,
  .drag-question-object-grippy,
  .dropzone-object-outline,
  .dropzone-object-grippy {
    z-index: unset;
    pointer-events: none;
  }

  &.is-thumb {
    .drag-question-object-outline,
    .dropzone-object-outline {
      left: -1px;
      top: -1px;
      right: -1px;
      bottom: -1px;
      border-width: 1px;
      border-radius: 2px;
    }

    .drag-question-object-grippy,
    .dropzone-object-grippy {
      width: 14px;
      height: 14px;
      background-size: 90% auto;
      border-width: 2px;
      cursor: default;
      background-size: contain;
    }
  }
}

@assetsUrl: /search/public;