@import (once) url('rootImports.less');

.scale-wrapper {
  .scale-input-container {
    width: 60%;
    margin: 0 auto;
    text-align: center;
    position: relative;
    @media (max-width: @mq-md-min) {
      width: 80%;
    }

    .scale-marker-container {
      position: relative;
      margin: 0 auto;
      height: 0.6em;
      display: block;
      width: ~'calc(100% - 2em)';
      margin-right: 1em;
      left: 0.5em;
      margin-bottom: 5%;
    }
    .scale-marker-homework {
      font-size: 0.7em;
      width: ~'calc(100% - 1.7em)';
    }
    .scale-marker {
      position: absolute;
      width: 1em;
      left: 50%;
      font-size: 1em;
      transform: translateX(-100%);
      transition: ease-in;
    }
    .scale-marker-height {
      height: 1em;
    }
    .scale-label-wrapper {
      position: relative;
      width: 100%;
      height: 1em;
      margin-top: 2%;
      .scale-label {
        font-size: 0.6em;
        position: absolute;
        opacity: 0.7;
      }
      .scale-min {
        left: 0;
        transform: translateX(-50%);
      }
      .scale-max {
        right: 0;
        transform: translateX(50%);
      }
    }
  }
  .scale-fontsize-fix {
    input[type='range'].poll-scale {
      font: inherit;
    }
  }
  .scale-button.event-loading {
    padding-left: 60px;
  }
  .scale-button.event-loading:after {
    position: absolute;
    left: 5px;
    right: 0;
    top: 0;
    height: 100%;
    width: 50px;
    content: '';
    background-image: url('@{assetsUrl}/img/rocket4.png') !important;
    background-repeat: no-repeat;
    background-position: 7px center;
    background-size: 30px auto;
    -webkit-animation: fly-small infinite 2s ease-in-out;
    -moz-animation: fly-small infinite 2s ease-in-out;
    -ms-animation: fly-small infinite 2s ease-in-out;
    animation: fly-small infinite 2s ease-in-out;
  }
  .scale-button {
    background-repeat: no-repeat;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: content-box;
    position: relative;
    text-align: right;
    background-position: 7px center;
    background-size: 35px auto;
    width: auto;
    display: inline-block;
    height: 60px;
    background-color: @yellow;
    color: @brown-dark;
    font-size: 17px;
    text-decoration: none;
    line-height: 60px;
    border-radius: 4px;
    border: none;
    border-bottom: 2px solid rgba(0, 0, 0, 0.5);
    cursor: pointer;

    @media (max-width: @mq-sm-min) {
      padding-left: 12px;
      padding-right: 12px;
      height: 37px;
      font-size: 15px;
      line-height: 37px;
      background-size: 20%;

      &:active {
        height: 37px;
      }

      &.event-loading {
        padding-left: 50px;

        &:after {
          background-size: 25px auto;
        }
      }
    }
  }
  .scale-button:disabled {
    opacity: 0.75;
    cursor: auto;
  }
}

//Fun IE stuff here
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .scale-wrapper {
    .scale-input-container {
      .scale-min,
      .scale-max {
        bottom: -20px;
      }
    }
  }
}

@assetsUrl: /search/public;