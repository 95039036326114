@-webkit-keyframes fly {
  0% {
    -webkit-transform: translate(0, 0);
  }
  25% {
    -webkit-transform: translate(-2%, 2%);
  }
  50% {
    -webkit-transform: translate(-2%, -2%);
  }
  75% {
    -webkit-transform: translate(2%, 2%);
  }
  200% {
    -webkit-transform: translate(0, 0);
  }
}
@-moz-keyframes fly {
  0% {
    -moz-transform: translate(0, 0);
  }
  25% {
    -moz-transform: translate(-2%, 2%);
  }
  50% {
    -moz-transform: translate(-2%, -2%);
  }
  75% {
    -moz-transform: translate(2%, 2%);
  }
  200% {
    -moz-transform: translate(0, 0);
  }
}
@-ms-keyframes fly {
  0% {
    -ms-transform: translate(0, 0);
  }
  25% {
    -ms-transform: translate(-2%, 2%);
  }
  50% {
    -ms-transform: translate(-2%, -2%);
  }
  75% {
    -ms-transform: translate(2%, 2%);
  }
  200% {
    -ms-transform: translate(0, 0);
  }
}
@keyframes fly {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(-2%, 2%);
  }
  50% {
    transform: translate(-2%, -2%);
  }
  75% {
    transform: translate(2%, 2%);
  }
  200% {
    transform: translate(0, 0);
  }
}

@-webkit-keyframes flyhard {
  0% {
    -webkit-transform: translate(0, 0);
  }
  25% {
    -webkit-transform: translate(-8%, 8%);
  }
  50% {
    -webkit-transform: translate(-8%, -8%);
  }
  75% {
    -webkit-transform: translate(8%, 8%);
  }
  200% {
    -webkit-transform: translate(0, 0);
  }
}
@-moz-keyframes flyhard {
  0% {
    -moz-transform: translate(0, 0);
  }
  25% {
    -moz-transform: translate(-8%, 8%);
  }
  50% {
    -moz-transform: translate(-8%, -8%);
  }
  75% {
    -moz-transform: translate(8%, 8%);
  }
  200% {
    -moz-transform: translate(0, 0);
  }
}
@-ms-keyframes flyhard {
  0% {
    -ms-transform: translate(0, 0);
  }
  25% {
    -ms-transform: translate(-8%, 8%);
  }
  50% {
    -ms-transform: translate(-8%, -8%);
  }
  75% {
    -ms-transform: translate(8%, 8%);
  }
  200% {
    -ms-transform: translate(0, 0);
  }
}
@keyframes flyhard {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(-8%, 8%);
  }
  50% {
    transform: translate(-8%, -8%);
  }
  75% {
    transform: translate(8%, 8%);
  }
  200% {
    transform: translate(0, 0);
  }
}

@-webkit-keyframes fly-small {
  0% {
    -webkit-transform: translate(0, 0);
  }
  25% {
    -webkit-transform: translate(-1px, 1px);
  }
  50% {
    -webkit-transform: translate(-1px, -1px);
  }
  75% {
    -webkit-transform: translate(1px, 1px);
  }
  200% {
    -webkit-transform: translate(0, 0);
  }
}
@-moz-keyframes fly-small {
  0% {
    -moz-transform: translate(0, 0);
  }
  25% {
    -moz-transform: translate(-1px, 1px);
  }
  50% {
    -moz-transform: translate(-1px, -1px);
  }
  75% {
    -moz-transform: translate(1px, 1px);
  }
  200% {
    -moz-transform: translate(0, 0);
  }
}
@-ms-keyframes fly-small {
  0% {
    -ms-transform: translate(0, 0);
  }
  25% {
    -ms-transform: translate(-1px, 1px);
  }
  50% {
    -ms-transform: translate(-1px, -1px);
  }
  75% {
    -ms-transform: translate(1px, 1px);
  }
  200% {
    -ms-transform: translate(0, 0);
  }
}
@keyframes fly-small {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(-1px, 1px);
  }
  50% {
    transform: translate(-1px, -1px);
  }
  75% {
    transform: translate(1px, 1px);
  }
  200% {
    transform: translate(0, 0);
  }
}

@assetsUrl: /search/public;