@import (once) url('rootImports.less');

@left-offset: 25%;

.upsell-modal {
  .teacher-image {
    position: absolute;
    bottom: 0;
    height: 50%;
    max-height: 200px;

    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    right: 0;
  }

  .upsell-checklist {
    padding-inline-start: 0;
  }

  .modal__content {
    min-height: 250px;
  }

  @media (min-width: @mq-sm-min) {
    overflow: visible;

    .teacher-image {
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
      right: auto;

      max-height: 300px;
    }

    .modal-title-content {
      margin-left: @left-offset;
    }

    .upsell-content {
      margin-left: @left-offset;

      .subcontent {
        margin-left: @left-offset * 1.5;
      }
    }

    .teacher-image {
      left: -35px;
      height: 110%;
      max-height: none;
    }
  }
}

@assetsUrl: /search/public;