@import (once) url('rootImports.less');

@bottomBarHeight: (100% / 6);

.pin-thumb-bottom-bar {
  bottom: 0;
  display: flex;
  margin-bottom: 8px;
  margin-left: 8px;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;

  &.content-align-start {
    justify-content: flex-start;
  }
}

@assetsUrl: /search/public;