@import (once) url('./pin-mixins.less');

@pin-ratio: (10 ./ 16) * 9;
// this is the total margin around the pin flow
// so gutter of 30 = left/right 30*2 = 60
.pin-flow(@outer-margins, @pin-view-border: 1px) {
  justify-content: space-between;
  margin: -10px;

  .pin-wrapper {
    display: block;
    margin: 10px;
  }

  @small-breakpoint: @outer-margins + 490px;
  @small0: @outer-margins + 150;
  @baseVW: 0.97vw;

  @media (max-width: @small-breakpoint) {
    width: 100%;

    .pin-view {
      width: 100%;
      padding-top: 56.25%;
      font-size: @pin-ratio * (@baseVW - @outer-margins / @small0);
    }
  }

  @small1: @outer-margins + 200;
  @media (min-width: @small1 * 1px) {
    .pin-view {
      font-size: @pin-ratio * (@baseVW - @outer-margins ./ @small1);
    }
  }

  @small2: @outer-margins + 290;
  @media (min-width: @small2 * 1px) {
    .pin-view {
      font-size: @pin-ratio * (@baseVW - @outer-margins ./ @small2);
    }
  }

  @small3: @outer-margins + 340;
  @media (min-width: @small3 * 1px) {
    .pin-view {
      font-size: @pin-ratio * (@baseVW - @outer-margins ./ @small3);
    }
  }

  .pin-sizing(355, 325,@pin-view-border, 0);
  .pin-sizing(225, 200,@pin-view-border, @small-breakpoint);
  .pin-sizing(250, 225,@pin-view-border, @small-breakpoint + 540px);
  .pin-sizing(275, 250,@pin-view-border, @small-breakpoint + 590px);
  .pin-sizing(300, 275,@pin-view-border, @small-breakpoint + 640px);
  .pin-sizing(350, 325,@pin-view-border, @small-breakpoint + 740px);
  .pin-sizing(250, 225,@pin-view-border, @small-breakpoint + 810px);
  .pin-sizing(275, 250,@pin-view-border, @small-breakpoint + 890px);
  .pin-sizing(250, 225,@pin-view-border, @small-breakpoint + 1110px);
  .pin-sizing(275, 250,@pin-view-border, @small-breakpoint + 1200px);
}
