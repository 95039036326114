@import (once) url('rootImports.less');

@spacing: 0.5rem;

.gray-page-layout {
  background-color: @surface;
  height: auto;
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  overflow-x: hidden;
  height: auto;
  flex-direction: column;
  justify-content: space-around;
  padding: @search-results-outer-padding-mobile @search-results-outer-padding-mobile 3rem;

  @media (min-width: @mq-md-min) {
    align-items: center;
    padding: 6rem @search-results-outer-padding-desktop @search-results-outer-padding-desktop;
  }

  .registration-title {
    text-align: center;

    & > *:not(:last-child) {
      margin-bottom: @spacing;
    }
  }

  .gray-page-title {
    margin-top: 0;
    text-align: center;
    font-size: 1.5rem;
  }

  .gray-page-subtitle {
    text-align: center;
    display: block;
  }

  .gray-page-section {
    display: flex;
    flex-direction: column;

    &.gray-page-section-top {
      justify-content: center;
    }

    &.gray-page-section-center {
      justify-content: flex-start;
      flex-grow: 1;
    }

    &.gray-page-section-bottom {
      justify-content: center;
      align-items: center;
    }

    &.grow {
      height: auto;
      align-items: center;
    }

    &.full-width {
      width: 100%;
    }
  }
}

@assetsUrl: /search/public;