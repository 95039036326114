@import (once) url('rootImports.less');

.condensed-explorer {
  padding-top: 0;
  &__breadcrumbs {
    position: sticky;
    top: 0;
    background-color: @white;
    z-index: 1;
  }
}

@assetsUrl: /search/public;