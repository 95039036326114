.all-images(@url) {
  .image-teacher-expired {
    background-image: url('@{assetsUrl}/svg/teacher-expired.svg');
  }

  .image-teacher-renewed {
    background-image: url('@{assetsUrl}/svg/teacher-renewed.svg');
  }

  .image-teacher-sad {
    background-image: url('@{assetsUrl}/svg/teacher-sad.svg');
  }
}
