@spacing: 0.3rem;

.folder-info {
  padding: 2 * @spacing;

  .actions {
    margin-top: @spacing * -1;
    & > * {
      margin-top: @spacing;
    }
  }

  .title {
    margin-top: 0;
    margin-bottom: @spacing * 2;
  }
}

@assetsUrl: /search/public;