@import (once) url('rootImports.less');
@import (once) url('./LessonPlanRoot.less');

.lesson-plan-folder {
  margin-right: 0;
  width: 100%;
  .visual {
    position: relative;
    width: 100%;
    background-color: @cyan;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
    z-index: 1;
    height: 142px;
    cursor: pointer;
  }
  .hover-el {
    position: absolute;
    left: 0;
    top: 0;
    width: 15%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }
  .hover-el::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url(../img/icons/white/arrow-right2.png) no-repeat center center;
    background-size: 40% auto;
  }
  &:hover .hover-el {
    transform: translateX(0);
  }
  .lesson-count {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 8px 16px;
    color: @white;
    background: rgba(0, 0, 0, 0.8);
    font-size: 14px;
    font-family: @lessonup-body-new, @system-font-stack!important;
    p {
      margin: 0;
    }
  }
  &.no-series-buttons {
    margin-right: 40px;

    .folder-info {
      .title,
      .description {
        margin-left: -0.6rem;
      }
    }
  }
  &.show-series-buttons {
    display: flex;
    flex-direction: column;
    margin: 0 0 50px;
    background-color: #eee;
    border: solid #ccc 2px;
    border-radius: 6px;
    overflow: hidden;
    width: 100%;

    @media (min-width: @mq-sm-min) {
      flex-direction: row;
      width: 100%;

      .visual {
        min-width: 288px;
        width: 288px;
        min-height: 212px;
        height: auto;
      }
      .info {
        padding-left: 15px;
      }
    }
    .actions {
      background: #eee;
    }
    &:hover {
      border-color: transparent;
    }
  }
  .info,
  .folder-info {
    overflow: hidden;
    .title {
      font-family: @lessonup-body-new;
      font-weight: bold;
      font-size: 16px;
      margin-bottom: 6px;
      color: @brown-dark;
      .line-clamp(1.2em, 3);
    }
    .description {
      font-family: @lessonup-body-new;
      font-size: 14px;
      margin-bottom: 8px;
      color: @brown-dark;
      max-height: 128px;
      overflow: hidden;
    }
  }

  .lesson-plan-folder {
    width: 100%;
    float: left;
    display: block;
    @media (min-width: @mq-xs-min) {
      width: 47%;
      margin-right: 10px;
    }
    @media (min-width: @mq-sm-min) {
      width: @column;
      margin-right: 40px;
    }
    .plan-folder {
      text-decoration: none;
    }
  }
}
.locked-folder {
  .lesson-folder {
    .hover-el {
      position: relative;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.6);
      background-size: 70px auto;
      -webkit-transform: none;
      -moz-transform: none;
      -ms-transform: none;
      -o-transform: none;
      transform: none;
      -webkit-transition: none;
      -moz-transition: none;
      -ms-transition: none;
      -o-transition: none;
      transition: none;
    }
    .hover-el::after {
      -webkit-transform-origin: center center center;
      -moz-transform-origin: center center center;
      -ms-transform-origin: center center center;
      -o-transform-origin: center center center;
      transform-origin: center center center;
      -webkit-transition: all 0.3s ease-out;
      -moz-transition: all 0.3s ease-out;
      -ms-transition: all 0.3s ease-out;
      -o-transition: all 0.3s ease-out;
      transition: all 0.3s ease-out;
      background: url(../img/icons/white/lock.png) no-repeat center 40%;
      background-size: 70px auto;
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }
    .hover-el:hover::after {
      -webkit-transform: translateX(0);
      -moz-transform: translateX(0);
      -ms-transform: translateX(0);
      -o-transform: translateX(0);
      background-size: 90px auto;
    }
  }
}

@assetsUrl: /search/public;