@import (once) url('rootImports.less');

.select-option-container {
  display: flex;
  justify-content: 'space-between';
  font-family: @lessonup-body-new;
}

.select-option-container__label {
  flex: 1;

  @media (min-width: @search-sidebar-desktop-min) {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-wrap: break-word;
  }
}

.select-option-container__count {
  vertical-align: middle;
  font-size: 0.7em;
  color: @cyan;
}

.select-style-capsule__control {
  cursor: pointer;
}

.react-select--is-disabled .react-select__control--is-disabled {
  background-color: @grey-bg;
  .react-select__placeholder,
  .select-option-chevron {
    color: @silver;
  }
}

.select-option-chevron {
  width: 28px;
  color: @brown-dark;
  display: flex;
  align-items: center;
  height: 100%;
}

.facet-close {
  margin-right: 5px;
  height: 26px;
  width: 26px;
  padding: 0.4em;
  fill: @brown-dark;
  background-color: @grey-bg;
  border-radius: 100%;
  cursor: pointer;
}

@assetsUrl: /search/public;