@import (once) url('rootImports.less');

.search-explanation-box {
  background-color: rgba(255, 255, 255, 0.3);
  color: @brown-dark;
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;

  .search-explanation-box__link {
    font-weight: bold;
  }
}

.search-explanation-item {
  margin-top: 5px;

  ol {
    list-style-type: initial;
  }
}

@assetsUrl: /search/public;