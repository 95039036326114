@import (once) url('_imports.less');

.text,
.component-text {
  column-gap: 0.8em;
  &.text-columns-1 {
  }
  &.text-columns-2 {
    .columns(2);
  }
  &.text-columns-3 {
    .columns(3);
  }
  &.text-columns-4 {
    .columns(4);
  }
  &.text-columns-5 {
    .columns(5);
  }
  &.text-columns-6 {
    .columns(6);
  }
  &.text-columns-7 {
    .columns(7);
  }
  &.text-columns-8 {
    .columns(8);
  }
}

@assetsUrl: /search/public;