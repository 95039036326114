@import (once) url('rootImports.less');

.cookie-popup-modal {
  font-family: @lessonup-body-new;
  color: @default-text;
}

.popup-button-wrapper {
  width: 100%;
  box-sizing: border-box;
  .cookie-button {
    padding: 1em;
    margin: 0;
    font-size: 0.9em;
    font-weight: bold;
    margin: 0 auto;
  }
  .change-cookies {
    display: inline-block;
    padding-left: 0;
    width: 60%;
    border: none;
    color: @grey-light2;
    background-color: white;
  }
  .accept-all {
    box-shadow: none;
    display: inline-block;
    cursor: pointer;
    border: none;
    width: 40%;
    border-radius: 0.5em;
    font-size: 0.9em;
    font-weight: bold;
  }
}

@assetsUrl: /search/public;