@import (once) url('rootImports.less');

@spacing: 0.5rem;
@max-width: 500px;

.school-picker-page {
  .reset-margin;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: @max-width;

  & > *:not(:last-child) {
    margin-bottom: @spacing * 4;
  }

  &__content > *:not(:first-child) {
    margin-top: 4 * @spacing;
    margin-bottom: 0;
  }

  &__heading {
    .reset-margin;
    line-height: 1.5;
    text-align: center;
  }

  &__description {
    .reset-margin;
    line-height: 1.5;
    margin-top: 0.5rem;
    margin-bottom: 0;
    text-align: center;
  }

  &__benefits-list {
    margin: 0 auto;
    width: fit-content;
    padding-top: @spacing / 2;

    li {
      padding-top: @spacing / 2;
      padding-bottom: @spacing / 2;
    }
  }

  &__button {
    height: @spacing * 5;
    text-align: center;

    span {
      margin: auto;
    }
  }

  &__input {
    width: 100%;
  }

  &__switch {
    justify-content: space-between;
  }
}

.reset-margin {
  margin: 0 !important;
}

.message {
  font-weight: @spacing * 2;
  text-align: center;
  font-family: @lessonup-body-new;

  &--error {
    color: @red;
  }
}

@assetsUrl: /search/public;