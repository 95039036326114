@import (once) url('rootImports.less');
@import (once) url('../../_imports.less');

@home-icon-container-margin: 1rem;
@home-button-size: @breadcrumb-bar-height - 2 * @home-icon-container-margin;

.explorer-breadcrumbs {
  display: flex;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  font-size: 1.1rem;
  color: @grey-mid-3;
  font-family: @lessonup-body-new;
  height: @breadcrumb-bar-height;
  &__shared-explorer-icon {
    margin-right: 1rem;
    width: 2.5rem;
    margin-top: 0.4rem; // the icon is not centered correctly
  }
  &__shared-folder-link {
    font-family: @lessonup-body-new;
    font-size: 0.8rem;
  }
}

.explorer-breadcrumb-wrapper {
  width: @home-button-size;
  height: @home-button-size;
  padding: 0.5rem;
  border-radius: 0.2rem;
  border: none;
  background-color: @white;
  display: flex;
  align-items: center;
  justify-content: center;
  &__icon {
    fill: @grey-mid-3;
    width: 1.8rem;
    height: 1.8rem;
  }
}

button.explorer-breadcrumb-wrapper {
  &:hover {
    cursor: pointer;
    background-color: @silver;
  }
}
div.explorer-breadcrumb-wrapper .explorer-breadcrumb-wrapper__icon {
  fill: @brown-dark;
}

.explorer-breadcrumbs,
.explorer-breadcrumbs-home-button {
  &__chevron-icon {
    fill: @grey-mid-3;
    height: 1rem;
    width: 1rem;
    overflow: visible;
    transform: rotate(270deg);
  }
}

@assetsUrl: /search/public;