@import (once) url('rootImports.less');

.reset-password-page {
  background-color: @white-2;
  padding: 1em;
  height: ~'calc(100vh - @{navbar-height})';
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .reset-password-title {
    .lessonup-title-1;
    text-align: center;
  }

  .reset-password-inner {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 800px;
    padding: 1em;
    margin: 1em;
  }

  .reset-password-description {
    text-align: center;
  }

  .reset-password-form {
    width: 50%;
  }

  .button-bar {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .input__error {
    max-height: unset;
  }
}

@assetsUrl: /search/public;