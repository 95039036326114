@import (once) url('rootImports.less');

@input-spacing: 20px;
@voucher-prefix-width: 45px;
@border-radius-voucher-input: 9px;
@letter-spacing: 5px;

.voucher-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.default-form .voucher-input {
  margin: @input-spacing;
  font-family: @lessonup-monospace;

  .input-width-for-char-length(20);

  .voucher-prefix {
    height: 40px;
    width: @voucher-prefix-width;
    padding-left: 10px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 600;
    z-index: 1;
    border-top-left-radius: @border-radius-voucher-input;
    border-bottom-left-radius: @border-radius-voucher-input;
    background-color: @cyan;
    color: #fff;
  }

  input[type='text'] {
    padding-left: @voucher-prefix-width + @letter-spacing;
    padding-right: @letter-spacing * 2;
    width: 100%;
    font-family: @lessonup-monospace;
    letter-spacing: @letter-spacing;
    border-radius: @border-radius-voucher-input;

    &::placeholder {
      font-family: @lessonup-monospace;
      font-size: 1rem;
    }
  }

  &.has-error {
    .error-animation(#fff, currentColor);
  }
}

.input-width-for-char-length(@number-of-chars) {
  .span-loop (@i) when (@i > 0) {
    &.input-length-@{i} {
      width: ~'calc(@{voucher-prefix-width} + (@{i}ch * 1.162) + (@{i} * @{letter-spacing}) + (@{letter-spacing} * 3) + 2px)'; // 2px margin provides room for the cursor at the end.

      // The ch unit behaves differently in IE11.
      // Through this support check, I'm separating IE11 from other browsers (https://stackoverflow.com/questions/17825638/css3-ch-unit-inconsistent-between-ie9-and-other-browsers)
      @supports (-webkit-line-clamp: 1) {
        width: ~'calc(@{voucher-prefix-width} + (@{i} * 1ch) + (@{i} * @{letter-spacing}) + (@{letter-spacing} * 3) + 2px)'; // 2px margin provides room for the cursor at the end.
      }
    }

    .span-loop(@i - 1);
  }

  .span-loop(@number-of-chars);
}

.error-animation(@background-color, @color, @ms: 0.4s) {
  .voucher-prefix,
  input {
    animation: shakeOnError @ms normal;
  }

  // Courtesy of https://css-tricks.com/snippets/css/shake-css-keyframe-animation/
  @keyframes shakeOnError {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
      background-color: @red;
      color: #fff;
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }

    99% {
      //cleanup to return to original state.
      background-color: @background-color;
      color: @color;
    }
  }
}

@assetsUrl: /search/public;