@import (once) url('../../../../../rootImports.less');

@primary-spacing: 15px;

@summary-card-padding: @primary-spacing;
@summary-card-margin-side: 0px;
@summary-card-margin-top-bottom: 13px;

@preview-margin-bottom: 10px;

@title-max-lines: 2;
@title-font-size: 15px;
@title-line-height: 1.14;
@title-margin-bottom: 5px;
@title-height: @title-max-lines * @title-font-size * @title-line-height;

@tags-max-lines: 3;
@tags-height: floor(@tags-max-lines * (@tag-font-size-small * @tag-line-height + @tag-margin-bottom-small));

@slide-count-max-lines: 2;
@slide-count-line-height: 1.18;
@slide-count-font-size: 13px;
@slide-count-bottom-margin: 5px;
@slide-count-height: @slide-count-font-size * @slide-count-line-height * @slide-count-max-lines;

.base-summary-card {
  position: relative;
  color: @brown-dark;
  font-size: 13px;
  padding: @summary-card-padding;
  margin: @summary-card-margin-top-bottom @summary-card-margin-side;
  cursor: pointer;
  &:hover {
    background-color: @white-2;
  }
  &.dummy {
    height: 0;
    padding-top: 0;
    padding-bottom: 0;
    border-color: transparent;
    pointer-events: none;
    margin-top: 0;
    margin-bottom: 0;
  }
}
