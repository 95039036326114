@import (once) url('rootImports.less');

@placeholder-color: @grey-mid-3;
@font-size: 18px;
@input-width: 20rem;
@form-element-padding: 0.7em 1rem;

.channelfilter {
  position: relative;
  padding-bottom: 1.5rem;

  &__input&__input&__input {
    .border-radius;
    border: none;
    font-family: @lessonup-body-new;
    display: inline-block;
    line-height: 1;
    height: auto;
    padding: @form-element-padding;
    padding-left: 2rem;
    width: @input-width;
    font-size: @font-size;
    color: @brown-dark;

    &::placeholder {
      opacity: 1;
      color: @placeholder-color;
      font-size: @font-size;
    }

    &:active,
    &:focus {
      padding-left: 1rem;

      &::placeholder {
        opacity: 0;
      }

      + .channelfilter__icon {
        display: none;
      }
    }
  }

  &__icon {
    position: absolute;
    margin-left: -(@input-width - 1rem);
    margin-top: 1.4rem;
    width: 0.8rem;
    height: 0.8rem;
    fill: @placeholder-color;
  }

  &__button {
    display: inline-block !important;
    margin-top: 5px !important;
    margin-left: -(2 * 5px) !important;
    height: auto !important;
    font-size: @font-size !important;
    padding: @form-element-padding !important;
  }
}

@assetsUrl: /search/public;