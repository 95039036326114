@import (once) url('rootImports.less');

.search-sidebar {
  padding-left: 20px;
  padding-right: 20px;
  color: @brown-dark;

  .search-sidebar__title {
    margin-top: 20px;
    font-weight: bold;
    font-size: 28px;
    margin-bottom: 10px;
  }

  .search-sidebar__subtitle {
    display: inline-block;
    margin-top: 40px;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 15px;
  }

  input {
    padding-left: 20px;
  }

  @media (min-width: @search-sidebar-desktop-min) {
    position: fixed;
    width: inherit; // Necessary to ensure that - although the element is fixed - it follows its parent width.
  }
}

.search-sidebar__toggle-button {
  display: flex;
  align-items: center;
  background: none;
  color: @brown-dark;
  font-family: @lessonup-body-new;
  font-weight: bold;
  font-size: 15px;
  border: none;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0;
}

.search-sidebar__toggle-button-icon-container {
  display: flex;
  align-items: center;
  background-color: @white;
  border-radius: 20px;
  padding: 5px 20px;
  height: 38px;
  margin-right: 10px;

  svg {
    width: 20px;
    height: 20px;
  }
}

@assetsUrl: /search/public;