@import (once) url('rootImports.less');

.topbar-facets {
  display: flex;
  align-items: center;

  .facet-select {
    margin-bottom: 0;
  }
}

.topbar-facets-text {
  margin-left: 0.4em;
  margin-right: 0.4em;
  font-weight: bold;
}

.bottom-bar {
  justify-content: center;

  .align-end {
    position: absolute;
    right: 1em;
  }

  .deselect-pins-link {
    color: @blue;
    font-size: 13px;
  }
}

@assetsUrl: /search/public;