@import (reference) url('_mediaqueries.less');

// Display

.d-none {
  display: none !important;
}

.generate-responsive-display-utils(@counter) when (@counter > 0) {
  .generate-responsive-display-utils((@counter - 1));

  @name: extract(@breakpoint-names, @counter);
  @breakpoint: extract(@breakpoint-values, @counter);

  .d-@{name}-none {
    @media (min-width: @breakpoint) {
      display: none !important;
    }
  }

  .d-@{name}-block {
    @media (min-width: @breakpoint) {
      display: block !important;
    }
  }

  .d-@{name}-inline-block {
    @media (min-width: @breakpoint) {
      display: inline-block !important;
    }
  }

  .d-@{name}-flex {
    @media (min-width: @breakpoint) {
      display: flex !important;
    }
  }
}

.generate-responsive-display-utils(length(@breakpoint-names));

// Position

.relative {
  position: relative;
}

// Height

.h-100 {
  height: 100%;
}

// Text

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@assetsUrl: /search/public;