@import (once) url('rootImports.less');

.registration-page--terms {
  display: flex;
  flex-direction: column;
  align-items: center;

  & > *:not(:last-child) {
    margin-bottom: 1rem;
  }

  .registration-terms-container {
    gap: 2rem;
  }

  .name-field-container {
    display: block;
    gap: 1rem;
  }

  .name-field-container > *:not(:last-child) {
    margin-bottom: 1rem;
  }

  @media (min-width: @mq-sm-min) {
    & > *:not(:last-child) {
      margin-bottom: 2rem;
    }

    .name-field-container {
      display: flex;

      & > *:not(:last-child) {
        margin-bottom: 0;
      }
    }
  }

  @media (min-width: @registration-page-tablet-min) {
    .registration-container {
      max-width: 500px;
      width: 75vw;
      word-wrap: break-word;
    }

    .padding {
      width: 1rem;
    }
  }

  .action-button {
    align-self: center;
  }
}

.country-and-state-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;

  p {
    margin: 0;
  }
}

@assetsUrl: /search/public;