@import (once) url('rootImports.less');

@max-width: 480px;

.registration-pick-child-page {
  max-width: 480px;

  &__child-picker {
    width: 100%;

    form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
    }

    &-container {
      width: 100%;
      max-width: @max-width;
      max-height: 50vh;
      background-color: @wild-sand;
      padding: 8px;
      border-radius: 16px;
      margin-bottom: 16px;
      overflow: auto;
    }
  }

  &__error,
  &__notification {
    text-align: center;
    max-width: @max-width;
  }

  &__error {
    color: @red;
  }
}

@assetsUrl: /search/public;