@import (once) url('rootImports.less');
@import (once) url('../../_imports.less');

.checkbox-cell {
  width: @checkbox-cell-size !important;
  padding: 2 * @spacer;

  &__checkbox {
    visibility: hidden;
    vertical-align: top;
    &--active {
      visibility: visible;
    }
  }
}

@assetsUrl: /search/public;