@import (once) url('rootImports.less');
@import (once) url('../../_imports.less');

.explorer-private-folder-icon {
  position: relative;
  &__visual-icon {
    position: absolute;
    top: 0.5rem;
    height: 1.3rem;
    width: 2.5rem;
    background-size: auto 65%;
    background-repeat: no-repeat;
    background-position: 48% 30%;

    &--is-open {
      transform: scale(0.9) rotate(-3deg) translateY(0.2rem);
    }
  }
}

@assetsUrl: /search/public;