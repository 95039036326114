@import (once) url('_imports.less');
@multiplier: 1.2;
/* the miniature version of a contentType */
.thumbPreview {
  position: relative;
  width: @preview-width;
  height: @preview-height;
  font-size: @preview-font;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);

  &[data-role] {
    cursor: pointer;
  }

  .hover-text {
    position: absolute;
    left: 50%;
    top: 50%;
    min-width: 47px;
    white-space: nowrap;
    height: 30px;
    font-size: 14px;
    line-height: 30px;
    background-color: rgba(0, 0, 0, 0.8);
    background-repeat: no-repeat;
    background-position: 7px 7px;
    background-size: 15px auto;
    padding: 0 10px 0 32px;
    color: #fff;
    border-radius: 5px;
    .translate(-50%, -50%);
    z-index: 99;
    display: none;
  }
  &:hover,
  &.hover {
    .hover-text {
      display: block;
    }
  }

  &.bigger {
    width: @preview-width * @multiplier;
    height: @preview-height * @multiplier;
    font-size: @preview-font * @multiplier;
  }

  .thumb {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .fact {
    position: absolute;
    bottom: 0;
    right: 0;
    text-align: right;
    text-transform: lowercase;
    padding: 4px 8px;
    font-size: 11px;
    line-height: 16px;
    text-transform: uppercase;
    color: #fff;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 5px 0 0 0;
    z-index: 21;
    &.unsafe {
      background: @red;
      color: #fff;
    }
  }
  .unsafe-marker {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    border: solid @red 3px;
    pointer-events: none;
  }
  .glass-pane-svg {
    width: 100%;
    position: absolute;
    left: 0;
  }
}

@assetsUrl: /search/public;