@import (once) url('rootImports.less');

.flag-lesson-button {
  // align-self: flex-start;
  color: @red;
  cursor: pointer;
  // Make click area larger through padding but button should stick to the bottom
  margin-top: -5px;
  margin-bottom: -5px;

  .flag-lesson-button__inner {
    display: inline-block;
    text-decoration: underline;
    font-size: 14px;
    font-weight: normal;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .flag-icon {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 0.4em;

    g {
      fill: @pink;
    }
  }
}

@assetsUrl: /search/public;