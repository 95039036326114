.feedback_bar {
  .feedback_bar__star {
    &.feedback_bar__star--sizing-default {
      height: 20px;
      width: 20px;
    }

    &.feedback_bar__star--sizing-to-font {
      height: 1em;
      width: 1em;
    }
  }

  .feedback-bar__label {
    font-size: 0.6em;
    margin-left: 0.5em;
    margin-bottom: 0.4em; // TODO Center nicely
    vertical-align: middle;
    line-height: 1em;
    display: inline-block;
  }
}

@assetsUrl: /search/public;