@import (once) url('rootImports.less');

.promo-button {
  position: relative;
  border-radius: 4px;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.7);
  cursor: pointer;
  padding: 20px;
  line-height: 1.1em;
  font-family: @lessonup-body-new;
  font-size: 15px;
  text-decoration: none;
  color: @default-text;
  border: 0;
  outline: none;
  &.has-icon {
    padding-left: 45px;
  }
  &:active {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.7);
  }
  .icon-wrapper {
    display: block;
    position: absolute;
    left: 10px;
    top: 50%;
    .translatey(-50%);
    width: 30px;
    height: 30px;
  }
  .button-icon {
    width: 30px;
    height: 30px;
    position: absolute;
    background: url('') no-repeat center center;
    background-size: contain;
  }
}

.promo-button.green {
  background-color: @green;
}

@assetsUrl: /search/public;