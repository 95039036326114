@import (once) url('rootImports.less');

.interactive.view-component-audio {
  .core,
  .border {
    .transform-origin(center, center);
    .transition(all 0.5s ease-out);
  }

  &:hover {
    .core,
    .border {
      .transform(translate(-50%, -50%) scale(1.2));
    }
  }
}

@assetsUrl: /search/public;