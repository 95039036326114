@import (once) url('rootImports.less');
@import url('../../inputElements/inputMixin.less');

@default-input-width: 100%;
@default-button-margin: 1em;

.school-select {
  display: flex;
  flex-direction: column;

  &__label {
    font-weight: bold;
    font-size: 1.25;
    margin-bottom: 0.5rem;
  }

  &__dropdown-selection {
    width: @default-input-width;
  }

  &__continue-button {
    margin-left: @default-button-margin;
    margin-top: auto;
    margin-bottom: auto;
  }

  &__school-not-found-button {
    margin-left: @default-button-margin;
  }

  &__view-organizations-button {
    margin-top: 1em;
  }

  &__empty-option {
    min-height: 2rem;
    cursor: pointer;
  }

  &__horizontal-break {
    border-top: 1px solid @grey;
  }

  &__create-option {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    border-top: 0.5rem;
    align-items: center;
  }

  .create-element:not(:only-child) {
    border-top: 1px solid @outline;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    padding-left: 0;
    width: calc(100% - 1.5rem);
  }
}

.input-icon {
  height: 1rem;
  margin-left: 1rem;
}

.input-container {
  padding-left: 0 !important;
  margin-left: -0.5rem !important;
}

@assetsUrl: /search/public;