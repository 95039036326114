@keyframes wobblescale1 {
  0% {
    transform: rotate(-1deg) scale(0.85);
    animation-timing-function: ease-in;
  }
  50% {
    transform: rotate(1.5deg) scale(0.85);
    animation-timing-function: ease-out;
  }
}

@keyframes wobblescale2 {
  0% {
    transform: rotate(1deg) scale(0.85);
    animation-timing-function: ease-in;
  }
  50% {
    transform: rotate(-1.5deg) scale(0.85);
    animation-timing-function: ease-out;
  }
}

.wobble-anim-with-scale:nth-child(2n) {
  animation-name: wobblescale1;
  animation-iteration-count: infinite;
  transform-origin: 50% 50%;
}

.wobble-anim-with-scale:nth-child(2n-1) {
  animation-name: wobblescale2;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  transform-origin: 30% 5%;
}

@assetsUrl: /search/public;