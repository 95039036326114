@import (once) url('rootImports.less');
.poll-content {
  margin-top: 18.5%;
  height: ~'calc(81.5% - 2em)';
  position: absolute;
  top: 0;
  width: 100%;
  padding: 0.7em 0.5em;

  .multiplechoice-wrapper {
    grid-auto-rows: 50%;
    grid-row-gap: 5%;
    grid-column-gap: 1%;
    width: 100%;
    height: 50%;

    .option {
      height: 100%;
      overflow: hidden;
    }
    .image-option.option {
      min-width: auto;
      height: auto;
      width: auto;
    }
  }

  .mc-wrapper-10 {
    grid-template-columns: 20% 20% 20% 20% 20%;
    grid-template-rows: 100% 100%;
  }

  .mc-wrapper-8 {
    grid-template-columns: 20% 20% 20% 20%;
    grid-template-rows: 100% 100%;
  }
  .mc-wrapper-6 {
    grid-template-columns: 20% 20% 20%;
    grid-template-rows: 100% 100%;
  }

  .mc-wrapper-4 {
    grid-template-columns: 20% 20%;
    grid-template-rows: 100% 100%;
  }
  .mc-wrapper-1 {
    grid-template-columns: 20%;
    grid-template-rows: 100%;
  }
}

//Fun IE stuff here
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .poll-content {
    .multiplechoice-wrapper {
      .option,
      .image-option {
        width: 18.7%;
        height: 55%;
        @media (max-width: @mq-sm-min) {
          margin: 5px 5px 0 0;
        }
        @media (max-width: @mq-xs-min) {
          margin: 3px 3px 0 0;
        }
      }
      .image-option.option {
        min-width: auto;
        width: 18.5%;
        height: 54%;
      }
    }
  }
  .thumbPreview {
    .multiplechoice-wrapper {
      .option,
      .image-option {
        width: 18.7%;
        height: 52%;
        margin: 3px 3px 0 0;
      }
    }
  }
}

@assetsUrl: /search/public;