.overflow-auto {
  overflow: auto;
}
.overflow-hidden {
  overflow: hidden;
}
.can-scroll {
  -ms-overflow-style: -ms-autohiding-scrollbar !important;
  -webkit-overflow-scrolling: touch;
}
.align-vertical {
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.align-horizontal {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.align-center {
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  &.has-50px-top-bar {
    // topbar height ./ 2
    top: ~'calc(50% + 25px)';
  }
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.text-selectable {
  -webkit-touch-callout: text;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
.break-anywhere {
  word-break: break-all;
  -ms-word-break: break-all;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}
.clearfix {
  &:after {
    clear: both;
    display: block;
    content: '';
  }
}
.pointer {
  cursor: pointer;
  &.disabled {
    cursor: default;
    pointer-events: none;
  }
}

.opacity-80 {
  opacity: 0.8;
}
.opacity-60 {
  opacity: 0.6;
}
.opacity-40 {
  opacity: 0.4;
}
.opacity-20 {
  opacity: 0.2;
}
.opacity-0 {
  opacity: 0;
}

.image-size-contain {
  background-size: contain !important;
}
.image-size-cover {
  background-size: cover !important;
}
