@import (once) url('rootImports.less');
@import (once) url('../../../shared-core//ui/components/pin/pin-mixins');
@spacing: 7px;

.lesson-preview-tile {
  // corresponds with LESSON_THUMBNAIL_WIDTH in ../../../shared-core/domain/ui/constants/lessonThumbnail.ts
  width: 231px;
  // corresponds with LESSON_THUMBNAIL_HEIGHT in ../../../shared-core/domain/ui/constants/lessonThumbnail.ts
  height: 169px;
  overflow: hidden;

  .lesson-preview-tile--initialPin {
    height: 130px;
    position: relative;

    .pin-font-size(231, 1);
  }

  .lesson-preview-tile--lowerpin-list {
    display: flex;
    height: 31.5px;
    margin-top: @spacing;
  }

  .lesson-preview-tile--lowerpin {
    display: inline-block;
    width: 56px;
    margin-right: @spacing;
    height: 100%;

    .pin-font-size(56 * 4, 1);
  }

  .lesson-preview-tile__count {
    flex: 1;
    text-align: center;
    height: 100%;
    padding: 0 5px;
    vertical-align: top;
    background-color: @white;
    color: #c3c3c3;
    border: 1px solid @border;
  }
}

.lesson-preview-tile__count-text {
  margin-top: 5px;
  font-size: 15px;
  vertical-align: middle;
}

.lesson-preview-tile--v2 {
  border: 1px solid @border;
}

@assetsUrl: /search/public;