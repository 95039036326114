@import (once) url('rootImports.less');

.redeem-voucher-description {
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.redeem-voucher-form-wrapper {
  .error-message {
    margin-top: 16px;
    color: @red;
    font-family: @lessonup-body-new;
    text-align: center;
    max-width: 100%; // Demanded by IE11.
  }

  .voucher-input-field input {
    margin-top: 0;
    margin-bottom: 0;
  }
}

@assetsUrl: /search/public;