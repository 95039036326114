@import (once) url('rootImports.less');

.breadcrumb-list {
  padding: 10px 10px 10px 0;
  .button-unstyled {
    text-decoration: none;
  }
  .button-unstyled:hover {
    text-decoration: underline;
  }
}

.breadcrumb-list-item {
  display: inline-block;
  color: @brown-dark;
  font-family: @lessonup-body-new;
  text-decoration: none;
  font-size: 0.9rem;

  &::after {
    content: '/';
    margin-right: 5px;
    margin-left: 5px;
    color: @brown-dark;
    font-weight: bold;
    text-decoration: none;
  }

  &:last-child::after {
    content: none;
    margin: 0;
  }
}

.breadcrumb-list-item:not(:last-child) {
  color: @cyan;
  font-weight: 600;
}

@assetsUrl: /search/public;