@import (once) url('rootImports.less');

.login-username-password {
  width: unset;
  margin-top: 0;

  .error {
    max-width: 200px;
    text-align: center;
  }
}

.password-forgotten-link {
  font-size: 13px;
  margin-bottom: 1em;
  text-decoration: underline;
  cursor: pointer;
  display: block;
  bottom: 1.4em;
  text-align: center;
}

@assetsUrl: /search/public;