@import (once) url('rootImports.less');

.searchpage__searchresults {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-left: @search-results-outer-padding-mobile;
  padding-right: @search-results-outer-padding-mobile;

  @media (min-width: @search-sidebar-desktop-min) {
    padding-left: @search-results-outer-padding-desktop;
    padding-right: @search-results-outer-padding-desktop;
    margin-top: @gray-tabs-bar-height + @gray-tabs-margin-bottom;
  }
  &.hide-tabs {
    margin-top: auto;

    .country-notification {
      margin-top: @gray-tabs-margin-bottom;
    }
  }
}

.searchpage {
  @media only screen and (min-width: @search-sidebar-desktop-min) {
    .searchpage-hidden-on-large-displays {
      display: none;
    }
  }

  @media only screen and (max-width: @search-sidebar-mobile-max) {
    .search-sidebar {
      margin-bottom: 20px;
    }

    .searchpage-hidden-on-small-displays {
      display: none;
    }
  }
}

.searchpage-result-header {
  > .taglist {
    .taglist__list-element {
      display: inline-block;
      padding: 10px 20px;
      background-color: @cyan;
      border-radius: 20px;
      color: white;
      margin-right: 10px;
      margin-bottom: 10px;
    }
    &.removal-tags {
      .taglist__list-element {
        background-color: @pink;
      }
    }
  }

  .searchpage-result-header--title {
    margin-top: 40px;
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 28px;
    color: @brown-dark;
    font-family: @lessonup-heading;
  }

  .searchpage-result-header--title-hightlight {
    color: @blue;
  }
}

.searchpage-result-header__text {
  font-size: 15px;
  color: @brown-dark;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
}

.search-page-tags {
  margin-bottom: 20px;
}

@assetsUrl: /search/public;