@import (once) url('../_imports.less');

.component-hotspot {
  .hotspot-title {
    position: absolute;
    left: 50%;
    height: 95%;
    top: 2.5%;
    background: rgba(0, 0, 0, 0.6);
    color: #fff;
    padding: 4% 40% 4% 60%;
    white-space: nowrap;
    border-radius: 0 3000px 3000px 0;
    .vertical-align();
  }
  &.show-text {
    .hotspot-title {
      display: none;
    }
  }
  .hotspot-text {
    position: absolute;
    background: rgba(0, 0, 0, 0.6);
    color: #fff;
    left: 50%;
    top: 50%;
    padding: 20% 40% 20% 60%;
    font-size: 65%;
    opacity: 0;
    border-radius: 20px;
    min-width: 600%;
    .transition(opacity 0.3s ease-out);
    pointer-events: none;
    font-family: @lessonup-title;
    &.show {
      opacity: 1;
      a {
        pointer-events: all;
        // IE FIX, it will ignore the pointer-events otherwise
        position: relative;
      }
    }
  }
  &.inverted-text-color {
    .hotspot-title,
    .hotspot-text {
      background: rgba(255, 255, 255, 0.8);
      color: #000;
    }
  }
  &.full-opacity {
    .hotspot-title,
    .hotspot-text {
      background: rgba(0, 0, 0, 1);
    }
    &.inverted-text-color {
      .hotspot-title,
      .hotspot-text {
        background: rgba(255, 255, 255, 1);
      }
    }
  }
  .border {
    position: absolute;
    width: 95%;
    height: 95%;
    left: 50%;
    top: 50%;
    .translate(-50%, -50%);
    border-radius: 3000px;
    background: #000;
  }
  .core {
    position: absolute;
    width: 75%;
    height: 75%;
    left: 50%;
    top: 50%;
    .translate(-50%, -50%);
    border-radius: 3000px;
    background: #fff;
  }

  /*		&.bg-black:after,
		&.bg-pink:after,
		&.bg-cyan:after {
			background-image: url('@{assetsUrl}/img/icons/white/plus.png');
		}*/

  .core-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    .translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background-size: 70%;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .core-text {
    position: absolute;
    left: 50%;
    top: 50%;
    .translate(-50%, -50%);
    .font-montserrat;
    font-size: 95%;
    font-weight: bold;
  }
  .core-image {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: 100% auto;
    background-repeat: no-repeat;
    border-radius: 100%;
  }
  /* when expanding text to the left */
  &.expand-h-pos-left {
    .hotspot-title {
      left: auto;
      right: 50%;
      padding: 4% 60% 4% 40%;
      border-radius: 3000px 0 0 3000px;
    }
    .hotspot-text {
      left: auto;
      right: 50%;
      padding: 20% 60% 20% 40%;
    }
  }
  /* when expanding text to the top */
  &.expand-v-pos-up {
    .hotspot-text {
      top: auto;
      bottom: 50%;
    }
  }
  &.expand-v-pos-down {
    .hotspot-text {
      top: 50%;
      bottom: auto;
    }
  }
  &.active {
    z-index: 20;
  }
}

.presentationMode .view-mode {
  .view-component-hotspot {
    cursor: pointer;
    .core,
    .border {
      .transform-origin(center, center);
      .transition(transform 0.5s ease-out);
      -webkit-transform-style: preserve-3d;
      -webkit-transform: translate3d(-50%, -50%, 0);
    }
    &.showText-true {
      z-index: 11;
    }
  }
  .view-component-hotspot:hover {
    .core,
    .border {
      .transform(translate(-50%, -50%) scale(1.2));
    }
  }
}

@assetsUrl: /search/public;