@system-font-stack: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
@lessonup-logo: 'campton-bold';
@lessonup-heading: 'campton-bold', @system-font-stack;
@lessonup-title: 'montserrat';
@lessonup-round: 'odin';
@lessonup-body: 'sanfrancisco';
@lessonup-body-new: 'montserrat', @system-font-stack;
@lessonup-writing: 'comicneue', 'Comic Sans MS', cursive, sans-serif;
// Commented out until we find a lighter solution. https://lessonup.atlassian.net/browse/LI-374
// @lessonup-emoji: 'noto-color-emoji';
// From: https://css-tricks.com/snippets/css/font-stacks/
@lessonup-monospace: Consolas, 'Andale Mono WT', 'Andale Mono', 'Lucida Console', 'Lucida Sans Typewriter',
  'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Liberation Mono', 'Nimbus Mono L', Monaco, 'Courier New', Courier,
  monospace;

.font-andada {
  font-family: 'Andada', serif !important;
}
.font-arial {
  font-family: Arial, Helvetica, sans-serif !important;
}
.font-comicneue {
  font-family: 'comicneue', 'Comic Sans MS', cursive, sans-serif !important;
}
.font-courier {
  font-family: 'Courier New', Courier, monospace !important;
}
.font-georgia {
  font-family: Georgia, serif !important;
}
.font-gotham {
  font-family: 'Gotham Rounded SSm A', 'Gotham Rounded SSm B', 'montserrat', Arial, sans-serif !important;
}
.font-impact {
  font-family: Impact, Anton, Charcoal, sans-serif !important;
}
.font-lucida {
  font-family: 'Lucida Console', Monaco, monospace !important;
}
.font-montserrat {
  font-family: 'montserrat', sans-serif !important;
}
.font-opendyslexic {
  font-family: 'opendyslexic', serif !important;
}
.font-open-sans {
  font-family: 'Open Sans', sans-serif !important;
}
.font-odin {
  font-family: 'odin', sans-serif !important;
}
.font-palatino {
  font-family: 'Palatino Linotype', 'Book Antiqua', Palatino, serif !important;
}
.font-sanfrancisco {
  font-family: 'sanfrancisco', sans-serif !important;
}
.font-times {
  font-family: 'Times New Roman', Times, serif !important;
}
.font-trebuchet {
  font-family: 'Trebuchet MS', Helvetica, sans-serif !important;
}
.font-verdana {
  font-family: Verdana, Geneva, sans-serif !important;
}
.font-pangolin {
  font-family: 'Pangolin', cursive !important;
}
.font-dosis {
  font-family: 'Dosis', 'Lucida Console', sans-serif !important;
}
.font-roboto {
  font-family: 'Roboto', sans-serif !important;
}
.font-varelaround {
  font-family: 'Varela Round', Arial, sans-serif !important;
}
.font-kreon {
  font-family: 'Kreon', Arial, sans-serif !important;
}
.font-schulbuch {
  font-family: 'schulbuch', Arial, sans-serif !important;
}

.font-dm-sans {
  font-family: 'DM Sans', ui-sans-serif, sans-serif !important;
}

.text-align-left {
  text-align: left !important;
}
.text-align-center {
  text-align: center !important;
}
.text-align-right {
  text-align: right !important;
}

.safari,
.firefox {
  /* CSS Hack to fix the position of content editable cursor in FF and Safari */
  [contentEditable='true'].text-align-center:empty {
    padding-left: 50%;
  }
  [contentEditable='true'].text-align-right:empty {
    padding-left: 90%;
  }
}

.lessonup-round-huge {
  font-family: @lessonup-round;
  font-weight: normal;
  font-size: 45px;
  line-height: 50px;
  text-transform: uppercase;
}
.lessonup-round-semi-huge {
  font-family: @lessonup-round;
  font-weight: normal;
  font-size: 28px;
  line-height: 32px;
  text-transform: uppercase;
}
.lessonup-round-3 {
  font-family: @lessonup-round;
  font-size: 24px;
  line-height: 28px;
  text-transform: uppercase;
}
.lessonup-round-4 {
  font-family: @lessonup-round;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
}
.lessonup-round-5 {
  font-family: @lessonup-round;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
}

.lessonup-title-1 {
  font-family: @lessonup-heading;
  font-size: 42px;
  line-height: 52px;
}

.lessonup-body-1 {
  font-family: @lessonup-body-new;
  font-size: 20px;
  line-height: 24px;
}
.lessonup-body-2 {
  font-family: @lessonup-body-new;
  font-size: 16px;
  line-height: 20px;
}
.lessonup-body-3 {
  font-family: @lessonup-body-new;
  font-size: 14px;
  line-height: 18px;
}
.lessonup-body-1-new {
  font-family: @lessonup-body-new;
  font-size: 20px;
  line-height: 24px;
}

.lessonup-body-new-1 {
  font-family: @lessonup-body-new;
  font-size: 20px;
  line-height: 24px;
}
.lessonup-body-new-2 {
  font-family: @lessonup-body-new;
  font-size: 16px;
  line-height: 20px;
}
.lessonup-body-new-3 {
  font-family: @lessonup-body-new;
  font-size: 14px;
  line-height: 18px;
}

.font-size-10 {
  font-size: 10% !important;
}
.font-size-20 {
  font-size: 20% !important;
}
.font-size-30 {
  font-size: 30% !important;
}
.font-size-35 {
  font-size: 35% !important;
}
.font-size-40 {
  font-size: 40% !important;
}
.font-size-50 {
  font-size: 50% !important;
}
.font-size-60 {
  font-size: 60% !important;
}
.font-size-70 {
  font-size: 70% !important;
}
.font-size-80 {
  font-size: 80% !important;
}
.font-size-90 {
  font-size: 90% !important;
}
.font-size-100 {
  font-size: 100% !important;
}
.font-size-110 {
  font-size: 110% !important;
}
.font-size-120 {
  font-size: 120% !important;
}
.font-size-130 {
  font-size: 130% !important;
}
.font-size-140 {
  font-size: 140% !important;
}
.font-size-160 {
  font-size: 160% !important;
}
.font-size-180 {
  font-size: 180% !important;
}
.font-size-200 {
  font-size: 200% !important;
}
.font-size-300 {
  font-size: 300% !important;
}
.font-weight-500 {
  font-weight: 500 !important;
}
.font-weight-600 {
  font-weight: 600 !important;
}

@baseVw: 10/16 * 9;
.playercontent {
  font-size: ~'@{baseVw}vw';
}

@iterations: 100;

.span-loop (@i) when (@i > 0) {
  @scale: @baseVw * (@i ./ 100);
  .scale-font-@{i} {
    font-size: ~'@{scale}vw';
  }
  .scale-font-important-@{i} {
    @scale-sm: @scale * 0.96; //thumbnails are more inclined to have scaling issues, so a little margin
    font-size: ~'@{scale-sm}vw !important';
  }
  .span-loop(@i - 1);
}
// .span-loop (@iterations);

// Adds a font-display to the externally loaded Gotham fonts.
@font-face {
  font-family: 'Gotham Rounded SSm A';
  font-display: fallback;
}

@font-face {
  font-family: 'Gotham Rounded SSm B';
  font-display: fallback;
}
