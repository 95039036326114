@import (once) url('rootImports.less');

.ui-tags {
  font-size: 0;
}

.ui-tag {
  font-family: @lessonup-body-new;
  font-weight: 600;
  border-radius: 4px;
  position: relative;
  display: inline-block;
  color: #fff;
  padding: 0 0.3em;
  font-size: @tag-font-size-small;
  line-height: @tag-line-height;
  margin-right: 3px;
  margin-bottom: @tag-margin-bottom-small;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;

  &:last-child {
    margin-right: 0;
  }

  &--large {
    font-size: @tag-font-size-large;
    margin-right: 4px;
    margin-bottom: @tag-margin-bottom-large;
  }

  &.bg-yellow {
    color: @brown-dark;
  }
}

@assetsUrl: /search/public;