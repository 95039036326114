@import (once) url('rootImports.less');

.share-lessonmodal-controls {
  width: 100%;
  display: inline-flex;
  flex-direction: column;

  .action-button {
    align-self: center;
    margin-top: 0.6em;
  }
}

.copy-field {
  flex-grow: 1;
  margin-right: 0;
  border: none;
  height: 100%;
  border-radius: 0;
  color: @default-text;
  padding: 1em;
  font-family: monospace;
  font-weight: bold;
  font-size: 1em;
  outline: none;
}

@assetsUrl: /search/public;