@import (once) url('../_imports.less');

.map-container {
  width: 100%;
  max-width: 100%;
  height: 100%;
  .maps-error {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    max-width: 100%;
    height: 100%;
    background-color: @white;
    color: @pink;
    font-size: 50%;
    text-align: center;
  }
}

.foreground .map-container {
  position: absolute;
  top: 0;
  left: 0;
}

@assetsUrl: /search/public;