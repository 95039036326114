@import (once) url('rootImports.less');

.search-footer {
  width: 100%;
  display: inline-flex;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  flex-shrink: 0;
  flex-direction: column;
  z-index: 1;

  @media (min-width: 720px) {
    flex-direction: row;
  }

  @media (min-width: @mq-md-min) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.search-footer-nav-logo {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.search-footer-nav-container {
  @media (min-width: 720px) {
    flex-direction: row;
    margin-left: auto;
  }

  @media (min-width: @mq-md-min) {
    display: flex;
    align-items: center;
  }
}

.search-footer-nav {
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-right: 1rem;
}

.search-footer-nav-item {
  text-decoration: none;
  margin-left: 20px;

  &:first-child {
    margin-left: 0;
  }

  &:hover,
  &:active {
    text-decoration: underline;
  }
}

.search-footer-language-select {
  margin-top: 1rem;
  margin-bottom: 1rem;

  .language-switch {
    display: inline;
  }

  @media (min-width: @mq-md-min) {
    margin-left: 30px;
    justify-content: flex-end;
  }
}

@assetsUrl: /search/public;