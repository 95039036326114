.all-icons(@url) {
  .icon-rocket1 {
    background-image: url('@{url}/img/rocket1.png');
  }
  .icon-rocket2 {
    background-image: url('@{url}/img/rocket2.png');
  }
  .icon-rocket3 {
    background-image: url('@{url}/img/rocket3.png');
  }
  .icon-rocket4 {
    background-image: url('@{url}/img/rocket4.png');
  }
  .icon-rocket5 {
    background-image: url('@{url}/img/rocket5.png');
  }
  .icon-google {
    background-image: url('@{url}/img/google.png');
  }

  .after-icon-search:after {
    background-image: url('@{url}/img/icons/white/search.png');
  }
  .after-icon-image:after {
    background-image: url('@{url}/img/icons/white/image.png');
  }
  .after-icon-list:after {
    background-image: url('@{url}/img/icons/white/list.png');
  }
  .after-icon-numbered-list:after {
    background-image: url('@{url}/img/icons/white/numbered-list.png');
  }
  .after-icon-close2:after {
    background-image: url('@{url}/img/icons/white/close-2.png');
  }
  .after-icon-play:after {
    background-image: url('@{url}/img/icons/white/play.png');
  }
  .after-icon-pause:after {
    background-image: url('@{url}/img/icons/white/pause.png');
  }
  .after-icon-book:after {
    background-image: url('@{url}/img/icons/white/book3.png');
  }
  .after-icon-briefcase:after {
    background-image: url('@{url}/img/icons/white/briefcase.png');
  }
  .after-icon-drawer:after {
    background-image: url('@{url}/img/icons/white/drawer.png');
  }
  .after-icon-drawer2:after {
    background-image: url('@{url}/img/icons/white/drawer2.png');
  }
  .after-icon-home:after {
    background-image: url('@{url}/img/icons/white/home.png');
  }
  .after-icon-plus:after {
    background-image: url('@{url}/img/icons/white/plus.png');
  }
  .after-icon-minus:after {
    background-image: url('@{url}/img/icons/white/minus.png');
  }
  .after-icon-minus2:after {
    background-image: url('@{url}/img/icons/white/minus2.png');
  }
  .after-icon-arrow-left:after {
    background-image: url('@{url}/img/icons/white/arrow-left.png');
  }
  .after-icon-arrow-right:after {
    background-image: url('@{url}/img/icons/white/arrow-right.png');
  }
  .after-icon-cog:after {
    background-image: url('@{url}/img/icons/white/cog.png');
  }
  .after-icon-star:after {
    background-image: url('@{url}/img/icons/white/star.png');
  }
  .after-icon-star2:after {
    background-image: url('@{url}/img/icons/white/star2.png');
  }
  .after-icon-user:after {
    background-image: url('@{url}/img/icons/white/user.png');
  }
  .after-icon-cut:after {
    background-image: url('@{url}/img/icons/white/cut.png');
  }
  .after-icon-copy:after {
    background-image: url('@{url}/img/icons/white/copy.png');
  }
  .after-icon-paste:after {
    background-image: url('@{url}/img/icons/white/paste.png');
  }
  .after-icon-keyboard:after {
    background-image: url('@{url}/img/icons/white/keyboard.png');
  }
  .after-icon-close:after {
    background-image: url('@{url}/img/icons/white/close.png');
  }
  .after-icon-remove:after {
    background-image: url('@{url}/img/icons/white/remove.png');
  }
  .after-icon-move:after {
    background-image: url('@{url}/img/icons/white/move.png');
  }
  .after-icon-wrench:after {
    background-image: url('@{url}/img/icons/white/wrench.png');
  }
  .after-icon-up:after {
    background-image: url('@{url}/img/icons/white/arrow-up.png');
  }
  .after-icon-down:after {
    background-image: url('@{url}/img/icons/white/arrow-down.png');
  }
  .after-icon-pin:after {
    background-image: url('@{url}/img/icons/white/pushpin.png');
  }
  .after-icon-controls-left:after {
    background-image: url('@{url}/img/icons/white/controls-left.png');
  }
  .after-icon-controls-right:after {
    background-image: url('@{url}/img/icons/white/controls-right.png');
  }
  .after-icon-check:after {
    background-image: url('@{url}/img/icons/white/checkmark.png');
  }
  .after-icon-link:after {
    background-image: url('@{url}/img/icons/white/link.png');
  }
  .after-icon-map:after {
    background-image: url('@{url}/img/icons/white/map.png');
  }
  .after-icon-wand:after {
    background-image: url('@{url}/img/icons/white/wand.png');
  }
  .after-icon-slide:after {
    background-image: url('@{url}/img/icons/white/slide.png');
  }
  .after-icon-menu:after {
    background-image: url('@{url}/img/icons/white/menu.png');
  }
  .after-icon-pencil:after {
    background-image: url('@{url}/img/icons/white/pencil.png');
  }
  .after-icon-droplet:after {
    background-image: url('@{url}/img/icons/white/droplet.png');
  }
  .after-icon-attachment:after {
    background-image: url('@{url}/img/icons/white/attachment.png');
  }
  .after-icon-align-left:after {
    background-image: url('@{url}/img/icons/white/paragraph-left.png');
  }
  .after-icon-align-center:after {
    background-image: url('@{url}/img/icons/white/paragraph-center.png');
  }
  .after-icon-align-right:after {
    background-image: url('@{url}/img/icons/white/paragraph-right.png');
  }
  .after-icon-align-justify:after {
    background-image: url('@{url}/img/icons/white/paragraph-right.png');
  }
  .after-icon-eye:after {
    background-image: url('@{url}/img/icons/white/eye.png');
  }
  .after-icon-eye-blocked:after {
    background-image: url('@{url}/img/icons/white/eye-blocked.png');
  }
  .after-icon-share:after {
    background-image: url('@{url}/img/icons/white/share.png');
  }
  .after-icon-upload:after {
    background-image: url('@{url}/img/icons/white/upload.png');
  }
  .after-icon-user-plus:after {
    background-image: url('@{url}/img/icons/white/user-plus.png');
  }
  .after-icon-user-check:after {
    background-image: url('@{url}/img/icons/white/user-check.png');
  }
  .after-icon-help:after {
    background-image: url('@{url}/img/icons/white/help.png');
  }
  .after-icon-stop:after {
    background-image: url('@{url}/img/icons/white/stop.png');
  }
  .after-icon-grid:after {
    background-image: url('@{url}/img/icons/white/grid.png');
  }
  .after-icon-folder:after {
    background-image: url('@{url}/img/icons/white/folder.png');
  }
  .after-icon-folder-open:after {
    background-image: url('@{url}/img/icons/white/folder-open.png');
  }
  .after-icon-group:after {
    background-image: url('@{url}/img/icons/white/group.png');
  }
  .icon-dark.icon-logo {
    background-image: url('@{url}/img/icons/black/logo.png');
  }
  .icon-dark.icon-search {
    background-image: url('@{url}/img/icons/black/search.png');
  }
  .icon-dark.icon-image {
    background-image: url('@{url}/img/icons/black/image.png');
  }
  .icon-dark.icon-images {
    background-image: url('@{url}/img/icons/black/images.png');
  }
  .icon-dark.icon-list {
    background-image: url('@{url}/img/icons/black/list.png');
  }
  .icon-dark.icon-numbered-list {
    background-image: url('@{url}/img/icons/black/numbered-list.png');
  }
  .icon-dark.icon-play {
    background-image: url('@{url}/img/icons/black/play.png');
  }
  .icon-dark.icon-pause {
    background-image: url('@{url}/img/icons/black/pause.png');
  }
  .icon-dark.icon-book {
    background-image: url('@{url}/img/icons/black/book3.png');
  }
  .icon-dark.icon-briefcase {
    background-image: url('@{url}/img/icons/black/briefcase.png');
  }
  .icon-dark.icon-drawer {
    background-image: url('@{url}/img/icons/black/drawer.png');
  }
  .icon-dark.icon-drawer2 {
    background-image: url('@{url}/img/icons/black/drawer2.png');
  }
  .icon-dark.icon-plus {
    background-image: url('@{url}/img/icons/black/plus.png');
  }
  .icon-dark.icon-plus2 {
    background-image: url('@{url}/img/icons/black/plus2.png');
  }
  .icon-dark.icon-minus {
    background-image: url('@{url}/img/icons/black/minus.png');
  }
  .icon-dark.icon-minus2 {
    background-image: url('@{url}/img/icons/black/minus2.png');
  }
  .icon-dark.icon-arrow-left {
    background-image: url('@{url}/img/icons/black/arrow-left.png');
  }
  .icon-dark.icon-arrow-right {
    background-image: url('@{url}/img/icons/black/arrow-right.png');
  }
  .icon-dark.icon-arrow-left2 {
    background-image: url('@{url}/img/icons/black/arrow-left2.png');
  }
  .icon-dark.icon-arrow-right2 {
    background-image: url('@{url}/img/icons/black/arrow-right2.png');
  }
  .icon-dark.icon-arrow-up {
    background-image: url('@{url}/img/icons/black/arrow-up.png');
  }
  .icon-dark.icon-arrow-down {
    background-image: url('@{url}/img/icons/black/arrow-down.png');
  }
  .icon-dark.icon-arrow-up2 {
    background-image: url('@{url}/img/icons/black/arrow-up2.png');
  }
  .icon-dark.icon-arrow-down2 {
    background-image: url('@{url}/img/icons/black/arrow-down2.png');
  }
  .icon-dark.icon-cog {
    background-image: url('@{url}/img/icons/black/cog.png');
  }
  .icon-brown-dark.icon-cog {
    background-image: url('@{url}/img/icons/brown-dark/cog.png');
  }
  .icon-brown-dark.icon-image {
    background-image: url('@{url}/img/icons/brown-dark/image.png');
  }
  .icon-brown-dark.icon-images {
    background-image: url('@{url}/img/icons/brown-dark/images.png');
  }
  .icon-brown-dark.icon-font {
    background-image: url('@{url}/img/icons/brown-dark/font.png');
  }
  .icon-dark.icon-star {
    background-image: url('@{url}/img/icons/black/star.png');
  }
  .icon-dark.icon-star2 {
    background-image: url('@{url}/img/icons/black/star2.png');
  }
  .icon-dark.icon-user {
    background-image: url('@{url}/img/icons/black/user.png');
  }
  .icon-dark.icon-keyboard {
    background-image: url('@{url}/img/icons/black/keyboard.png');
  }
  .icon-dark.icon-close {
    background-image: url('@{url}/img/icons/black/close.png');
  }
  .icon-dark.icon-close2 {
    background-image: url('@{url}/img/icons/black/close-2.png');
  }
  .icon-dark.icon-remove {
    background-image: url('@{url}/img/icons/black/remove.png');
  }
  .icon-brown-dark.icon-remove {
    background-image: url('@{url}/img/icons/brown-dark/remove.png');
  }
  .icon-dark.icon-move {
    background-image: url('@{url}/img/icons/black/move.png');
  }
  .icon-dark.icon-move-2 {
    background-image: url('@{url}/img/icons/black/move2.png');
  }
  .icon-dark.icon-wrench {
    background-image: url('@{url}/img/icons/black/wrench.png');
  }
  .icon-dark.icon-up {
    background-image: url('@{url}/img/icons/black/arrow-up.png');
  }
  .icon-dark.icon-down {
    background-image: url('@{url}/img/icons/black/arrow-down.png');
  }
  .icon-dark.icon-pin {
    background-image: url('@{url}/img/icons/black/pushpin.png');
  }
  .icon-dark.icon-controls-left {
    background-image: url('@{url}/img/icons/black/controls-left.png');
  }
  .icon-dark.icon-controls-right {
    background-image: url('@{url}/img/icons/black/controls-right.png');
  }
  .icon-dark.icon-check {
    background-image: url('@{url}/img/icons/black/checkmark.png');
  }
  .icon-dark.icon-check-round {
    background-image: url('@{url}/img/icons/black/checkmark-round.png');
  }
  .icon-dark.icon-menu {
    background-image: url('@{url}/img/icons/black/menu.png');
  }
  .icon-dark.icon-home {
    background-image: url('@{url}/img/icons/black/home.png');
  }
  .icon-dark.icon-link {
    background-image: url('@{url}/img/icons/black/link.png');
  }
  .icon-dark.icon-map {
    background-image: url('@{url}/img/icons/black/map.png');
  }
  .icon-dark.icon-wand {
    background-image: url('@{url}/img/icons/black/wand.png');
  }
  .icon-dark.icon-slide {
    background-image: url('@{url}/img/icons/black/slide.png');
  }
  .icon-dark.icon-pencil {
    background-image: url('@{url}/img/icons/black/pencil.png');
  }
  .icon-dark.icon-pencil2 {
    background-image: url('@{url}/img/icons/black/pencil2.png');
  }
  .icon-dark.icon-droplet {
    background-image: url('@{url}/img/icons/black/droplet.png');
  }
  .icon-dark.icon-attachment {
    background-image: url('@{url}/img/icons/black/attachment.png');
  }
  .icon-dark.icon-align-left {
    background-image: url('@{url}/img/icons/black/paragraph-left.png');
  }
  .icon-dark.icon-align-center {
    background-image: url('@{url}/img/icons/black/paragraph-center.png');
  }
  .icon-dark.icon-align-right {
    background-image: url('@{url}/img/icons/black/paragraph-right.png');
  }
  .icon-dark.icon-align-justify {
    background-image: url('@{url}/img/icons/black/paragraph-justify.png');
  }
  .icon-dark.icon-vertical-align-top {
    background-image: url('@{url}/img/icons/black/vertical_align_top.png');
  }
  .icon-dark.icon-vertical-align-bottom {
    background-image: url('@{url}/img/icons/black/vertical_align_bottom.png');
  }
  .icon-dark.icon-vertical-align-center {
    background-image: url('@{url}/img/icons/black/vertical_align_center.png');
  }
  .icon-dark.icon-eye {
    background-image: url('@{url}/img/icons/black/eye.png');
  }
  .icon-dark.icon-eye-plus {
    background-image: url('@{url}/img/icons/black/eye-plus.png');
  }
  .icon-dark.icon-eye-blocked {
    background-image: url('@{url}/img/icons/black/eye-blocked.png');
  }
  .icon-dark.icon-share {
    background-image: url('@{url}/img/icons/black/share.png');
  }
  .icon-dark.icon-upload {
    background-image: url('@{url}/img/icons/black/upload.png');
  }
  .icon-dark.icon-download {
    background-image: url('@{url}/img/icons/black/download.png');
  }
  .icon-dark.icon-bubbles {
    background-image: url('@{url}/img/icons/black/bubbles.png');
  }
  .icon-dark.icon-user-plus {
    background-image: url('@{url}/img/icons/black/user-plus.png');
  }
  .icon-dark.icon-user-check {
    background-image: url('@{url}/img/icons/black/user-check.png');
  }
  .icon-check-green {
    background-image: url('@{url}/img/icons/other/check_green.png');
  }
  .icon-dark.icon-help {
    background-image: url('@{url}/img/icons/black/help.png');
  }
  .icon-dark.icon-cut {
    background-image: url('@{url}/img/icons/black/cut.png');
  }
  .icon-dark.icon-copy {
    background-image: url('@{url}/img/icons/black/copy.png');
  }
  .icon-dark.icon-paste {
    background-image: url('@{url}/img/icons/black/paste.png');
  }
  .icon-dark.icon-stop {
    background-image: url('@{url}/img/icons/black/stop.png');
  }
  .icon-dark.icon-sound-on {
    background-image: url('@{url}/img/icons/black/volume.png');
  }
  .icon-dark.icon-sound-off {
    background-image: url('@{url}/img/icons/black/volume-mute.png');
  }
  .icon-dark.icon-external {
    background-image: url('@{url}/img/icons/black/external-link.png');
  }
  .icon-dark.icon-display {
    background-image: url('@{url}/img/icons/black/display.png');
  }
  .icon-dark.icon-document-stroke {
    background-image: url('@{url}/img/icons/black/document-stroke.png');
  }
  .icon-dark.icon-file {
    background-image: url('@{url}/img/icons/black/file.png');
  }
  .icon-dark.icon-woordweb {
    background-image: url('@{url}/img/icons/black/woordweb.png');
  }
  .icon-dark.icon-stack {
    background-image: url('@{url}/img/icons/black/stack.png');
  }
  .icon-dark.icon-graduation {
    background-image: url('@{url}/img/icons/black/graduation.png');
  }
  .icon-dark.icon-lock {
    background-image: url('@{url}/img/icons/black/lock.png');
  }
  .icon-dark.icon-unlocked {
    background-image: url('@{url}/img/icons/black/unlocked.png');
  }
  .icon-dark.icon-youtube {
    background-image: url('@{url}/img/icons/black/youtube.png');
  }
  .icon-dark.icon-vimeo {
    background-image: url('@{url}/img/icons/black/vimeo.png');
  }
  .icon-dark.icon-youtube-interactive {
    background-image: url('@{url}/img/icons/black/youtube-interactive.png');
  }
  .icon-dark.icon-grid {
    background-image: url('@{url}/img/icons/black/grid.png');
  }
  .icon-dark.icon-powerpoint {
    background-image: url('@{url}/img/icons/black/powerpoint.png');
  }
  .icon-dark.icon-hidden {
    background-image: url('@{url}/img/icons/black/hidden.svg');
  }
  .icon-dark.icon-history {
    background-image: url('@{url}/img/icons/black/recent.svg');
  }
  .icon-dark.icon-folder {
    background-image: url('@{url}/img/icons/black/folder.png');
  }
  .icon-dark.icon-folder-new {
    background-image: url('@{url}/img/icons/black/folder-new.png');
  }
  .icon-dark.icon-layout-1 {
    background-image: url('@{url}/img/icons/black/icon-layout1-2x.png');
  }
  .icon-dark.icon-layout-2 {
    background-image: url('@{url}/img/icons/black/icon-layout2-2x.png');
  }
  .icon-dark.icon-layout-3 {
    background-image: url('@{url}/img/icons/black/icon-layout3-2x.png');
  }
  .icon-dark.icon-layout-4 {
    background-image: url('@{url}/img/icons/black/icon-layout4-2x.png');
  }
  .icon-dark.icon-layout-5 {
    background-image: url('@{url}/img/icons/black/icon-layout5-2x.png');
  }
  .icon-dark.icon-layout-6 {
    background-image: url('@{url}/img/icons/black/icon-layout6-2x.png');
  }
  .icon-dark.icon-layout-7 {
    background-image: url('@{url}/img/icons/black/icon-layout7-2x.png');
  }
  .icon-dark.icon-layout-8 {
    background-image: url('@{url}/img/icons/black/icon-layout8-2x.png');
  }
  .icon-dark.icon-layout-9 {
    background-image: url('@{url}/img/icons/black/icon-layout9-2x.png');
  }
  .icon-dark.icon-quiz-1 {
    background-image: url('@{url}/img/icons/black/quiz-icon-1-2x.png');
  }
  .icon-dark.icon-quiz-2 {
    background-image: url('@{url}/img/icons/black/quiz-icon-2-2x.png');
  }
  .icon-dark.icon-quiz-3 {
    background-image: url('@{url}/img/icons/black/quiz-icon-3-2x.png');
  }
  .icon-dark.icon-quiz-4 {
    background-image: url('@{url}/img/icons/black/quiz-icon-4-2x.png');
  }
  .icon-dark.icon-quiz-5 {
    background-image: url('@{url}/img/icons/black/icon-layout9-2x.png');
  }
  .icon-dark.icon-openquestion-1 {
    background-image: url('@{url}/img/icons/black/openquestion-icon-1-2x.png');
  }
  .icon-dark.icon-openquestion-2 {
    background-image: url('@{url}/img/icons/black/openquestion-icon-2-2x.png');
  }
  .icon-dark.icon-openquestion-3 {
    background-image: url('@{url}/img/icons/black/openquestion-icon-3-2x.png');
  }
  .icon-dark.icon-openquestion-4 {
    background-image: url('@{url}/img/icons/black/openquestion-icon-4-2x.png');
  }
  .icon-dark.icon-openquestion-5 {
    background-image: url('@{url}/img/icons/black/icon-layout9-2x.png');
  }
  .icon-dark.icon-dragquestion-1 {
    background-image: url('@{url}/img/icons/black/icon-layout9-2x.png');
  }
  .icon-dark.icon-dragquestion-2 {
    background-image: url('@{url}/img/icons/black/icon-layout3-2x.png');
  }
  .icon-dark.icon-lesson {
    background-image: url('@{url}/img/icons/black/lesson.png');
  }
  .icon-dark.icon-tags {
    background-image: url('@{url}/img/icons/black/embed.png');
  }
  .icon-dark.icon-cloud-download {
    background-image: url('@{url}/img/icons/black/cloud-download.png');
  }
  .icon-dark.icon-chat {
    background-image: url('@{url}/img/icons/black/chat.png');
  }
  .icon-dark.icon-circle-right {
    background-image: url('@{url}/img/icons/black/circle-right.png');
  }
  .icon-dark.icon-mail {
    background-image: url('@{url}/img/icons/black/mail.png');
  }
  .icon-dark.icon-gift {
    background-image: url('@{url}/img/icons/black/gift.png');
  }
  .icon-dark.icon-power {
    background-image: url('@{url}/img/icons/black/power.png');
  }
  .icon-dark.icon-cloud {
    background-image: url('@{url}/img/icons/black/cloud.png');
  }
  .icon-dark.icon-cloud-check {
    background-image: url('@{url}/img/icons/black/cloud-check.png');
  }

  .icon-dark.icon-cloud-upload {
    background-image: url('@{url}/img/icons/black/cloud-upload.png');
  }
  .icon-dark.icon-dot {
    background-image: url('@{url}/img/icons/black/dot.png');
  }
  .icon-dark.icon-undo {
    background-image: url('@{url}/img/icons/black/undo.png');
  }
  .icon-dark.icon-redo {
    background-image: url('@{url}/img/icons/black/redo.png');
  }
  .icon-dark.icon-eraser {
    background-image: url('@{url}/img/icons/black/eraser.png');
  }
  .icon-dark.icon-flag {
    background-image: url('@{url}/img/icons/black/flag.png');
  }
  .icon-dark.icon-bold {
    background-image: url('@{url}/img/icons/black/bold.png');
  }
  .icon-dark.icon-italic {
    background-image: url('@{url}/img/icons/black/italic.png');
  }
  .icon-dark.icon-underline {
    background-image: url('@{url}/img/icons/black/underline.png');
  }
  .icon-dark.icon-superscript {
    background-image: url('@{url}/img/icons/black/superscript.png');
  }
  .icon-dark.icon-subscript {
    background-image: url('@{url}/img/icons/black/subscript.png');
  }
  .icon-dark.icon-strikethrough {
    background-image: url('@{url}/img/icons/black/strikethrough.png');
  }
  .icon-dark.icon-font {
    background-image: url('@{url}/img/icons/black/font.png');
  }
  .icon-dark.icon-font2 {
    background-image: url('@{url}/img/icons/black/font2.png');
  }
  .icon-dark.icon-printer {
    background-image: url('@{url}/img/icons/black/printer.png');
  }
  .icon-dark.icon-stats {
    background-image: url('@{url}/img/icons/black/stats.png');
  }
  .icon-dark.icon-trophy {
    background-image: url('@{url}/img/icons/black/trophy.png');
  }
  .icon-dark.icon-gamepad {
    background-image: url('@{url}/img/icons/black/gamepad.png');
  }
  .icon-dark.icon-enlarge {
    background-image: url('@{url}/img/icons/black/enlarge.png');
  }
  .icon-dark.icon-shrink {
    background-image: url('@{url}/img/icons/black/shrink.png');
  }
  .icon-dark.icon-clock {
    background-image: url('@{url}/img/icons/black/clock.png');
  }
  .icon-dark.icon-mobile {
    background-image: url('@{url}/img/icons/black/mobile2.png');
  }
  .icon-dark.icon-checkbox-checked {
    background-image: url('@{url}/img/icons/black/checkbox-checked.png');
  }
  .icon-dark.icon-checkbox-unchecked {
    background-image: url('@{url}/img/icons/black/checkbox-unchecked.png');
  }
  .icon-dark.icon-file-excel {
    background-image: url('@{url}/img/icons/black/file-excel.png');
  }
  .icon-dark.icon-document-diagrams {
    background-image: url('@{url}/img/icons/black/document-diagrams.png');
  }
  .icon-dark.icon-thumbs-up {
    background-image: url('@{url}/img/icons/black/thumbs-o-up.png');
  }
  .icon-dark.icon-thumbs-down {
    background-image: url('@{url}/img/icons/black/thumbs-o-down.png');
  }
  .icon-dark.icon-thumbs-up2 {
    background-image: url('@{url}/img/icons/black/thumbs-up.png');
  }
  .icon-dark.icon-note {
    background-image: url('@{url}/img/icons/black/note.png');
  }
  .icon-dark.icon-zoom-in {
    background-image: url('@{url}/img/icons/black/zoom-in.png');
  }
  .icon-dark.icon-zoom-out {
    background-image: url('@{url}/img/icons/black/zoom-out.png');
  }
  .icon-dark.icon-group {
    background-image: url('@{url}/img/icons/black/group.png');
  }
  .icon-dark.icon-homework {
    background-image: url('@{url}/img/icons/black/homework.png');
  }
  .icon-dark.icon-diff {
    background-image: url('@{url}/img/icons/black/diff.png');
  }
  .icon-dark.icon-compass {
    background-image: url('@{url}/img/icons/black/compass.png');
  }
  .icon-dark.icon-compass2 {
    background-image: url('@{url}/img/icons/black/compass2.png');
  }
  .icon-dark.icon-paragraph {
    background-image: url('@{url}/img/icons/black/paragraph.png');
  }
  .icon-dark.icon-toeter {
    background-image: url('@{url}/img/icons/black/toeter.png');
  }
  .icon-dark.icon-radio-tower {
    background-image: url('@{url}/img/icons/black/radio-tower.png');
  }
  .icon-dark.icon-sigma {
    background-image: url('@{url}/img/icons/black/sigma.png');
  }
  .icon-dark.icon-cube {
    background-image: url('@{url}/img/icons/black/cube.png');
  }
  .icon-dark.icon-traffic {
    background-image: url('@{url}/img/icons/black/traffic.png');
  }
  .icon-dark.icon-hotspot {
    background-image: url('@{url}/img/icons/black/hotspot.png');
  }
  .icon-dark.icon-music {
    background-image: url('@{url}/img/icons/black/music.png');
  }
  .icon-dark.icon-headphones {
    background-image: url('@{url}/img/icons/black/headphones.png');
  }
  .icon-dark.icon-mic {
    background-image: url('@{url}/img/icons/black/mic.png');
  }
  .icon-dark.icon-to-front {
    background-image: url('@{url}/img/icons/black/flip_to_front.png');
  }
  .icon-dark.icon-to-back {
    background-image: url('@{url}/img/icons/black/flip_to_back.png');
  }
  .icon-dark.icon-retry {
    background-image: url('@{url}/img/icons/black/retry.png');
  }
  .icon-dark.icon-view-powerpoint {
    background-image: url('@{url}/img/icons/black/view-powerpoint.png');
  }
  .icon-dark.icon-view-overview {
    background-image: url('@{url}/img/icons/black/view-overview.png');
  }
  .icon-dark.icon-view-slide {
    background-image: url('@{url}/img/icons/black/view-slide.png');
  }
  .icon-dark.icon-smiley {
    background-image: url('@{url}/img/icons/black/smiley.png');
  }
  .icon-dark.icon-cart {
    background-image: url('@{url}/img/icons/black/cart.png');
  }
  .icon-dark.icon-layout {
    background-image: url('@{url}/img/icons/black/layout.png');
  }
  .icon-dark.icon-columns {
    background-image: url('@{url}/img/icons/black/columns.png');
  }
  .icon-dark.icon-pricetags {
    background-image: url('@{url}/img/icons/black/pricetags.png');
  }
  .icon-dark.icon-calendar {
    background-image: url('@{url}/img/icons/black/calendar.png');
  }
  .icon-dark.icon-toets {
    background-image: url('@{url}/img/icons/black/checklist.png');
  }
  .icon-dark.icon-onoff {
    background-image: url('@{url}/img/icons/black/onoff.png');
  }
  .icon-dark.icon-camera {
    background-image: url('@{url}/img/icons/black/camera.png');
  }
  .icon-dark.icon-video {
    background-image: url('@{url}/img/icons/black/video.png');
  }
  .icon-dark.icon-record {
    background-image: url('@{url}/img/icons/black/record.png');
  }
  .icon-dark.icon-template {
    background-image: url('@{url}/img/icons/black/document-certificate.png');
  }
  .icon-dark.icon-speaker {
    background-image: url('@{url}/img/icons/black/speaker.png');
  }
  .icon-dark.icon-exit {
    background-image: url('@{url}/img/icons/black/exit.png');
  }
  .icon-dark.icon-qr {
    background-image: url('@{url}/img/icons/black/qr.png');
  }
  .icon-dark.icon-fullscreen {
    background-image: url('@{url}/img/icons/black/fullscreen.png');
  }
  .icon-dark.icon-fullscreen-exit {
    background-image: url('@{url}/img/icons/black/fullscreen-exit.png');
  }
  .icon-dark.icon-target {
    background-image: url('@{url}/img/icons/black/target.png');
  }
  .icon-dark.icon-unlink {
    background-image: url('@{url}/img/icons/black/chain-broken.png');
  }
  .icon-dark.icon-google-classroom {
    background-image: url('@{url}/img/icons/black/google-classroom-2x.png');
  }
  .icon-dark.after-icon-pin:after {
    background-image: url('@{url}/img/icons/black/pushpin.png');
  }
  .icon-dark.after-icon-group:after {
    background-image: url('@{url}/img/icons/black/group.png');
  }
  .icon-dark.after-icon-close:after {
    background-image: url('@{url}/img/icons/black/close.png');
  }
  .icon-dark.after-icon-close2:after {
    background-image: url('@{url}/img/icons/black/close-2.png');
  }
  .icon-brown-dark.icon-text {
    background-image: url('@{url}/img/icons/svg/text-icon.svg');
  }
  .icon-brown-dark.icon-video-screen {
    background-image: url('@{url}/img/icons/brown-dark/video-screen.png');
  }
  .icon-brown-dark.icon-search {
    background-image: url('@{url}/img/icons/brown-dark/search.png');
  }
  .icon-brown-dark.icon-plus {
    background-image: url('@{url}/img/icons/brown-dark/plus.png');
  }
  .icon-brown-dark.icon-pin {
    background-image: url('@{url}/img/icons/black/pushpin.png');
  }

  .icon-logo {
    background-image: url('@{url}/img/icons/white/logo.png');
  }
  .icon-search {
    background-image: url('@{url}/img/icons/white/search.png');
  }
  .icon-search2 {
    background-image: url('@{url}/img/icons/white/search2.png');
  }
  .icon-image {
    background-image: url('@{url}/img/icons/white/image.png');
  }
  .icon-images {
    background-image: url('@{url}/img/icons/white/images.png');
  }
  .icon-list {
    background-image: url('@{url}/img/icons/white/list.png');
  }
  .icon-numbered-list {
    background-image: url('@{url}/img/icons/white/numbered-list.png');
  }
  .icon-play {
    background-image: url('@{url}/img/icons/white/play.png');
  }
  .icon-pause {
    background-image: url('@{url}/img/icons/white/pause.png');
  }
  .icon-book {
    background-image: url('@{url}/img/icons/white/book3.png');
  }
  .icon-briefcase {
    background-image: url('@{url}/img/icons/white/briefcase.png');
  }
  .icon-drawer {
    background-image: url('@{url}/img/icons/white/drawer.png');
  }
  .icon-drawer2 {
    background-image: url('@{url}/img/icons/white/drawer2.png');
  }
  .icon-home {
    background-image: url('@{url}/img/icons/white/home.png');
  }
  .icon-plus {
    background-image: url('@{url}/img/icons/white/plus.png');
  }
  .icon-arrow-left {
    background-image: url('@{url}/img/icons/white/arrow-left.png');
  }
  .icon-arrow-right {
    background-image: url('@{url}/img/icons/white/arrow-right.png');
  }
  .icon-arrow-left2 {
    background-image: url('@{url}/img/icons/white/arrow-left2.png');
  }
  .icon-arrow-right2 {
    background-image: url('@{url}/img/icons/white/arrow-right2.png');
  }
  .icon-arrow-up2 {
    background-image: url('@{url}/img/icons/white/arrow-up2.png');
  }
  .icon-arrow-down2 {
    background-image: url('@{url}/img/icons/white/arrow-down2.png');
  }
  .icon-cog {
    background-image: url('@{url}/img/icons/white/cog.png');
  }

  .icon-star {
    background-image: url('@{url}/img/icons/white/star.png');
  }
  .icon-star2 {
    background-image: url('@{url}/img/icons/white/star2.png');
  }
  .icon-user {
    background-image: url('@{url}/img/icons/white/user.png');
  }
  .icon-cut {
    background-image: url('@{url}/img/icons/white/cut.png');
  }
  .icon-copy {
    background-image: url('@{url}/img/icons/white/copy.png');
  }
  .icon-paste {
    background-image: url('@{url}/img/icons/white/paste.png');
  }
  .icon-keyboard {
    background-image: url('@{url}/img/icons/white/keyboard.png');
  }
  .icon-close {
    background-image: url('@{url}/img/icons/white/close.png');
  }
  .icon-close2 {
    background-image: url('@{url}/img/icons/white/close-2.png');
  }
  .icon-remove {
    background-image: url('@{url}/img/icons/white/remove.png');
  }
  .icon-move {
    background-image: url('@{url}/img/icons/white/move.png');
  }
  .icon-move-2 {
    background-image: url('@{url}/img/icons/white/move2.png');
  }
  .icon-wrench {
    background-image: url('@{url}/img/icons/white/wrench.png');
  }
  .icon-up {
    background-image: url('@{url}/img/icons/white/arrow-up.png');
  }
  .icon-down {
    background-image: url('@{url}/img/icons/white/arrow-down.png');
  }
  .icon-pin {
    background-image: url('@{url}/img/icons/white/pushpin.png');
  }
  .icon-controls-left {
    background-image: url('@{url}/img/icons/white/controls-left.png');
  }
  .icon-controls-right {
    background-image: url('@{url}/img/icons/white/controls-right.png');
  }
  .icon-check {
    background-image: url('@{url}/img/icons/white/checkmark.png');
  }
  .icon-check-round {
    background-image: url('@{url}/img/icons/white/checkmark-round.png');
  }
  .icon-link {
    background-image: url('@{url}/img/icons/white/link.png');
  }
  .icon-map {
    background-image: url('@{url}/img/icons/white/map.png');
  }
  .icon-wand {
    background-image: url('@{url}/img/icons/white/wand.png');
  }
  .icon-slide {
    background-image: url('@{url}/img/icons/white/slide.png');
  }
  .icon-menu {
    background-image: url('@{url}/img/icons/white/menu.png');
  }
  .icon-pencil {
    background-image: url('@{url}/img/icons/white/pencil.png');
  }
  .icon-pencil-disabled {
    background-image: url('@{url}/img/icons/svg/pencil-disabled.svg');
  }
  .icon-pencil2 {
    background-image: url('@{url}/img/icons/white/pencil2.png');
  }
  .icon-droplet {
    background-image: url('@{url}/img/icons/white/droplet.png');
  }
  .icon-attachment {
    background-image: url('@{url}/img/icons/white/attachment.png');
  }
  .icon-align-left {
    background-image: url('@{url}/img/icons/white/paragraph-left.png');
  }
  .icon-align-center {
    background-image: url('@{url}/img/icons/white/paragraph-center.png');
  }
  .icon-align-right {
    background-image: url('@{url}/img/icons/white/paragraph-right.png');
  }
  .icon-align-justify {
    background-image: url('@{url}/img/icons/white/paragraph-justify.png');
  }
  .icon-vertical-align-top {
    background-image: url('@{url}/img/icons/white/vertical_align_top.png');
  }
  .icon-vertical-align-bottom {
    background-image: url('@{url}/img/icons/white/vertical_align_bottom.png');
  }
  .icon-vertical-align-center {
    background-image: url('@{url}/img/icons/white/vertical_align_center.png');
  }
  .icon-eye {
    background-image: url('@{url}/img/icons/white/eye.png');
  }
  .icon-eye-plus {
    background-image: url('@{url}/img/icons/white/eye-plus.png');
  }
  .icon-eye-blocked {
    background-image: url('@{url}/img/icons/white/eye-blocked.png');
  }
  .icon-share {
    background-image: url('@{url}/img/icons/white/share.png');
  }
  .icon-upload {
    background-image: url('@{url}/img/icons/white/upload.png');
  }
  .icon-download {
    background-image: url('@{url}/img/icons/white/download.png');
  }
  .icon-user-plus {
    background-image: url('@{url}/img/icons/white/user-plus.png');
  }
  .icon-user-check {
    background-image: url('@{url}/img/icons/white/user-check.png');
  }
  .icon-help {
    background-image: url('@{url}/img/icons/white/help.png');
  }
  .icon-stop {
    background-image: url('@{url}/img/icons/white/stop.png');
  }
  .icon-sound-on {
    background-image: url('@{url}/img/icons/white/volume.png');
  }
  .icon-sound-off {
    background-image: url('@{url}/img/icons/white/volume-mute.png');
  }
  .icon-external {
    background-image: url('@{url}/img/icons/white/external-link.png');
  }
  .icon-display {
    background-image: url('@{url}/img/icons/white/display.png');
  }
  .icon-file {
    background-image: url('@{url}/img/icons/white/file.png');
  }
  .icon-woordweb {
    background-image: url('@{url}/img/icons/white/woordweb.png');
  }
  .icon-stack {
    background-image: url('@{url}/img/icons/white/stack.png');
  }
  .icon-graduation {
    background-image: url('@{url}/img/icons/white/graduation.png');
  }
  .icon-layout-1 {
    background-image: url('@{url}/img/icons/white/icon-layout1-2x.png');
  }
  .icon-layout-2 {
    background-image: url('@{url}/img/icons/white/icon-layout2-2x.png');
  }
  .icon-layout-3 {
    background-image: url('@{url}/img/icons/white/icon-layout3-2x.png');
  }
  .icon-layout-4 {
    background-image: url('@{url}/img/icons/white/icon-layout4-2x.png');
  }
  .icon-layout-5 {
    background-image: url('@{url}/img/icons/white/icon-layout5-2x.png');
  }
  .icon-layout-6 {
    background-image: url('@{url}/img/icons/white/icon-layout6-2x.png');
  }
  .icon-layout-7 {
    background-image: url('@{url}/img/icons/white/icon-layout7-2x.png');
  }
  .icon-layout-8 {
    background-image: url('@{url}/img/icons/white/icon-layout8-2x.png');
  }
  .icon-layout-9 {
    background-image: url('@{url}/img/icons/white/icon-layout9-2x.png');
  }
  .icon-quiz {
    background-image: url('@{url}/img/icons/white/quiz.png');
  }
  .icon-quiz-1 {
    background-image: url('@{url}/img/icons/white/quiz-icon-1-2x.png');
  }
  .icon-quiz-2 {
    background-image: url('@{url}/img/icons/white/quiz-icon-2-2x.png');
  }
  .icon-quiz-3 {
    background-image: url('@{url}/img/icons/white/quiz-icon-3-2x.png');
  }
  .icon-quiz-4 {
    background-image: url('@{url}/img/icons/white/quiz-icon-4-2x.png');
  }
  .icon-quiz-5 {
    background-image: url('@{url}/img/icons/white/icon-layout9-2x.png');
  }
  .icon-openquestion-1 {
    background-image: url('@{url}/img/icons/white/openquestion-icon-1-2x.png');
  }
  .icon-openquestion-2 {
    background-image: url('@{url}/img/icons/white/openquestion-icon-2-2x.png');
  }
  .icon-openquestion-3 {
    background-image: url('@{url}/img/icons/white/openquestion-icon-3-2x.png');
  }
  .icon-openquestion-4 {
    background-image: url('@{url}/img/icons/white/openquestion-icon-4-2x.png');
  }
  .icon-openquestion-5 {
    background-image: url('@{url}/img/icons/white/icon-layout9-2x.png');
  }
  .icon-dragquestion-1 {
    background-image: url('@{url}/img/icons/white/icon-layout9-2x.png');
  }
  .icon-dragquestion-2 {
    background-image: url('@{url}/img/icons/white/icon-layout3-2x.png');
  }
  .icon-imagequestion {
    background-image: url('@{url}/img/icons/white/imagequestion.png');
  }
  .icon-openquestion {
    background-image: url('@{url}/img/icons/white/openquestion.png');
  }
  .icon-lock {
    background-image: url('@{url}/img/icons/white/lock.png');
  }
  .icon-unlocked {
    background-image: url('@{url}/img/icons/white/unlocked.png');
  }
  .icon-grid {
    background-image: url('@{url}/img/icons/white/grid.png');
  }
  .icon-powerpoint {
    background-image: url('@{url}/img/icons/white/powerpoint.png');
  }
  .icon-folder {
    background-image: url('@{url}/img/icons/white/folder.png');
  }
  .icon-folder-open {
    background-image: url('@{url}/img/icons/white/folder-open.png');
  }
  .icon-folder-new {
    background-image: url('@{url}/img/icons/white/folder-new.png');
  }
  .icon-lesson {
    background-image: url('@{url}/img/icons/white/lesson.png');
  }
  .icon-tags {
    background-image: url('@{url}/img/icons/white/embed.png');
  }
  .icon-cloud-download {
    background-image: url('@{url}/img/icons/white/cloud-download.png');
  }
  .icon-chat {
    background-image: url('@{url}/img/icons/white/chat.png');
  }
  .icon-circle-right {
    background-image: url('@{url}/img/icons/white/circle-right.png');
  }
  .icon-mail {
    background-image: url('@{url}/img/icons/white/mail.png');
  }
  .icon-gift {
    background-image: url('@{url}/img/icons/white/gift.png');
  }
  .icon-power {
    background-image: url('@{url}/img/icons/white/power.png');
  }
  .icon-cloud {
    background-image: url('@{url}/img/icons/white/cloud.png');
  }
  .icon-cloud-check {
    background-image: url('@{url}/img/icons/white/cloud-check.png');
  }
  .icon-cloud-upload {
    background-image: url('@{url}/img/icons/white/cloud-upload.png');
  }
  .icon-undo {
    background-image: url('@{url}/img/icons/white/undo.png');
  }
  .icon-redo {
    background-image: url('@{url}/img/icons/white/redo.png');
  }
  .icon-eraser {
    background-image: url('@{url}/img/icons/white/eraser.png');
  }
  .icon-flag {
    background-image: url('@{url}/img/icons/white/flag.png');
  }
  .icon-bold {
    background-image: url('@{url}/img/icons/white/bold.png');
  }
  .icon-italic {
    background-image: url('@{url}/img/icons/white/italic.png');
  }
  .icon-underline {
    background-image: url('@{url}/img/icons/white/underline.png');
  }
  .icon-superscript {
    background-image: url('@{url}/img/icons/white/superscript.png');
  }
  .icon-subscript {
    background-image: url('@{url}/img/icons/white/subscript.png');
  }
  .icon-strikethrough {
    background-image: url('@{url}/img/icons/white/strikethrough.png');
  }
  .icon-font {
    background-image: url('@{url}/img/icons/white/font.png');
  }
  .icon-font2 {
    background-image: url('@{url}/img/icons/white/font2.png');
  }
  .icon-printer {
    background-image: url('@{url}/img/icons/white/printer.png');
  }
  .icon-stats {
    background-image: url('@{url}/img/icons/white/stats.png');
  }
  .icon-trophy {
    background-image: url('@{url}/img/icons/white/trophy.png');
  }
  .icon-gamepad {
    background-image: url('@{url}/img/icons/white/gamepad.png');
  }
  .icon-youtube {
    background-image: url('@{url}/img/icons/white/youtube.png');
  }
  .icon-vimeo {
    background-image: url('@{url}/img/icons/white/vimeo.png');
  }
  .icon-youtube-interactive {
    background-image: url('@{url}/img/icons/white/youtube-interactive.png');
  }
  .icon-enlarge {
    background-image: url('@{url}/img/icons/white/enlarge.png');
  }
  .icon-shrink {
    background-image: url('@{url}/img/icons/white/shrink.png');
  }
  .icon-clock {
    background-image: url('@{url}/img/icons/white/clock.png');
  }
  .icon-mobile {
    background-image: url('@{url}/img/icons/white/mobile2.png');
  }
  .icon-checkbox-checked {
    background-image: url('@{url}/img/icons/white/checkbox-checked.png');
  }
  .icon-checkbox-unchecked {
    background-image: url('@{url}/img/icons/white/checkbox-unchecked.png');
  }
  .icon-file-excel {
    background-image: url('@{url}/img/icons/white/file-excel.png');
  }
  .icon-document-diagrams {
    background-image: url('@{url}/img/icons/white/document-diagrams.png');
  }
  .icon-history {
    background-image: url('@{url}/img/icons/white/history.png');
  }
  .icon-thumbs-up {
    background-image: url('@{url}/img/icons/white/thumbs-o-up.png');
  }
  .icon-thumbs-down {
    background-image: url('@{url}/img/icons/white/thumbs-o-down.png');
  }
  .icon-thumbs-up2 {
    background-image: url('@{url}/img/icons/white/thumbs-up.png');
  }
  .icon-up-after:after {
    background-image: url('@{url}/img/icons/black/arrow-up.png');
  }
  .icon-down-after:after {
    background-image: url('@{url}/img/icons/black/arrow-down.png');
  }
  .icon-note {
    background-image: url('@{url}/img/icons/white/note.png');
  }
  .icon-zoom-in {
    background-image: url('@{url}/img/icons/white/zoom-in.png');
  }
  .icon-zoom-out {
    background-image: url('@{url}/img/icons/white/zoom-out.png');
  }
  .icon-group {
    background-image: url('@{url}/img/icons/white/group.png');
  }
  .icon-homework {
    background-image: url('@{url}/img/icons/white/homework.png');
  }
  .icon-diff {
    background-image: url('@{url}/img/icons/white/diff.png');
  }
  .icon-compass {
    background-image: url('@{url}/img/icons/white/compass.png');
  }
  .icon-compass2 {
    background-image: url('@{url}/img/icons/white/compass2.png');
  }
  .icon-paragraph {
    background-image: url('@{url}/img/icons/white/paragraph.png');
  }
  .icon-toeter {
    background-image: url('@{url}/img/icons/white/toeter.png');
  }
  .icon-radio-tower {
    background-image: url('@{url}/img/icons/white/radio-tower.png');
  }
  .icon-sigma {
    background-image: url('@{url}/img/icons/white/sigma.png');
  }
  .icon-cube {
    background-image: url('@{url}/img/icons/white/cube.png');
  }
  .icon-traffic {
    background-image: url('@{url}/img/icons/white/traffic.png');
  }
  .icon-hotspot {
    background-image: url('@{url}/img/icons/white/hotspot.png');
  }
  .icon-music {
    background-image: url('@{url}/img/icons/white/music.png');
  }
  .icon-headphones {
    background-image: url('@{url}/img/icons/white/headphones.png');
  }
  .icon-mic {
    background-image: url('@{url}/img/icons/white/mic.png');
  }
  .icon-to-front {
    background-image: url('@{url}/img/icons/white/flip_to_front.png');
  }
  .icon-to-back {
    background-image: url('@{url}/img/icons/white/flip_to_back.png');
  }
  .icon-retry {
    background-image: url('@{url}/img/icons/white/retry.png');
  }
  .icon-view-powerpoint {
    background-image: url('@{url}/img/icons/white/view-powerpoint.png');
  }
  .icon-view-overview {
    background-image: url('@{url}/img/icons/white/view-overview.png');
  }
  .icon-view-slide {
    background-image: url('@{url}/img/icons/white/view-slide.png');
  }
  .icon-smiley {
    background-image: url('@{url}/img/icons/white/smiley.png');
  }
  .icon-cart {
    background-image: url('@{url}/img/icons/white/cart.png');
  }
  .icon-layout {
    background-image: url('@{url}/img/icons/white/layout.png');
  }
  .icon-border-rounded {
    background-image: url('@{url}/img/icons/white/border-rounded.png');
  }
  .icon-border-not-rounded {
    background-image: url('@{url}/img/icons/white/border-not-rounded.png');
  }
  .icon-border-round {
    background-image: url('@{url}/img/icons/white/border-round.png');
  }
  .icon-columns {
    background-image: url('@{url}/img/icons/white/columns.png');
  }
  .icon-pricetags {
    background-image: url('@{url}/img/icons/white/pricetags.png');
  }
  .icon-calendar {
    background-image: url('@{url}/img/icons/white/calendar.png');
  }
  .icon-toets {
    background-image: url('@{url}/img/icons/white/checklist.png');
  }
  .icon-onoff {
    background-image: url('@{url}/img/icons/white/onoff.png');
  }
  .icon-camera {
    background-image: url('@{url}/img/icons/white/camera.png');
  }
  .icon-video {
    background-image: url('@{url}/img/icons/white/video.png');
  }
  .icon-record {
    background-image: url('@{url}/img/icons/white/record.png');
  }
  .icon-speaker {
    background-image: url('@{url}/img/icons/white/speaker.png');
  }
  .icon-template {
    background-image: url('@{url}/img/icons/white/document-certificate.png');
  }
  .icon-power {
    background-image: url('@{url}/img/icons/white/switch.png');
  }
  .icon-exit {
    background-image: url('@{url}/img/icons/white/exit.png');
  }
  .icon-qr {
    background-image: url('@{url}/img/icons/white/qr.png');
  }
  .icon-fullscreen {
    background-image: url('@{url}/img/icons/white/fullscreen.png');
  }
  .icon-fullscreen-exit {
    background-image: url('@{url}/img/icons/white/fullscreen-exit.png');
  }
  .icon-target {
    background-image: url('@{url}/img/icons/white/target.png');
  }
  .icon-unlink {
    background-image: url('@{url}/img/icons/white/chain-broken.png');
  }

  .icon-keypad-apple {
    background-image: url('@{url}/img/icons/keypad/apple.png');
  }
  .icon-keypad-ball {
    background-image: url('@{url}/img/icons/keypad/ball.png');
  }
  .icon-keypad-balloon {
    background-image: url('@{url}/img/icons/keypad/balloon.png');
  }
  .icon-keypad-bell {
    background-image: url('@{url}/img/icons/keypad/bell.png');
  }
  .icon-keypad-boat {
    background-image: url('@{url}/img/icons/keypad/boat.png');
  }
  .icon-keypad-car {
    background-image: url('@{url}/img/icons/keypad/car.png');
  }
  .icon-keypad-dog {
    background-image: url('@{url}/img/icons/keypad/dog.png');
  }
  .icon-keypad-dolphin {
    background-image: url('@{url}/img/icons/keypad/dolphin.png');
  }
  .icon-keypad-frog {
    background-image: url('@{url}/img/icons/keypad/frog.png');
  }
  .icon-keypad-lights {
    background-image: url('@{url}/img/icons/keypad/lights.png');
  }
  .icon-keypad-lolly {
    background-image: url('@{url}/img/icons/keypad/lolly.png');
  }
  .icon-keypad-present {
    background-image: url('@{url}/img/icons/keypad/present.png');
  }
  .icon-keypad-rocket {
    background-image: url('@{url}/img/icons/keypad/rocket.png');
  }
  .icon-keypad-star {
    background-image: url('@{url}/img/icons/keypad/star.png');
  }
  .icon-keypad-crown {
    background-image: url('@{url}/img/icons/keypad/crown.png');
  }
  .icon-keypad-pizza {
    background-image: url('@{url}/img/icons/keypad/pizza.png');
  }
  .icon-keypad-fish {
    background-image: url('@{url}/img/icons/keypad/fish.png');
  }
  .icon-keypad-watermelon {
    background-image: url('@{url}/img/icons/keypad/watermelon.png');
  }
  .icon-keypad-bike {
    background-image: url('@{url}/img/icons/keypad/bike.png');
  }
  .icon-keypad-diamond {
    background-image: url('@{url}/img/icons/keypad/diamond.png');
  }
  .icon-keypad-cactus {
    background-image: url('@{url}/img/icons/keypad/cactus.png');
  }
  .icon-keypad-palm {
    background-image: url('@{url}/img/icons/keypad/palm.png');
  }
  .icon-keypad-clover {
    background-image: url('@{url}/img/icons/keypad/clover.png');
  }
  .icon-keypad-fire {
    background-image: url('@{url}/img/icons/keypad/fire.png');
  }
  .icon-keypad-cookie {
    background-image: url('@{url}/img/icons/keypad/cookie.png');
  }
  .icon-keypad-hat {
    background-image: url('@{url}/img/icons/keypad/hat.png');
  }

  /* SVG icons */
  .icon-arrow-right-dark-slim {
    background-image: url('@{url}/img/icons/black/arrow-right-dark-slim.svg');
  }

  .icon-nine-squares {
    background-image: url('@{url}/img/icons/svg/nine-squares.svg');
  }

  .icon-group-svg {
    background-image: url('@{url}/img/icons/svg/group.svg');
  }

  .icon-crown {
    background-image: url('@{url}/img/icons/svg/crown.svg');
  }

  .icon-crown-pro {
    background-image: url('@{url}/img/icons/svg/icon-crown-pro.svg');
  }

  .icon-lesson-thumb {
    background-image: url('@{url}/img/icons/svg/lesson.svg');
  }

  .icon-code {
    background-image: url('@{url}/img/icons/svg/code.svg');
  }

  .icon-questionmark {
    background-image: url('@{url}/img/icons/svg/questionmark.svg');
  }

  .icon-remove-outline {
    background-image: url('@{url}/img/icons/svg/remove-outline.svg');
  }

  .icon-settings-outline {
    background-image: url('@{url}/img/icons/svg/settings-outline.svg');
  }

  .icon-settings {
    background-image: url('@{url}/img/icon-cog.svg');
  }

  .icon-upload-outline {
    background-image: url('@{url}/img/icons/svg/upload-outline.svg');
  }

  .icon-compass-brown-dark {
    background-image: url('@{url}/img/icons/brown-dark/compass.png');
  }

  .icon-more-options-menu {
    background-image: url('@{url}/img/icons/svg/more-options-menu.svg');
  }
  .icon-checkmark-round-green {
    background-image: url('@{url}/img/icons/svg/checkmark-round.svg');
  }
  .icon-video-screen {
    background-image: url('@{url}/img/icons/white/video-screen.png');
  }
  .icon-som-2-day {
    background-image: url('@{url}/img/icons/svg/som.svg');
  }
  .icon-globe {
    background-image: url('@{url}/img/icons/svg/globe.svg');
  }
  .icon-language {
    background-image: url('@{url}/img/icons/svg/language.svg');
  }

  .icon-poll {
    background-image: url('@{url}/img/icons/svg/poll.svg');
  }

  .icon-hidden {
    background-image: url('@{url}/img/icons/svg/hidden.svg');
  }

  .icon-chart-pie {
    background-image: url('@{url}/img/icons/svg/chart-pie-grayscale.svg');
  }

  .icon-chart-bar {
    background-image: url('@{url}/img/icons/svg/chart-bar-grayscale.svg');
  }

  .icon-chart-scale {
    background-image: url('@{url}/img/icons/svg/chart-scale-grayscale.svg');
  }

  .icon-text {
    background-image: url('@{url}/img/icons/svg/text-icon-white.svg');
  }

  .icon-close-3 {
    background-image: url('@{url}/img/icons/svg/close-3.svg');
  }

  .icon-gift {
    background-image: url('@{url}/img/icons/svg/gift.svg');
  }

  .icon-download-2 {
    background-image: url('@{url}/img/icons/svg/download.svg');
  }

  .icon-archive {
    background-image: url('@{url}/img/icons/svg/archive.svg');
  }

  .icon-change {
    background-image: url('@{url}/img/icons/svg/change.svg');
  }

  .icon-upload-alternative {
    background-image: url('@{url}/img/icons/svg/upload.svg');
  }
}

.icon-default-pos {
  background-position: center center;
  min-width: 20px;
  min-height: 20px;
}
.icon-size-25 {
  background-size: 25% auto !important;
  background-position: center center !important;
}
.icon-size-35 {
  background-size: 35% auto !important;
  background-position: center center !important;
}
.icon-size-40 {
  background-size: 40% auto !important;
  background-position: center center !important;
}
.icon-size-50 {
  background-size: 50% auto !important;
  background-position: center center !important;
}
.icon-size-55 {
  background-size: 55% auto !important;
  background-position: center center !important;
}
.icon-size-60 {
  background-size: 60% auto !important;
  background-position: center center !important;
}
.icon-size-70 {
  background-size: 70% auto !important;
  background-position: center center !important;
}
.icon-size-75 {
  background-size: 75% auto !important;
  background-position: center center !important;
}

/*.after-check-white {
	&:after {
		content:''; display: block; position: absolute;
		width: 12px; height: 12px; right: 3px; top: 3px;
		background: url('@{url}/img/icons/white/checkmark.png') no-repeat;
		background-position: right top;
		background-size: contain;
		z-index: 3;
	}
}*/
