@import (once) url('rootImports.less');
@import (once) url('./LessonPlanRoot.less');

.lesson-plan-text {
  width: auto;
  max-width: @column3;
  word-wrap: break-word;
  .small-buttons {
    top: -70px;
    right: -10px;
    padding: 0 3px;
    border-radius: 5px 5px 0 0;
    &:before {
      display: none;
    }
  }
  .child-buttons {
    padding: 0 0 0 3px;
    border-radius: 5px 0 0 0;
  }

  .small-buttons {
    .translatey(0);
    opacity: 1;
    pointer-events: all;
  }
}
.paragraph,
.content-page-paragraph {
  color: @default-text;
  .font-montserrat;
  font-size: 16px;
  line-height: 1.4em;
  margin: 0;
  a {
    color: @blue;
  }
  &.text-align-center {
    text-align: center;
  }
  &.text-align-left {
    text-align: left;
  }
  &.text-align-right {
    text-align: right;
  }

  &.text-align-justify {
    text-align: justify;
    text-align-last: justify;
  }
  overflow: hidden;
}

@assetsUrl: /search/public;