@import (once) url('../_imports.less');

.component-dropzone {
  .comp-dropzone {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .dropzone-name {
    font-size: 60%;
    color: @default-text;
    .font-odin;
    position: absolute;
    left: 50%;
    top: 50%;
    .translate(-50%, -50%);
  }
  .text-value {
    line-height: 1.5em;
    border: none !important;
    outline: none;
    &.vertical-align-top {
    }
    &.vertical-align-center {
      position: absolute;
      top: 50%;
      width: 100%;
      .translatey(-50%);
    }
    &.vertical-align-bottom {
      position: absolute;
      bottom: 0;
      width: 100%;
    }
  }
}

@assetsUrl: /search/public;