@import (once) url('rootImports.less');

@tab-height: 50px;
@outer-padding: @search-results-outer-padding-mobile;
@border-size: 3px;
@tab-border: @border-size solid @grey-bg;

@border-padding-small: @outer-padding + @border-size;
@border-padding-large: @search-results-outer-padding-desktop + @border-size;

.gray-tabs-bar {
  display: flex;
  flex-direction: row;
  height: @gray-tabs-bar-height;
  align-items: center;
  background-color: #fff;
  position: relative;
  color: @default-text;
  box-sizing: border-box;
  border-bottom: @border-size solid @grey-bg;

  a {
    text-decoration: none;
  }

  .gray-title {
    font-family: @lessonup-heading;
    color: @brown-dark;
    font-size: 28px;
  }

  .tabs {
    align-self: flex-end;
    display: flex;
    height: @tab-height;
    position: absolute;
    bottom: 0;
  }

  .secondary-child {
    position: absolute;
    font-weight: 600;
    right: 0;
    bottom: 2 * @border-size;
    height: @tab-height;
    display: flex;
    align-items: flex-end;
    font-family: @lessonup-heading;
    font-size: 1.1rem;
  }

  .gray-tab {
    background-color: @grey-bg;
    border: 3px solid transparent;
    border-bottom-width: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    font-weight: 500;
    font-size: 0.95rem;
    margin-right: 0.6rem;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    height: ~'calc(100% + 3px)';

    align-items: center;
    display: flex;

    cursor: pointer;
    text-decoration: none;

    span {
      line-height: 19px;
    }

    &.active {
      background-color: #fff;
      cursor: normal;
      border-color: @grey-bg;
    }

    .explanation-tag {
      margin-left: 0.6em;
      padding: 0.6em;
      border-radius: 100%;
      font-size: 11px;
      color: @brown-dark;
      width: 30px;
      height: 30px;
      display: inline-block;
      text-align: center;

      .explanation-tag-tooltip {
        opacity: 0;
        display: none;
        font-size: 1rem;
        text-align: left;
      }

      &:hover {
        .explanation-tag-tooltip {
          opacity: 1;
          display: block;
        }
      }
    }

    .explanation-tag-tooltip {
      min-width: 250px;
    }

    & > *:not(:first-child) {
      margin-left: 0.3rem;
    }
  }
  .tabs-border-element {
    border-bottom: @tab-border;
    height: 100%;
    flex-grow: 1;
    margin-left: 50px;
  }

  @media (min-width: @mq-sm-min) {
    .gray-tab {
      .explanation-tag {
        margin-left: 1em;
        margin-bottom: 0;
        padding: 0.2em 1em;
        border-radius: 10px;
        line-height: 1.3;
        width: auto;
        height: auto;
      }
    }
  }
}
// this never worked on teacher since it outputted (@media (min-width: 959px + 1)
// Now that this is fixed it broke classes layout, so made this code search only
.searchpage {
  .gray-tabs-bar {
    @media (min-width: @search-sidebar-desktop-min) {
      position: fixed;
      width: 100%;
      z-index: 1;

      &:after {
        content: '';
        height: @gray-tabs-margin-bottom;
        width: 100%;
        left: 0;
        display: block;
        position: absolute;
        top: @gray-tabs-bar-height;
        background-color: #fff;
      }
    }
  }
}

@assetsUrl: /search/public;