@import (once) url('_imports.less');

.slide-note-content,
#page-inner .slide-note-content {
  font-family: 'montserrat';
  p {
    padding-left: 0;
    color: inherit;
    font-size: inherit;
    line-height: inherit;
    margin: 0;
  }
  a {
    color: @link;
  }
}

@assetsUrl: /search/public;