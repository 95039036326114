@import (once) url('../_imports.less');

#question {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  /*background: @blue-dark;*/
  overflow: hidden;
  #title {
    text-align: center;
  }

  &.view-mode {
  }

  &.preview {
    width: auto;
    height: auto;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;

    #question-inner {
      // width: 800px; height: 700px;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      // .transform-origin (left, top);
      .transform (scale(180/800));; /* this is for portait pins, the landscape media query is in pin.less */
    }
  }

  .fg {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
  .image {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .question {
    display: block;
    color: inherit;
    position: absolute;
    top: 5%;
    left: 5%;
    width: 90%;
    height: 35%;
    font-size: 80%;
    line-height: 1.2em;
    padding: 1%;
    font-family: @lessonup-title;
    z-index: 2;
  }

  &.view-mode {
    .question {
      overflow: hidden;
    }
  }

  #title,
  .text {
    text-align: center;
    &.text-align-1 {
      text-align: center;
    }
    &.text-align-2 {
      text-align: left;
    }
    &.text-align-3 {
      text-align: right;
    }
  }

  .buttons {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
    .button {
      margin: 0 0 0 10px;
    }
  }

  #answers {
    position: absolute;
    top: 41%;
    width: 100%;
    height: 48%;
  }
  .answer {
    display: block;
    width: 50%;
    float: left;
    height: 50%;
    line-height: 1.2em;
    padding: 2% 1% 2% 5%;
    font-family: @lessonup-body;
    text-align: left;
    border: none;
    color: #fff;
    position: relative;

    &.correct {
      .inner {
        background: lighten(@correct, 25) !important;
        .answer-text {
          color: #fff !important;
        }
        .nr {
          color: transparent !important;
          background: url('@{assetsUrl}/img/icons/white/checkmark.png') no-repeat center center !important;
          background-size: 100% auto !important;
        }
      }
    }
    &.iscorrect {
      .inner {
        background: lighten(@correct, 45);
        background: repeating-linear-gradient(
          45deg,
          lighten(@correct, 35),
          lighten(@correct, 35) 10px,
          lighten(@correct, 45) 10px,
          lighten(@correct, 45) 20px
        );
        .answer-text {
          color: #fff !important;
        }
      }
      .nr {
        color: transparent !important;
        background: url('@{assetsUrl}/img/icons/white/checkmark.png') no-repeat center center !important;
        background-size: 100% auto !important;
      }
    }
    &.incorrect {
      .inner {
        background: @red !important;
        .nr,
        .answer-text {
          color: #fff !important;
        }
      }
    }
    &.opinion {
      .inner {
        background: @yellow !important;
        .nr,
        .answer-text {
          color: #fff !important;
        }
      }
    }

    .quiz-options-aligment {
      position: absolute;
      width: 4%;
      height: 15%;
      top: 18%;
      cursor: pointer;
    }

    .quiz-options {
      // display: none;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: red;
      background: #ffffff url(img/icons/black/cog.png) no-repeat center center;
      background-size: 100% auto;
      cursor: pointer;
      z-index: 1;

      &:hover {
        display: block;
      }
    }
    &:hover {
      .quiz-options {
        display: block;
      }
    }

    > .inner {
      position: absolute;
      left: 10%;
      top: 10%;
      right: 10%;
      bottom: 10%;

      border-radius: 5px;
      background: #fff;

      textarea,
      .answer-text {
        position: absolute;
        width: 100%;
        height: 100%;
        padding: 5%;
        padding-bottom: 0;
        overflow: hidden;
        border: none;
        font-size: 60%;
        line-height: 1.1em;
        font-family: @lessonup-body;
        resize: none;
        color: darken(@blue, 40);
        &:active,
        &:focus {
          outline: none;
        }
      }
      .answer-text {
        height: auto;
        top: 50%;
        .transform(translateY(-50%));
        padding: 0 5%;
        max-height: 100%;
        overflow: hidden;
      }
      .answer-guppy {
        position: absolute;
        .transform(translateY(-50%));
        top: 50%;
        color: black;
        max-height: 100%;
        overflow: hidden;
        .katex {
          margin-left: 6px;
          font-size: 80%;
        }
      }

      .answer-zoom-icon {
        display: block;
        height: 70%;
        opacity: 0.5;
        cursor: zoom-in;
        top: 50%;
        position: absolute;
        transform: translateY(-50%);
        background: rgb(158, 158, 158);
        border-radius: 100%;
        padding: 3%;

        &.zoom-icon-left {
          left: 18%;
        }

        &.zoom-icon-right {
          right: 18%;
        }
      }

      .answer-image {
        .align-content-right {
          margin-right: 6%;
          margin-left: auto;
        }

        .align-content-left {
          margin-right: auto;
          margin-left: 6%;
        }
      }

      .answer-audio {
        position: absolute;
        .transform(translateY(-50%));
        top: 50%;
        max-height: 100%;
        height: 90%;
        width: 23%;

        border-radius: 100%;

        .border {
          position: absolute;
          width: 95%;
          height: 95%;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          border-radius: 3000px;
          background: #000;
        }

        .core {
          position: absolute;
          width: 75%;
          height: 75%;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          border-radius: 3000px;
          background: #fff;
          background-color: #ca4cfd;
        }

        &.answer-audio-align-right {
          right: 4%;
        }

        &.answer-audio-align-left {
          left: 4%;
        }

        // .answer-audio-border {
        // 	position: absolute;
        // 	width: 95%;
        // 	height: 95%;
        // 	left: 50%;
        // 	top: 50%;
        // 	transform: translate(-50%, -50%);
        // 	border-radius: 100%;
        // 	background: #000;
        // }

        .answer-audio-image {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: 100%;
          background-size: 70%;
          background-repeat: no-repeat;
          background-position: center center;
        }

        &.answer-audio-paused {
          .icon-playing-state.icon-dark {
            background-image: url('../img/icons/black/play.png');
          }
        }

        &.answer-audio-playing {
          .icon-playing-state.icon-dark {
            background-image: url('@{assetsUrl}/img/icons/black/pause.png');
          }
        }

        .icon-playing-state.icon-dark {
          background-image: url('@{assetsUrl}/img/icons/black/speaker.png');
        }

        .icon-playing-state {
          background-image: url('@{assetsUrl}/img/icons/white/speaker.png');
        }

        &.answer-audio-paused {
          .icon-playing-state {
            background-image: url('../img/icons/white/play.png');
          }
        }

        &.answer-audio-playing {
          .icon-playing-state {
            background-image: url('@{assetsUrl}/img/icons/white/pause.png');
          }
        }
      }
    }

    .icon {
      position: absolute;
      top: 50%;
      .transform(translateY(-50%));
      width: 10%;
      height: 40%;
      border: solid #fff 2px;
      color: #fff;
      text-align: center;
      line-height: 1em;
      cursor: pointer;
      background-size: 80% auto;
      background-image: url('@{assetsUrl}/img/icons/white/checkmark.png');
      opacity: 0.3;
      &.checked {
        opacity: 1;
      }
      .button-tip {
        z-index: 999;
        .transform(translate(0, 0));
      }
    }
    .nr {
      position: absolute;
      left: 6%;
      top: 53%;
      color: @pink;
      text-align: center;
      font-family: @lessonup-round;
      text-transform: uppercase;
      font-size: 100%;
      line-height: 1.5em;
      .transform(translateY(-50%));
    }
    .text {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;
      color: inherit;
      text-align: inherit;
      padding: inherit;
      border: none;
    }
  }
  .answer1,
  .answer3 {
    & > .inner {
      border-radius: 500px 0 0 500px;
      right: 4%;
      .answer-text {
        padding-left: 15%;
        text-align: right;
      }
      .icon {
        right: -15%;
      }
      .answer-guppy {
        right: 4%;

        &.answer-nospacing {
          right: 0px;
        }
      }
    }
    .quiz-options-aligment {
      left: 17%;
    }
  }
  .answer2,
  .answer4 {
    & > .inner {
      border-radius: 0 500px 500px 0;
      left: 4%;

      .answer-text {
        padding-right: 15%;
        text-align: left;
        border-radius: 0 500px 500px 0;
      }
      .nr {
        left: auto;
        right: 6%;
      }
      .answer-guppy {
        left: 4%;

        &.answer-nospacing {
          left: 0px;
        }
      }
    }
    .quiz-options-aligment {
      right: 17%;
    }
  }
  #question-inner {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
  textarea {
    font-family: inherit;
    color: @grey-dark;
    background-color: transparent;
  }
  textarea::-webkit-scrollbar {
    display: none;
  }
  .text {
    resize: none;
  }
  /* the normal colors have no contrast with background */
  &.question-color-8 .answer.correct .inner {
    background: @correct !important;
  }
  &.question-color-2 .answer.opinion .inner {
    background: darken(@yellow, 12%) !important;
  }
  .component-area {
    @height: 43%;
    @scaleup: 100 ./ @height*100;
    width: 100%;
    height: @height;
    position: absolute;
    overflow: hidden;
    .scale-up {
      height: @scaleup;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }
  }
  &.edit-mode {
    .answer .nr {
      top: 60%;
    }
  }
  //white fixes
  &.question-color-9 {
    .inner {
      border: 1px solid #111;
    }
    #pick-answer .checked {
      border: 1px solid #111;
    }
    &.edit-mode .text:not(.button) {
      border: rgba(0, 0, 0, 0.3) 1px solid;
    }
  }
  &.question-color-9.question-layout-1.has-image {
    .inner {
      border: none;
    }
    #pick-answer .checked {
      border: none;
    }
  }

  .indicator-bottom-right {
    position: absolute;
    bottom: 2%;
    right: 1%;
    width: 25px;
    height: 20px;
    cursor: pointer;
  }
}

#questionPreview,
.content-type-question {
  #title {
    max-height: 36% !important;
    overflow-y: hidden;
  }
}

@assetsUrl: /search/public;