@import (once) url('rootImports.less');

.forgot-password-page {
  .password-forgotten-title {
    text-align: center;
  }

  .forgot-password {
    display: flex;
    flex-direction: column;
    align-items: center;

    .spread-content {
      justify-content: space-between;
    }

    @media (min-width: @mq-sm-min) {
      flex-direction: row;
    }
  }

  .completed-message {
    text-align: center;
  }

  .default-form {
    .action-button {
      margin-top: 30px;
    }
  }
}

@assetsUrl: /search/public;