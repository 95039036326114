@import (once) url('_imports.less');

@directions: left, right, top, bottom;
@amount-of-variations: 6;

.spacing-loop(@type, @multiplier) when (@multiplier > 0) {
  .@{type}-@{multiplier} {
    @{type}: @spacer * @multiplier;
  }
  .spacing-loop(@type, @multiplier - 1);
}

.spacing-direction-multiplier-loop(@type, @direction, @multiplier) when (@multiplier > 0) {
  .@{type}-@{direction}-@{multiplier} {
    @{type}-@{direction}: @spacer * @multiplier;
  }
  .spacing-direction-multiplier-loop(@type, @direction, @multiplier - 1);
}

.spacing-direction-loop(@type, @multiplier, @directionIndex) when (@directionIndex > 0) {
  .spacing-direction-multiplier-loop(@type, extract(@directions, @directionIndex), @multiplier);
  .spacing-direction-loop(@type, @multiplier, @directionIndex - 1);
}

.spacing-directions-loop(@type, @multiplier) {
  .spacing-direction-loop(@type, @multiplier, length(@directions));
}

.spacing-loop(padding, @amount-of-variations);
.spacing-loop(margin, @amount-of-variations);

.spacing-directions-loop(padding, @amount-of-variations);
.spacing-directions-loop(margin, @amount-of-variations);
