@import (once) url('rootImports.less');

@outer-margin-mobile: @outer-page-margin-mobile;
@outer-margin-desktop: @outer-page-margin-desktop;
@column: 256px;
@row: 220px;
@subtitle-line-height: 1.4;

.chan-header {
  height: 300px;
  color: #fff;
  position: relative;
  width: 100%;

  .visual {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
  }

  .glasspane {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.9), transparent);
  }

  .channel-banner-content {
    flex-direction: row;
  }

  .channel-icon-and-name {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: center;

    padding-left: @outer-page-margin-mobile;
    padding-right: @outer-page-margin-mobile;

    align-content: center;

    .header-icon {
      width: 100px;
      height: 100px;
      background-position: center;
      background-size: cover;
      margin-right: 1em;
    }

    .channel-name-description {
      .channel-name {
        font-family: @lessonup-heading;
        font-size: 1.6em;
      }
      .subtitle {
        font-family: @lessonup-body-new;
        line-height: @subtitle-line-height;
        .line-clamp(1em * @subtitle-line-height, 2);
      }
      .buttonbar {
        margin-top: 1em;
        .button-margin-right {
          margin-right: 0.3em;
        }
      }
    }

    @media (min-width: @mq-sm-min) {
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      .channel-name-description {
        .channel-name {
          font-size: 2.2em;
        }
      }
    }

    @media (min-width: @mq-md-min) {
      padding-left: @outer-page-margin-desktop;
      padding-right: @outer-page-margin-desktop;
    }
  }
}
.iframe-header {
  height: 170px;
  margin-top: 0;

  .channel-icon-and-name {
    max-width: 1200px;
    margin: auto;
  }
}
.iframe-container {
  height: 100%;
  &.lesson-font-inherit {
    .ui-heading,
    .channel-info {
      font-family: inherit;
    }
  }
}
.channel-about {
  max-width: 900px;
  margin: 30px auto auto;

  .content-page {
    padding: 15px;
    color: #000;
  }
}

.channel-container {
  .plan-wrapper {
    .breadcrumb-list {
      padding: 40px 40px 0;
    }
  }
}

@assetsUrl: /search/public;