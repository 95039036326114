@import (once) url('rootImports.less');

@border-rest: 2px solid #fff;
@border-active: 2px solid rgb(216, 216, 216);
@background: @white-2;

.context-menu {
  position: absolute;
  cursor: pointer;

  svg {
    position: absolute;
    top: 6px;
    fill: @grey-dark;
  }

  .open-chevron {
    position: relative;
    display: flex;
    width: 20px;
    height: 20px;
    padding: 2px;
    background-color: @background;
    border: @border-rest;
    z-index: 1;
  }

  .context-menu-inner {
    background-color: @background;
    position: absolute;
    top: 18px;
    border: @border-active;
    padding: 10px;
  }

  &.open {
    .open-chevron {
      border: @border-active;
      border-bottom: 0;
    }
  }
}

@assetsUrl: /search/public;