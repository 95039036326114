@import (once) url('_imports.less');

.emoji {
  width: 30px;
  height: 30px;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  vertical-align: middle;
  margin: 0;
  font-size: 30px;
  line-height: 33px;
  font-family: 'Segoe UI Emoji';
  pre {
    margin: 0;
  }
}

@assetsUrl: /search/public;