@import (once) url('_imports.less');

.yt-video {
  height: 100%;
  width: 100%;
}

.vimeo-video {
  height: 100%;
  width: 100%;
  overflow: hidden;
  iframe {
    height: 100%;
    width: 100%;
  }
}

.video-controller {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  pointer-events: none;
  .video-timer {
    position: absolute;
    right: 0;
    font-size: 23%;
    background-color: @blue;
    color: white;
    border-bottom-left-radius: 0.3em;
    padding: 0.1% 0.6%;
    text-align: left;
    width: 9%;
    line-height: 1.6em;
  }
}

.time-line {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  bottom: 0;
  height: 0.05em;
  width: 100%;
  cursor: pointer;

  .time-indicator,
  .question-indicator,
  .start-stop-indicator {
    height: 100%;
    width: 0.05em;
    border-radius: 100%;
    position: absolute;
  }
  .time-indicator {
    width: 0.07em;
    background-color: @blue;
    transition: left 0.5s;
    transform: translateX(-20%);
  }
  .question-indicator {
    background-color: yellow;
  }
  .start-stop-indicator {
    background-color: red;
    border-radius: 0;
  }
}
.thumb-yt {
  background-color: black;
}

.video-indicator {
  position: absolute;
  left: 0;
  top: 5px;
  z-index: 42;
  .video-thumb {
    background-color: black;
    position: absolute;
    width: 70px;
    height: 40px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .video-time {
    text-align: center;
    position: absolute;
    background-color: #5a4d4c;
    padding: 2px;
    color: white;
    bottom: -10px;
    height: 20px;
    width: 100%;
  }
  .arrow {
    width: 23px;
    position: absolute;
    top: 52px;
    left: 5px;
    stroke: @grey-light2;
  }
  &.has-end-small {
    font-size: 80%;
  }
  &.has-end {
    .video-time {
      bottom: -25px;
      height: 35px;
      text-align: left;
      padding-left: 10px;
    }
    .edit-time {
      display: block;
      position: absolute;
      right: 5px;
      top: 12px;
    }
    .arrow {
      top: 72px;
    }
  }
}
.invalid-video {
  background-color: black;
  height: 100%;
  width: 100%;
  position: absolute;
  background-size: cover;
}
.invalid-video-msg {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .msg-inner {
    border-radius: 0.2em;
    background-color: @red;
    color: white;
    width: 60%;
    padding: 0.5em;
    text-align: center;
  }
  .explain {
    font-size: 60%;
    color: @grey;
  }
}

@assetsUrl: /search/public;