@import (once) url('rootImports.less');

@button-bar-height: @spacer*4 + 1.8rem;
@modal-spacer: 8 * @spacer;
@modal-surrounding-content-height: ~'calc(@{modal-title-height} + 2* @{modal-content-padding} + @{button-bar-height} + @{modal-spacer})';

.selected-content-confirmation {
  width: 100%;
  &__header {
    &:hover {
      text-decoration: underline;
    }
    cursor: pointer;
    margin-bottom: @spacer * 4;
    svg {
      margin-right: @spacer * 2;
    }
  }
  &__actions {
    margin-top: 2rem;
    padding-left: @spacer * 2;
    padding-right: @spacer * 2;
    align-items: center;
    justify-content: space-between;
  }
  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .preview-thumbnail {
    width: 350px;
  }
}

@assetsUrl: /search/public;