@import (once) url('../../lesson/lessonSummaryCard/BaseSummaryCard.less');
@border-width: 2px;
@total-top-padding: @summary-card-padding + @border-width;
@height: 300px;
@summary-card-margin: 15px;
@column: 256px;

.plan-summary-cards {
  position: relative;

  .plan-url {
    font-size: 80%;
    color: @cyan;
    margin-left: 12px;
  }
  .cards {
    display: flex;
    margin-left: @summary-card-margin * -1;
    margin-right: @summary-card-margin * -1;
  }
  &.one-liner .cards {
    max-width: ~'calc(100vw - 400px)';
  }
  &.full-page .cards {
    flex-wrap: wrap;
  }
}
.plan-summary-card {
  flex: 1;
  background-color: @white;
  border: @border-width solid @white-2;
  height: @height;
  min-width: 256px;
  margin: @summary-card-margin;

  .ui-tags {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .thumb-banner {
    bottom: 40px;
    left: -7px;
  }

  .plan-summary-inner {
    text-decoration: none;
    max-height: @height - @total-top-padding - 1;
    display: block;
    overflow: hidden;
  }
  .plan-title {
    font-family: @lessonup-heading;
    font-size: 18px;
    max-height: 2.6em;
    overflow: hidden;
    line-height: 1.5em;
    margin-top: 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .plan-body {
    display: flex;
    flex-wrap: wrap;
    .lesson-thumb {
      width: 45%;
      margin: 2%;
    }
    img {
      width: 100%;
    }
  }
  .plan-section {
    width: ~'calc(100% - 35px)';
    margin-left: 30px;
    position: relative;
    padding-left: 0;
  }
  .plan-description {
    max-height: 2.8em;
    overflow: hidden;
    line-height: 1.4em;
    margin-bottom: 6px;

    @supports (-webkit-line-clamp: 2) {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      height: auto;
    }
  }
  .section-header {
    font-weight: bold;
  }
  .section-text {
    margin-top: 5px;
    max-height: 35px;
    overflow: hidden;
    margin-bottom: 10px;
  }
  .section-visual {
    height: 135px;
    width: 100%;
    background-position: center;
    margin-bottom: 10px;
  }
  .plancard-bullet {
    position: absolute;
    content: '';
    height: 3px;
    background: none;
    width: 20px;
    margin: 7px 0 0 -13px;

    &:after {
      position: absolute;
      content: '';
      width: 15px;
      height: 15px;
      background: @cyan;
      border-radius: 15px;
      left: -7px;
      top: -7px;
    }
    &.no-connector {
      background: none;
    }
  }

  .plan-border-line {
    position: absolute;
    height: 100%;
    border-left: dashed 3px @cyan;
    margin: 12px 0 0 -14px !important;
  }
}

.lesson-plan-summary {
  float: left;
  width: @column;
  .plan-plan {
    width: @column;
  }
  .plan-summary-card {
    width: 256px;
    margin: 0;
  }
}

@sidebar-width: 350px;
@min-plan: 300px;

@one-plan: @sidebar-width + @min-plan * 2;
@two-plan: @sidebar-width + @min-plan * 3;
@three-plan: @sidebar-width + @min-plan * 4;
@four-plan: @sidebar-width + @min-plan * 5;

.one-liner {
  @media screen and (max-width: @one-plan) {
    .plan-summary-card:nth-child(n + 2) {
      display: none;
    }
  }
  @media screen and (max-width: @two-plan) {
    .plan-summary-card:nth-child(n + 3) {
      display: none;
    }
  }
  @media screen and (max-width: @three-plan) {
    .plan-summary-card:nth-child(n + 4) {
      display: none;
    }
  }
  @media screen and (max-width: @four-plan) {
    .plan-summary-card:nth-child(n + 5) {
      display: none;
    }
  }
}

@assetsUrl: /search/public;