@import (once) url('rootImports.less');

html,
body {
  margin: 0;
  font-family: @lessonup-body-new;
  color: @brown-dark;
  height: 100%;
}

body {
  // Prevent scroll bouncing
  overscroll-behavior-y: none;
}

#app {
  height: 100%;
}

.app-layout {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.app-layout-content {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto;

  display: flex;
  align-items: stretch;
  flex-direction: column;
}

a {
  color: inherit; //TODO IE11 alternative
}

@media (min-width: @search-sidebar-desktop-min) {
  .app-wrapper-with-fixed-navbar {
    .nav-bar {
      position: fixed !important;
      width: 100%;
    }
    .app-layout {
      padding-top: @navbar-height;
    }
  }
}

@assetsUrl: /search/public;