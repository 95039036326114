@import (once) url('rootImports.less');
@import url('../inputElements/inputMixin.less');

@default-button-margin: 1rem;
@label-margin: 0.125rem;

.input {
  display: block;
  width: 100%;
  flex-basis: content;
  flex-grow: 1;
  margin: 0 auto;

  input {
    outline: 0;
  }
  &--has-icon {
    position: relative;
  }

  &__field {
    font-family: @lessonup-body-new;
    width: 100%;
    display: block;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    position: static;
    height: 2.5rem;
    left: 0px;
    top: 0px;
    margin-top: 0 !important;
    padding-top: 0;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid @dark-grey-faded;
    font-size: 1rem;
    box-shadow: none;
    padding: 0.1rem 0.6rem;
    transition: all 300ms;
    transition-timing-function: ease-in-out;
    border-width: 1px;
    &:enabled {
      color: @dark-grey;

      &:hover {
        .hover-input-styling;
      }

      &:active {
        .active-input-styling;
      }

      &:focus {
        .focus-input-styling;
      }
    }

    &:disabled {
      .disabled-input-styling;
    }

    &--error {
      .input-error-styling;
    }

    .placeholder-input-styling;

    &--has-icon {
      padding-left: @spacer * 9;
    }
  }

  &__icon {
    height: @spacer * 5;
    width: @spacer * 5;
    position: absolute;
    margin: auto;
    top: 50%;
    transform: translateY(-50%);
    left: @spacer * 3;
    color: @grey-light3;
  }
}

@assetsUrl: /search/public;