@import (once) url('rootImports.less');

.logo-red-rocket-circle {
  position: relative;
  width: 100px;
  height: 100px;
  display: inline-block;
  .logo-circle {
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 100%;
  }
  .rocket-svg {
    position: absolute;
    fill: @red;
    width: 80%;
    height: 80%;
    top: 5%;
    right: 5%;
  }
}

@assetsUrl: /search/public;