@import (once) url('_imports.less');

.player-pin {
  position: relative;
  display: inline-block;
  border: solid transparent 4px;
  padding: 2px;
  background: transparent;
  display: inline-block;
  margin: 15px 0 15px 0;
  .video-pin-indicator-wrapper svg {
    position: absolute;
    left: 5px;
    bottom: 5px;
    width: 40px;
    height: 50px;
    z-index: 1;
    .text {
      font-size: 20px;
      fill: @default-text;
    }
    .center {
      fill: @yellow;
    }
    .core {
      pointer-events: all;
      cursor: pointer;
      &:hover {
        .center {
          fill: @blue;
        }
        .text,
        .text-small {
          fill: white;
        }
      }
    }
  }

  &:hover {
    border-color: rgba(0, 0, 0, 0.3);
  }
}

/* for student and teacher lessons, slightly bigger */
#lesson .content-item {
  width: @preview-width * @bigger-preview-multiplier;
  height: @preview-height * @bigger-preview-multiplier;
  #slide,
  #question {
    font-size: @preview-font-bigger;
  }
}

@assetsUrl: /search/public;