.savemodal__header {
  display: flex;
}

.savemodal_headertext {
  flex: 1;
}

.savemodal__list {
  display: flex;
  flex-direction: column;

  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  margin-top: 20px;
  margin-bottom: 20px;
}

.savemodal__lesson {
  margin: 40px 20px;
}

@assetsUrl: /search/public;