@import (once) url('_imports.less');

.capsule-borders {
  border-radius: 15px;
}

.capsule-sizing-default {
  &:extend(.capsule-borders);

  height: 25px;
  margin: 5px 10px;
  padding: 3px 7px 3px 15px;
}

.capsule-color-default {
  background-color: white;
}

.capsule-input-field {
  &:extend(.capsule-borders);
  padding: 10px;
  padding-left: 15px;

  font-size: inherit;
  display: block;
  width: 100%;

  background-color: white;
  border: none;

  &:focus {
    outline: none;
    text-decoration: underline;
  }
}

textarea.capsule-textarea-field {
  padding: 10px;
  padding-left: 15px;
  margin: 1em 0;

  font-size: inherit;
  display: block;
  width: 100%;

  color: @default-text;
  border: none;
  border-radius: 0;
  box-shadow: inset 1px 0px 2px 1px @grey-light;

  min-height: 100px;
  resize: none;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: @grey-dark;
  }
}

.capsule-list-horizontal {
  .capsule-list-horizontal--capsule {
    border-radius: 1em;
    background-color: @white-2;
    display: inline-block;
    padding: 10px;
    margin-right: 0.5em;
  }
}

.link-prominent {
  text-decoration: underline;
  color: darken(@blue, 0);
  font-weight: 500;
}

@assetsUrl: /search/public;