.country-state-form {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  text-align: left;

  & > *:not(:last-child) {
    margin-bottom: 1.5rem;
  }
}

@assetsUrl: /search/public;