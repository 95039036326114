@import (once) url('rootImports.less');

@label-margin: 0.125rem;

.input-label {
  font-size: 0.8125rem;
  font-weight: 600;
  color: @dark-grey;
  height: 1.5rem;
  line-height: 1.5rem;
  margin-bottom: @label-margin;
  padding-bottom: 0;

  &--disabled {
    color: @grey-light2;
  }
}

@assetsUrl: /search/public;