@import (once) url('rootImports.less');

@base-switch-spacing: 1rem;
@base-switch-width: 3rem;
@base-switch-height: 1.5rem;

.switch {
  position: relative;
  height: @base-switch-height;
  width: @base-switch-width;

  &__label {
    vertical-align: middle;
    display: inline-block;
    margin-left: 0;
    cursor: pointer;
  }

  &__input {
    opacity: 0;
    width: @base-switch-width;
    height: 0;
  }

  &__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: @base-switch-spacing;
    background-color: @silver;
    height: @base-switch-height;
  }

  &__slider::before {
    position: absolute;
    content: '';
    height: @base-switch-height - (@base-switch-spacing ./ 2);
    width: @base-switch-height - (@base-switch-spacing ./ 2);
    left: 0.25rem;
    bottom: 0.25rem;
    border-radius: 50%;
    background-color: white;
    transition: 0.3s;
  }

  &__input:checked + &__slider::before {
    transform: translateX(1.5rem);
  }

  &__input:checked + &__slider {
    background-color: @blue-dark;
  }

  &__container {
    display: flex;
    align-items: center;

    & > *:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
}

@assetsUrl: /search/public;