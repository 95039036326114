@import (once) url('../_imports.less');

#poll {
  white-space: normal;
  &.poll-color-9 {
    .image-option:not(.active),
    .text-option:not(.active),
    .image-label-svg-wrapper {
      border: 1px solid @default-text;
    }
    .create-option {
      background-image: url('@{assetsUrl}/img/icons/brown-dark/plus.png');
      border: 1px solid @brown-dark;
    }
    .poll-scale {
      border: 1px solid @brown-dark;
    }
  }

  .image {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .component-area {
    @height: 33%;
    @scaleup: 100 ./ @height*100;
    width: 100%;
    height: @height;
    position: absolute;
    overflow: hidden;
    .scale-up {
      height: @scaleup;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }
  }
  .title {
    position: relative;
  }
  .input-error {
    position: absolute;
    right: 0;
    left: 0;
    text-align: center;
    padding: 5px 15px;
    color: red;
    font-size: 0.25em;
  }

  .fg {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  .question {
    display: block;
    color: inherit;
    position: absolute;
    top: 5%;
    left: 5%;
    width: 90%;
    height: 25%;
    font-size: 80%;
    line-height: 1.2em;
    padding: 1%;
    font-family: @lessonup-title;
  }

  #question-inner {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
  textarea.question {
    font-family: inherit;
    background-color: transparent;
  }
  textarea::-webkit-scrollbar {
    display: none;
  }
  .text {
    resize: none;
  }
  .answer-area {
    top: 35%;
    height: 65%;
    position: absolute;
    width: 100%;
  }

  .edit-poll-react-wrapper {
    height: calc(100% - 1.1em);

    .multiplechoice-wrapper {
      grid-row-gap: 5%;
      grid-column-gap: 1%;
    }
  }
}

@assetsUrl: /search/public;