@import (once) url('rootImports.less');

.notification-bar {
  font-family: @lessonup-body-new;
  font-weight: 500;
  align-items: center;
  display: inline-flex;
  border-radius: 5px;
  box-shadow: 0 1px 4px 0 #f3f3f3;
  flex-direction: row;
  align-content: center;
  flex-wrap: wrap;

  &__close-cross {
    height: 1rem;
    width: 1rem;
    margin-right: 0.5rem;
    color: @dark-grey;
    transition: all 300ms;
    &:hover {
      cursor: pointer;
      filter: brightness(0.8);
    }
  }

  &--height-small {
    padding: 0.25rem 0.5rem;
  }
  &--height-medium {
    padding: 0.5rem 0.6rem;
  }
  &--height-large {
    padding: 1rem 1.25rem;
  }

  &--theme-white {
    border: 1px solid #f3f3f3;
  }
  &--theme-blue {
    border: 1px solid @blue;
    background-color: fadeout(@blue, 90%);
  }

  & button {
    margin-left: @spacer * 4;
    align-self: center;
    flex-shrink: 0;
    margin-bottom: 0;
  }

  .notification-image {
    min-width: 18px;
    min-height: 18px;
    max-width: 24px;
    fill: @brown-dark;

    background-size: contain;
    margin-right: 0.6rem;
  }

  & p {
    display: inline-block;
    color: @brown-dark;
    flex-shrink: 0.7;
  }

  @media (max-width: @mq-md-max), (min-width: @mq-lg-min) {
    align-items: center;
    flex-direction: row;

    & img {
      margin-right: 1rem;
      width: 30px;
      display: inline-block;
    }
  }
}

@assetsUrl: /search/public;