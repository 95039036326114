@import (once) url('rootImports.less');

@fontBasedSize: 2.4em;

.base-pill {
  line-height: @fontBasedSize;
  height: @fontBasedSize;
  font-size: 50%;
  display: inline-flex;
  border-radius: 2em;
  font-family: @lessonup-title;
  padding-right: 0.8em;
  font-weight: bold;
  .icon-img {
    width: @fontBasedSize;
    height: @fontBasedSize;
    background-repeat: no-repeat;
    background-size: 60%;
    background-position: center;
  }
  .label {
    line-height: @fontBasedSize + 0.2;
  }

  &.pro-pill {
    border: 1.5px solid @red;
    background-color: @red-faded;
    color: @red;
    line-height: 1em;
    padding: 0.3em 0.6em;
    font-size: 0.6em;
    height: auto;
    .label {
      line-height: 1em;
    }
  }

  &.beta-pill {
    padding-left: 0.8em;
    background-color: @green;
    color: white;
  }
}

.action-button {
  .pro-pill {
    margin-left: 0.5em;
  }
  &.action-button-primary {
    .pro-pill {
      background-color: @cyan;
    }
  }
}

.test-pill {
  position: absolute;
  background: @pink;
  padding: 3px 6px;
  font-size: 11px;
  line-height: 11px;
  border-radius: 20px;
  white-space: nowrap;
  color: #fff;
  text-transform: uppercase;
  transform: translate(6px, -50%);
  top: 4px;
  right: -4px;
}

@assetsUrl: /search/public;