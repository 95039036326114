@import (once) url('_imports.less');

// IMPORTANT, never change these values without also changing aspectratioLib
@fullscreen-offset-bottom: 60px;

#player {
  position: absolute;
  overflow: auto;
  /*background: @grey-bg;*/
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  #playercontent {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    overflow: auto;
    // background: @grey-bg;
    left: 0;
    top: 0;
    right: 0;
    left: 0;
  }

  #playercontent-border,
  #playercontent-inner,
  #playercontent-layer {
    position: absolute;
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
    top: 50%;
    left: 50%;
    .transform(translate(-50%, -50%));
  }

  #playercontent-layer {
    box-shadow: none;
    pointer-events: none;
  }

  .content {
    padding: 0;
    width: 100%;
    height: 100%;
    #video {
      width: 100%;
      height: 100%;
    }
  }
  #presentationModeBar {
    .page-realtimePlayer & {
      pointer-events: none;
      .buttons {
        pointer-events: all;
      }
    }
  }

  &.drawing-mode #presentationModeBar {
    pointer-events: none;
    .buttons {
      pointer-events: all;
    }
  }
  #playercontent-inner.no-background {
    #slide {
      background: transparent;
    }
  }
}

.background-fade-0 .content-background {
  opacity: 0;
}
.background-fade-20 .content-background {
  opacity: 0.2;
}
.background-fade-40 .content-background {
  opacity: 0.4;
}
.background-fade-60 .content-background {
  opacity: 0.6;
}
.background-fade-80 .content-background {
  opacity: 0.8;
}

#realtime-player {
  #player.student-player.player-type-dragQuestion {
    #playercontent {
      top: 45px;
      bottom: 45px;
    }
  }

  #openQuestion {
    left: 0;
    top: 10px;
  }
}

#player.student-player {
  // this was fixed before, but on some safari mobile versions the fixed elements wont have the correct size after the window.resize event
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  &:not(.full-screen-image) {
    [data-allow-image-zoom='1'] {
      .image,
      .image2 {
        cursor: zoom-in;
      }
    }
  }
}
/* ie doesn't have zoom-in cursors */
html.msie #player.student-player:not(.full-screen-image) {
  [data-allow-image-zoom='1'] {
    .image,
    .image2 {
      cursor: pointer;
    }
  }
}

#player.full-screen-image {
  #playercontent {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    #playercontent-inner {
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100% !important;
      height: 100% !important;
      .transform(none);

      /* hide stuff */
      .text1,
      .text2,
      .question,
      #answers,
      #game-secondary-info,
      .bottom-bar,
      .student-open-question,
      .student-button,
      .component {
        display: none;
      }

      .student-image-container {
        display: none;
      }

      .image,
      .image2 {
        opacity: 1;
        display: none;
        position: absolute;
        left: 0 !important;
        top: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
        width: auto !important;
        height: auto !important;
        background-position: 50% 50%;
      }
      .view-mode {
        background: #000 !important;
      }
    }
  }
  &.full-screen-image-1 {
    .image {
      display: block !important;
    }
  }
  &.full-screen-image-2 {
    .image2 {
      display: block !important;
    }
  }
  &.zoom-1 #playercontent #playercontent-inner {
    .image,
    .image2 {
      background-size: contain !important;
      cursor: zoom-in;
    }
  }
  &.zoom-2 #playercontent #playercontent-inner {
    .image,
    .image2 {
      background-size: cover !important;
      cursor: move;
    }
  }
  &.zoom-3 #playercontent #playercontent-inner {
    .image,
    .image2 {
      background-size: 300% auto !important;
    }
  }
  &.zoom-4 #playercontent #playercontent-inner {
    .image,
    .image2 {
      background-size: 400% auto !important;
    }
  }
  &.zoom-5 #playercontent #playercontent-inner {
    .image,
    .image2 {
      background-size: 500% auto !important;
    }
  }
}

.fullscreen-non-comp-image #player.full-screen-image .no-transform-on-fullscreen-image {
  transform: none !important;
}

.fullscreen-non-comp-image #player.full-screen-image .no-margin-on-fullscreen-image {
  margin: 0 !important;
}

/* ie doesn't have zoom-in cursors */
html.msie #player.full-screen-image.zoom-1 #playercontent #playercontent-inner {
  .image,
  .image2 {
    cursor: pointer;
  }
}

html.msie.presentation-mode-active {
  // Ensures that fullscreen mode renders well in IE.
  width: 100%;
  height: 100%;
}

.content-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  // HACK: YouTube embed is not playable without this rule
  pointer-events: none;
}

@assetsUrl: /search/public;