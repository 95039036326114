@import (once) url('./_variables.less');

.explorer-icon {
  // height: @size-thumbnail * @16-9-aspect-ratio-height;
  width: @size-thumbnail;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: inline-block;
}
