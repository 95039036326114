@import (once) url('rootImports.less');

.channel-search {
  @media (min-width: 960px) {
    .gray-tabs-bar {
      position: relative;
    }
    .search-sidebar {
      position: relative;
    }
    .searchpage__searchresults {
      margin-top: 30px;
    }
  }
}
.sticky {
  @media (min-width: 960px) {
    .search-sidebar {
      position: fixed;
      width: inherit;
      top: 60px;
    }
  }
}

@assetsUrl: /search/public;