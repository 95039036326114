@import (once) url('../_imports.less');

.slide-color-1 {
  .content-background {
    background-color: #fff;
  }
  color: #000;
}
.slide-color-2 {
  .content-background {
    background-color: @yellow;
  }
  color: #000;
}
.slide-color-3 {
  .content-background {
    background-color: @pink;
  }
  color: #fff;
  &.edit-mode .text {
    border-color: rgba(255, 255, 255, 0.3);
  }
}
.slide-color-4 {
  .content-background {
    background-color: @cyan;
  }
  color: #fff;
  &.edit-mode .text {
    border-color: rgba(255, 255, 255, 0.3);
  }
}
.slide-color-5 {
  .content-background {
    background-color: #000;
  }
  color: #fff;
  &.edit-mode .text {
    border-color: rgba(255, 255, 255, 0.3);
  }
}
.slide-color-6 {
  .content-background {
    background-color: @blue;
  }
  color: darken(@blue, 50);
  &.edit-mode .text {
    border-color: rgba(255, 255, 255, 0.5);
  }
}
.slide-color-7 {
  .content-background {
    background-color: @orange;
  }
  color: darken(@orange, 40);
  &.edit-mode .text {
    border-color: rgba(255, 255, 255, 0.5);
  }
}
.slide-color-8 {
  .content-background {
    background-color: @correct-light;
  }
  color: darken(@correct, 25);
  &.edit-mode .text {
    border-color: rgba(255, 255, 255, 0.5);
  }
}

/*

	NOTE THAT TOP/LEFT/RIGHT/BOTTOM VALUES ARE OVERWRITTEN BY RUNTIME STYLES
	DEFINED IN ContentTypeLib.js, AND BURNED THROUGH THE getAttributes

*/

#slide {
  &.slide-layout-1 {
    #text1 {
      left: 2%;
      right: 2%;
      bottom: 54%;
      top: 28%;
    }
    #text2 {
      left: 2%;
      right: 2%;
      top: 48%;
      bottom: 3%;
    }
  }

  &.slide-layout-2 {
    #text1 {
      left: 2%;
      right: 2%;
      bottom: 78%;
      top: 4%;
    }
    #text2 {
      left: 2%;
      right: 2%;
      bottom: 3%;
      top: 24%;
    }
  }

  // TODO before merge: hier stond eerst een z-index: 3, dit is nu weggehaald.

  &.slide-layout-3 {
    #text1 {
      display: none;
    }
    #text2 {
      display: none;
    }
    .image {
      background-size: contain;
    } // .image { background-size: contain; z-index: 3; }
  }

  &.slide-layout-4 {
    #text1 {
      left: 2%;
      right: 2%;
      top: 78%;
      bottom: 4%;
    }
    #text2 {
      display: none;
    }
    .image {
      left: 0%;
      right: 0%;
      bottom: 26%;
      top: 0%;
      background-size: contain;
    }
  }

  &.slide-layout-5 {
    #text1 {
      left: 51%;
      right: 2%;
      bottom: 78%;
      top: 4%;
    }
    #text2 {
      left: 51%;
      right: 2%;
      bottom: 3%;
      top: 24%;
    }
    .image {
      left: 0%;
      right: 51%;
      bottom: 0%;
      top: 0%;
      background-size: contain;
    }
  }

  &.slide-layout-6 {
    #text1 {
      left: 2%;
      right: 51%;
      bottom: 78%;
      top: 4%;
    }
    #text2 {
      left: 2%;
      right: 51%;
      bottom: 3%;
      top: 24%;
    }
    .image {
      left: 51%;
      right: 0%;
      bottom: 0%;
      top: 0%;
      background-size: contain;
    }
  }

  &.slide-layout-7 {
    #text1 {
      left: 2%;
      right: 51%;
      bottom: 3%;
      top: 78%;
    }
    #text2 {
      left: 51%;
      right: 2%;
      bottom: 3%;
      top: 78%;
    }
    .image {
      left: 2%;
      right: 51%;
      bottom: 24%;
      top: 4%;
      background-size: contain;
    }
    .image2 {
      left: 51%;
      right: 2%;
      bottom: 24%;
      top: 4%;
      background-size: contain;
      display: block;
    }
  }

  &.slide-layout-8 {
    #text1 {
      left: 2%;
      right: 51%;
      bottom: 3%;
      top: 3%;
    }
    #text2 {
      left: 51%;
      right: 2%;
      bottom: 3%;
      top: 3%;
    }
  }

  &.slide-layout-9 {
    #text1 {
      display: none;
    }
    #text2 {
      display: none;
    }
    .image {
      display: none;
    }
    .image2 {
      display: none;
    }
  }
}

@assetsUrl: /search/public;