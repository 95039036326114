@import (once) url('rootImports.less');

.rocket-logo {
  display: inline-flex;
  text-decoration: none;
  align-items: center;

  img {
    height: 30px;
    width: 30px;
  }

  span {
    font-family: @lessonup-logo;
    margin-left: 15px;
    font-size: 25px;
  }

  @media (min-width: @mq-md-min) {
    &.left-nav-logo {
      padding-left: 25px;
    }
  }
}

@assetsUrl: /search/public;