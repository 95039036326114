@import (once) url('rootImports.less');

@button-bar-height: @spacer*4 + 1.8rem;
@modal-spacer: 8 * @spacer;
@modal-surrounding-content-height: ~'calc(@{modal-title-height} + 2* @{modal-content-padding} + @{button-bar-height} + @{modal-spacer})';

.location-picker-modal {
  color: @brown-dark;
  background-color: white;
  &__explanation {
    margin-top: 0;
  }
  &__explorer {
    background: white;
    height: @location-picker-explorer-height;
    max-height: ~'calc(100vh - @{modal-surrounding-content-height})';
    overflow-y: auto;
  }
  &__actions {
    padding-left: @spacer * 2;
    padding-right: @spacer * 2;
    align-items: center;
    justify-content: space-between;
    &__pick-folder-button {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

@assetsUrl: /search/public;