@import (once) url('rootImports.less');
@import (once) url('../../components/lessonPlan/css/LessonPlanRoot.less');

.plan-styles {
  &.no-sections {
    .plan-bullet,
    .plan-checkmark,
    .plan-border-line {
      display: none !important;
    }
  }

  .plan-button {
    background-color: @pink;
    color: @white;
    text-decoration: none;
    font-size: 1rem;
    overflow: hidden;
    white-space: nowrap;
    line-height: 1.2em;
    padding: 10px 10px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    width: 100%;
    gap: 0.5rem;

    span {
      min-width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.disabled {
      cursor: default;
      opacity: 0.2;
      pointer-events: none;
    }

    .icon {
      display: inline-block;
      margin-right: 10px;
      width: 24px;
      height: 24px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      vertical-align: top;
    }
  }

  .plan-label {
    .font-odin;
    padding: 8px 20px;
    background: #eee;
    color: @default-text;
    font-size: 14px;
    line-height: 1em;
    font-weight: normal;
    height: 26px;
    border-radius: 20px;
    text-transform: uppercase;
    display: inline-block;
    margin: 0px 0 15px 0px;

    @media (min-width: 768px) {
      position: absolute;
      .translatex(-100%);
      margin: 4px 0 0 -15px;
    }
  }

  h1.title {
    .font-odin;
    text-transform: uppercase;
    font-weight: bold;
    color: @default-text;
    font-size: 20px;
    line-height: 1.2em;
    padding: 0;
    margin: 0 0 20px 0;

    @media (min-width: 768px) {
      font-size: 35px;
    }
  }

  .description {
    position: relative;
    color: @default-text;
    /*margin-bottom: 20px;*/
  }

  .channel-origin {
    .font-montserrat;
    color: @grey-mid-2;

    a {
      color: @blue-dark;
      text-decoration: underline;
    }
  }

  .plan-intro {
    max-width: @plan-section;
    margin: auto;
    position: relative;
    padding: 0 0 20px 0;
    margin-top: 50px;

    .plan-actions {
      background: #eee;
      padding: 10px 20px;
      margin-bottom: 20px;

      p {
        font-size: 11px;
      }

      .content-item-button {
        margin-right: 5px;
      }

      @media (max-width: 768px) {
        margin-right: 10px;
      }
    }
  }

  .plan-section {
    position: relative;
    clear: both;
    padding-left: @plan-section-padding;

    .plan-children {
      max-width: @plan-section;
      margin: auto;
      min-height: 200px;
      position: relative;
      padding: 20px 0;
    }

    &.done {
      .plan-bullet {
        background: @green;

        &:after {
          background: @green;
        }
      }

      /*.plan-checkmark { background-image: url(/img/check-green.png); }*/
      .plan-border-line {
        border-left-color: @green;
      }
    }

    .plan-bullet {
      position: absolute;
      content: '';
      height: 3px;
      background: @cyan;
      z-index: 3;
      width: 21px;
      margin: 12px 0 0 -32px;

      @media (min-width: 768px) {
        width: 42px;
        margin: 12px 0 0 -53px;
      }

      &:after {
        position: absolute;
        content: '';
        width: 15px;
        height: 15px;
        background: @cyan;
        border-radius: 15px;
        left: -7px;
        top: -7px;
      }
    }

    .plan-checkmark {
      width: 30px;
      height: 30px;
      position: absolute;
      margin: -4px 0 0 -108px;
      background: url(/img/check-grey.png) no-repeat center center;
      background-size: 100% auto;
      z-index: 2;
      cursor: pointer;

      /* the checkmark:after is the white box around the green bullet
	  	   to make a specific gap between the border line and the bullet */
      &:after {
        content: '';
        position: absolute;
        background: #fff;
        width: 35px;
        height: 35px;
        margin: -1px 0 0 32px;
      }
    }

    .plan-border-line {
      position: absolute;
      height: 100%;
      z-index: 3;
      border-left: dashed 3px @cyan;
      margin: 12px 0 0 -33px;

      @media (min-width: 768px) {
        margin: 12px 0 0 -54px;
      }
    }
  }

  .plan-section:nth-child(even) {
    background: @white;
  }

  .plan-section:nth-child(odd) {
    background: @grey;
  }

  .intro:nth-child(odd) {
    background: @white;
  }

  .context-bar {
    max-width: @column3;
    z-index: inherit;
  }

  .plan-caption {
    margin-top: 7px;
    font-size: 92%;
    font-style: italic;
    .font-montserrat;
  }

  .content-editable {
    background-color: inherit;
    padding: 0 !important;

    &:active,
    &:focus {
      outline: none;
    }
  }

  [data-role='column-size'].active {
    position: relative;

    &:after {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      right: 0;
      bottom: -2px;
      border: solid @pink 2px;
      border-radius: 4px;
    }
  }
}

#lesson-plan.view {
  .user-select(text);

  .child-buttons {
    display: none;
  }

  .plan-children {
    .plan-child.selected:after {
      border-radius: 5px !important;
    }
  }

  @media (min-width: 768px) {
    :not(.plan-summary-card) .plan-section {
      padding-left: 120px;
    }
  }
}

.explorer-preview-panel {
  .plan-tree {
    h3 {
      margin: 20px 0 0 0;
    }

    .leaf {
      margin-bottom: 7px;
      text-indent: -0.5em;
      padding-left: 0.5em;
    }
  }
}

@assetsUrl: /search/public;