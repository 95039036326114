@import (once) url('../_imports.less');

.component-trafficlight {
  .light {
    height: 100%;
    width: 100%;
    .lightbox {
      background-color: #5a4d4c;
      height: 100%;
      width: 100%;
      position: absolute;
      border-top-left-radius: 50% 18%;
      border-top-right-radius: 50% 18%;
      border-bottom-left-radius: 50% 18%;
      border-bottom-right-radius: 50% 18%;
      border: 1% solid gray;
    }
    .lightbox-inner {
      background-color: #282524;
      margin-left: 4%;
      margin-top: 3%;
      height: 97%;
      width: 92%;
    }
    .lightbox-outer {
      box-shadow: 0 0 0.4em 0.05em rgba(0, 0, 0, 0.5);
    }
    .lamp {
      position: absolute;
      height: 26%;
      width: 68%;
      border-radius: 100%;
      left: 16%;
    }
    @green-tf: lime;
    @yellow-tf: #ffb405;
    @red-tf: red;
    @box-1: 0.8em;
    @box-2: 0.3em;
    .lamp-red {
      top: 8%;
      background-color: darken(@red-tf, 35%);
    }
    &.active-red .lamp-red,
    &.active-yellow .lamp-yellow,
    &.active-green .lamp-green {
      &:after {
        border-radius: 100%;
        position: absolute;
        height: 50%;
        width: 50%;
        left: 20%;
        top: 20%;
        content: '';
        background: radial-gradient(ellipse at center, #ffffff 0%, rgba(71, 73, 25, 0) 60%);
        opacity: 0.5;
      }
    }
    // 0 0 0.8em rgba(255, 177, 177, 0.65) inset, 0 0 0.3em red;

    &.active-red .lamp-red {
      background-color: @red-tf;
      box-shadow: 0 0 @box-1 rgba(255, 173, 173, 0.65) inset, 0 0 @box-2 @red-tf;
    }
    .lamp-yellow {
      top: 37%;
      background-color: darken(@yellow-tf, 35%);
    }
    &.active-yellow .lamp-yellow {
      background-color: @yellow-tf;
      box-shadow: 0 0 @box-1 rgba(255, 181, 115, 0.65) inset, 0 0 @box-2 @yellow-tf;
    }
    .lamp-green {
      bottom: 8%;
      background-color: darken(@green-tf, 35%);
    }
    &.active-green .lamp-green {
      background-color: @green-tf;
      box-shadow: 0 0 @box-1 rgba(136, 255, 136, 0.65) inset, 0 0 @box-2 @green-tf;
    }
    &.no-yellow {
      .lamp-yellow {
        display: none;
      }
      .lamp {
        height: 38%;
      }
      .lightbox {
        border-top-left-radius: 50% 25%;
        border-top-right-radius: 50% 25%;
        border-bottom-left-radius: 50% 25%;
        border-bottom-right-radius: 50% 25%;
      }
    }
  }
}
.edit-mode {
  .component-trafficlight {
    .light {
      pointer-events: none;
    }
    .lamp {
      pointer-events: all;
    }
  }
}

@assetsUrl: /search/public;