@import (once) url('rootImports.less');

@lesson-card-size: 255px;
@lesson-card-margin: 5px;

.lesson-page-section {
  padding-left: 20px;
  padding-right: 20px;
}

.lesson-page-view {
  margin-top: 20px;
  margin-bottom: 90px;
}

.lesson-page__content-grid {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  &:after {
    content: '';
    display: flex;
    width: @lesson-card-size;
    margin: @lesson-card-margin;
  }
}

.flex-align-horizontal {
  display: flex;
  justify-content: center;
}

.lesson-page__back {
  margin-top: 30px;
  margin-bottom: 20px;
}

.lesson-page__back-arrow {
  display: inline-block;
  font-size: 1.5em;
  margin-right: 5px;
}

.lesson-page__more-like-this {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: @white-2;
}
.lesson-overview-introduction-text {
  padding-right: 10px;
}

@media print {
  .crisp-client {
    display: none;
  }
  .lesson-page-rendered {
    .app-layout {
      padding-top: 0;
    }
    .lesson-overview-meta {
      padding-left: 0;
    }
    .chan-header,
    .search-footer,
    .navbar,
    .lesson-preview__player-container,
    .lesson-overview-introduction-downloads,
    .lesson-overview-meta-action-section,
    .breadcrumb-list,
    .lesson-page__more-like-this,
    .lesson-page__back,
    .overview-meta-tags-show-more,
    .action-button {
      display: none;
    }
    .lesson-overview-pin-list-item,
    .pin-wrapper {
      break-inside: avoid;
      page-break-inside: avoid;
    }
    .lesson-overview__title {
      font-size: 30px;
    }

    .lesson-page-section {
      width: 670px;
      height: inherit;
      overflow: visible;
      -webkit-print-color-adjust: exact;
      color-adjust: exact;
    }
  }
}

@assetsUrl: /search/public;