@import (once) url('../_imports.less');

/* some colors and layouts may overlap with slide, but some don't - so don't merge! */

.poll-color-1 {
  .content-background {
    background-color: @blue-dark;
  }
  color: #fff;
}
.poll-color-2 {
  .content-background {
    background-color: @yellow;
  }
  color: #000;
}
.poll-color-3 {
  .content-background {
    background-color: @pink;
  }
  color: #fff;
  &.edit-mode .text {
    border-color: rgba(255, 255, 255, 0.3);
  }
}
.poll-color-4 {
  .content-background {
    background-color: @cyan;
  }
  color: #fff;
  &.edit-mode .text {
    border-color: rgba(255, 255, 255, 0.3);
  }
}
.poll-color-5 {
  .content-background {
    background-color: #000;
  }
  color: #fff;
  &.edit-mode .text {
    border-color: rgba(255, 255, 255, 0.3);
  }
}
.poll-color-6 {
  .content-background {
    background-color: @blue;
  }
  color: darken(@blue, 45);
  &.edit-mode .text {
    border-color: rgba(255, 255, 255, 0.5);
  }
}
.poll-color-7 {
  .content-background {
    background-color: @orange;
  }
  color: darken(@orange, 40);
  &.edit-mode .text {
    border-color: rgba(255, 255, 255, 0.5);
  }
}
.poll-color-8 {
  .content-background {
    background-color: @correct-light;
  }
  color: darken(@correct, 25);
  &.edit-mode .text {
    border-color: rgba(255, 255, 255, 0.5);
  }
}
.poll-color-9 {
  .content-background {
    background-color: white;
  }
  color: #111;
  &.edit-mode .text {
    border-color: rgba(0, 0, 0, 0.5);
  }
}

@assetsUrl: /search/public;