@import (once) url('rootImports.less');

.search-result-tabs {
  padding-left: @search-results-outer-padding-mobile;
  padding-right: @search-results-outer-padding-mobile;

  @media (min-width: @search-sidebar-desktop-min) {
    padding-left: @search-results-outer-padding-desktop;
    padding-right: @search-results-outer-padding-desktop;

    .search-tab-wrapper {
      height: calc(100% + 3px);
    }
  }
}

@assetsUrl: /search/public;