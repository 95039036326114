@import (once) url('rootImports.less');

.error-page-container {
  padding: 20px;
  display: flex;
  justify-content: center;

  .error-page {
    flex-grow: 0;
    padding: 1em;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;

    svg {
      width: auto;
      max-width: 100vw;
    }
  }

  .error-title {
    font-family: @lessonup-heading, sans-serif;
    color: @brown-dark;
  }
  .error-text {
    font-family: @lessonup-body-new, sans-serif;
  }
}

@assetsUrl: /search/public;