@import (once) url('rootImports.less');

.registration-demo-lesson-page {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  max-width: 1200px;
  gap: @spacer * 12;
  flex-wrap: wrap;

  @media (min-width: @mq-md-min) {
    flex-wrap: nowrap;

    &__col {
      height: 100%;
    }
  }

  &__col {
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
    flex-grow: 1;
    flex-shrink: 1;
    align-content: space-between;
    justify-content: center;
    gap: @spacer * 8;

    h1,
    p {
      margin: 0;
    }
  }

  &__title {
    font-size: 2rem;
    line-height: 1.5;
  }

  &__extra-text {
    line-height: 1.5;
    font-size: 0.8rem;
  }

  &__img-container {
    height: 0;
    padding-top: calc(9 / 16 * 100%);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    position: relative;

    &--placeholder {
      border: 1px solid @outline;
      background-color: @surface;
    }
  }

  &__description {
    line-height: 1.5;
  }

  &__placeholder-svg {
    color: @silver;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    transform: translateY(50%);
  }

  &__button-container {
    display: flex;
  }
}

@assetsUrl: /search/public;