@import (once) url('../_imports.less');

.component-audio {
  .audio-title {
    position: absolute;
    left: 50%;
    height: 95%;
    top: 2.5%;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    padding: 4% 40% 4% 60%;
    white-space: nowrap;
    border-radius: 0 3000px 3000px 0;
    line-height: 175%;
    pointer-events: none;
  }
  .audio-player {
    position: absolute;
    left: 104%;
    top: 50%;
    .translatey(-50%);
    opacity: 0;
    display: none;
    .transition(all 0.3s ease-out);
    pointer-events: none;
    border: solid #000 2px;
    line-height: 0;
  }
  .audio-duration {
    color: white;
    position: absolute;
    bottom: -75%;
    font-size: 50%;
    left: 0;
    text-align: center;
    width: 100%;
    height: 1.2em;
    line-height: 1.2em;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    cursor: pointer;
    &.paused {
      .icon {
        cursor: pointer;
        position: absolute;
        width: 1.2em;
        height: 1.2em;
        background-size: 60% auto;
        background-repeat: no-repeat;
        background-position: center center;
        right: -30%;
        top: 0;
        background-color: rgba(0, 0, 0, 0.4);
        border-radius: 100%;
      }
    }
  }
  .hotspot-scale-mousecatcher {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
  }
  .border {
    pointer-events: none;
    position: absolute;
    width: 95%;
    height: 95%;
    left: 50%;
    top: 50%;
    .translate(-50%, -50%);
    border-radius: 3000px;
    background: #fff;
    @svg-size: 94%;
    @svg-offset: 3%;
    text-align: left;
    svg {
      margin: @svg-offset 0 0 @svg-offset;
      height: @svg-size;
      width: @svg-size;
      .timer-back {
        fill: @silver;
      }
      .timer-arc {
        fill: @blue;
      }
    }
  }
  .core {
    pointer-events: none;
    position: absolute;
    width: 75%;
    height: 75%;
    left: 50%;
    top: 50%;
    .translate(-50%, -50%);
    border-radius: 100%;
    background: #fff;
    &.icon-pause {
      background-image: none;
      .core-icon {
        background-image: url(img/icons/white/pause.png);
      }
      &.icon-dark {
        .core-icon {
          background-image: url(img/icons/black/pause.png);
        }
      }
    }
  }
  .core-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    .translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background-size: 70%;
    border-radius: 100%;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .core-image {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: 100% auto;
    background-repeat: no-repeat;
    border-radius: 100%;
  }
  /* when positioned on the right side of the screen */
  &.pos-right {
    .audio-title {
      left: auto;
      right: 50%;
      padding: 4% 60% 4% 40%;
      border-radius: 3000px 0 0 3000px;
    }
    .audio-player {
      left: auto;
      right: 104%;
    }
  }
  /* when positioned on the bottom side of the screen */
  &.pos-bottom {
    .audio-text {
      top: auto;
      bottom: 95%;
    }
  }
  .seek-ball {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    width: 0.35em;
    height: 0.35em;
    border-radius: 100%;
    background-color: @blue;
    display: none;
    .translate(-50%, -50%);
    &:hover {
      background-color: @green;
    }
  }
}
@enhance: -23%;
.view-mode {
  .view-component-audio {
    cursor: pointer;
    .core,
    .border {
      .transform-origin(center, center);
      .transition(all 0.5s ease-out);
    }
  }
  .view-component-audio:hover {
    .seek-ball {
      opacity: 1;
    }
    .core,
    .border {
      .transform(translate(-50%, -50%) scale(1.2));
    }
    .hotspot-scale-mousecatcher {
      left: @enhance;
      right: @enhance;
      top: @enhance;
      bottom: @enhance;
    }
  }
}

@assetsUrl: /search/public;