@import (once) url('rootImports.less');
@import (once) url('../../../../shared-core/ui/components/pin/PinFlow.less');

.lesson-overview {
  color: @brown-dark;

  .lesson-overview__title {
    margin-top: 20px;
    margin-bottom: 20px;

    @media only screen and (max-width: 600px) {
      font-size: 6vw;
    }
  }

  .print-button {
    display: flex;

    svg {
      height: 1rem;
      width: 1rem;
      margin-right: 0.4rem;
    }
  }
}

.lesson-overview-meta {
  display: flex;
  height: 100%;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;

  @media only screen and (max-width: @mq-xs-max) {
    padding-left: 0;
    padding-right: 0;
  }

  .lesson-overview-meta__description {
    flex-grow: 1;
    line-height: 1.4em;
  }

  .lesson-overview-meta__title {
    font-size: 2em;
    text-transform: uppercase;
  }

  .action-button {
    margin-bottom: 5px;
  }
}

.lesson-overview-meta-tags-show-more {
  color: @cyan;
  font-size: 14px;
}

.lesson-overview-meta-action-section {
  margin-top: 1em;
}

.lesson-overview-meta__buttons {
  @media only screen and (max-width: 350px) {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    text-align: center;
  }
  @media only screen and (min-width: 350px) {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    align-items: center;
  }
}

.lesson-overview-meta__icon img {
  max-width: 1em;
  max-height: 1em;
  margin-right: 0.5em;
  opacity: 0.5;
  transform: translateY(1.5px);
}

.lesson-overview-meta-section {
  margin-top: 20px;

  &:first-child {
    margin-top: 0;
  }
}
.lesson-overview-meta__sub-buttons {
  display: flex;
  align-items: center;
  margin-top: 5px;

  & > *:not(:first-child) {
    margin-left: 0.4rem;
  }
}

.lesson-overview-introduction {
  margin-top: 40px;

  @media (min-width: @mq-md-min) {
    display: flex;
    margin-top: 60px;
  }
}

.lesson-overview-introduction-text {
  white-space: pre-line;

  @media (min-width: @mq-md-min) {
    flex: 2 auto;
  }
}

.lesson-overview-introduction-downloads {
  background: @white-2;
  padding: 30px;
  margin-top: 40px;
  border-radius: 10px;

  @media (min-width: @mq-md-min) {
    flex: 1 auto;
    max-width: 400px;
    margin-top: 0;
  }
}

.lesson-overview-introduction-downloads-button {
  margin-bottom: 10px;
}

.pin-flow-40 {
  .pin-flow(40);
}

.lesson-overview-pin-title {
  margin: 0;
  font-family: @lessonup-body-new;
  font-weight: 700;
}

.lesson-overview-pin-notes-empty {
  color: #c3c3c3;
}

.lesson-overview-pins {
  .context-menu {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 22;
    transition: opacity 0.2s ease-in-out;
    opacity: 0;

    @media (hover: none) {
      opacity: 1;
    }

    .pin-view {
      .pin-size(275, 1);
      position: relative;
      margin: 0 10px;
      margin: 7px 0;
    }
    .context-menu-inner {
      right: 0;
    }
    &.open {
      opacity: 1;
    }
  }
}

.lesson-overview-pin-grid {
  margin-top: 40px;

  @media (min-width: @mq-md-min) {
    margin-top: 60px;
  }

  .pin-view.dummy {
    height: 0;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
    border: 0;
  }

  .lesson-overview-pin-title {
    position: absolute;
    bottom: -35px;
  }

  .pin-wrapper {
    display: inline-block;

    position: relative;
    margin-bottom: 70px !important;

    &:hover .context-menu {
      opacity: 1;
    }
  }

  .ui-heading {
    padding-left: 10px;
  }
}

.lesson-overview-pin-list {
  margin-top: 40px;

  @media (min-width: @mq-md-min) {
    margin-top: 60px;
  }

  .lesson-overview-pin-container {
    position: relative;
    .pin-size(150, 1);
    .pin-video-view {
      .pin-size(125, 1);
    }

    @media (min-width: @mq-xs-min) {
      .pin-size(200, 1);
      .pin-video-view {
        .pin-size(175, 1);
      }
    }

    @media (min-width: @mq-md-min) {
      .pin-size(275, 1);
      .pin-video-view {
        .pin-size(250, 1);
      }
    }
  }

  .lesson-overview-pin-list-item {
    display: flex;
    margin-bottom: 20px;
    &:hover {
      .context-menu {
        opacity: 1;
      }
    }
  }

  .lesson-overview-pin-list-item-notes {
    margin-left: 15px;
  }
}

@assetsUrl: /search/public;