@import (once) url('rootImports.less');
.channel-container {
  height: 100%;
  .channel-curricula {
    display: flex;
    min-height: calc(40vh - 50px);
    align-items: stretch;
    flex-direction: row;
    list-style: none;
    @media (max-width: @mq-sm-min) {
      flex-direction: column;
    }
    @media only screen and (max-width: 600px) {
      h1 {
        font-size: 6vw;
      }
      h2 {
        font-size: 5vw;
      }
      h3 {
        font-size: 4.5vw;
      }
    }
    .curricula-sidebar {
      width: 300px;
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: auto;
      background-color: #f3f3f3;
      @media (max-width: @mq-sm-min) {
        width: 100%;
      }
      h2 {
        padding: 0px 10px;
      }
      a {
        text-decoration: none;
        font-weight: bold;
        font-size: 16px;
        :hover {
          color: @white;
          background-color: #00b6bca6;
        }
      }
      .channel-tab {
        position: relative;
        display: flex;
        height: 45px;
        align-items: center;
        padding: 10px 15px 10px;
      }
      .pending {
        color: #00b6bca6;
        background-color: @cyan;
      }
      .pending::after {
        position: absolute;
        right: 5px;
        font-size: 150%;
        content: '...';
        color: @white;
      }
      .active {
        color: @white;
        background-color: @cyan;
      }
      .active::after {
        position: absolute;
        right: 5px;
        font-size: 150%;
        content: '>';
        color: @white;
      }
    }
    .curricula-content {
      width: 100%;
      min-height: 75vh;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 20px 40px;
      @media (max-width: @mq-sm-min) {
        padding: 20px 25px;
      }
      > ul > .curricula-section {
        padding: 0;
      }
      > ul {
        width: 100%;
        padding: 0;
        li {
          border-bottom: 1px solid #ccc;
          list-style: none;
          cursor: pointer;
          .curricula-section {
            border-bottom: 1px solid #ccc;
          }
          .curricula-section:last-child {
            border-bottom: none;
          }
          li {
            padding: 0px 5px 0px 10px;
            border-bottom: none;
            .curricula-child-nr {
              font-weight: bold;
              margin-right: 15px;
            }
            .collapse-name .curricula-name {
              font-weight: normal;
            }
            .curricula-child-info {
              padding: 5px 0px 5px 0px;
            }
            ul {
              padding-inline-start: 5px;
            }
          }
        }
        .curricula-child-info:hover {
          background-color: #eee;
        }
        .collapse-name {
          padding: 10px 10px;
          h2 {
            margin: 10px 0;
          }
        }
        .collapse-name:hover {
          background-color: #eee;
        }
        .curricula-section {
          @media (max-width: @mq-sm-min) {
            padding-inline-start: 0px;
          }
        }
      }
      .collapse-groupyear .collapse-content {
        h2 {
          margin: 0;
          font-size: 20px;
        }
        ul li {
          padding: 0px;
          li {
            padding: 5px 5px;
          }
        }
      }
      .curricula-child {
        cursor: default;
        .curricula-child-info {
          display: flex;
        }
        .curricula-child-description {
          display: block;
        }
      }
      .pointer,
      .curricula-description-icon {
        cursor: pointer;
      }
      .collapsed {
        background-color: #eee;
      }
      .curricula-description-icon {
        display: inline-block;
        width: 20px;
        min-width: 20px;
        height: 20px;
        margin-left: 10px;
      }
      .curricula-description-icon:hover {
        background-color: #00b6bca6;
        -moz-border-radius: 70px;
        -webkit-border-radius: 70px;
        border-radius: 70px;
      }
      .curricula-description-content {
        padding: 5px 35px;
      }
    }
    .curricula-filters button {
      padding: 15px 10px;
      margin: 10px 15px 20px 0;
      font-weight: bold;
      font-size: 16px;
    }
    .curricula-section {
      padding: 10px 10px;
    }
    .count {
      color: @blue;
    }
    .button-inactive {
      background-color: #afafaf;
    }
    .button-inactive:hover {
      background-color: #e6786d;
    }
  }
}

@assetsUrl: /search/public;