.video-settings {
  .change-video-buttons {
    display: flex;
    flex-direction: column;
    max-width: 150px;

    &.stack > * {
      margin-bottom: 0.4rem;
    }
  }
}

@assetsUrl: /search/public;