@import (once) url('../_imports.less');

#wordweb {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  top: 0;
  overflow: hidden;
  .image {
    width: 100%;
    height: 100%;
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .tip {
    padding: 10px 50px 0 50px;
    font-family: @lessonup-writing;
    font-size: 40%;
    text-align: center;
    position: absolute;
    width: 100%;
    z-index: 10;
  }

  &.result-success {
    background: lighten(@correct, 25);
    color: #fff;
  }
  &.result-fail {
    background: @red;
    color: #fff;
  }

  .fg {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  &.edit-mode {
    textarea {
      color: inherit;
      resize: none;
    }
    [data-role='word'] {
      display: block;
      position: absolute;
      top: 40%;
      left: 0%;
      width: 100%;
      height: 60%;
      font-size: 60%;
      text-align: center;
      font-family: @lessonup-title;
      background: transparent;
      border: none;
      outline: none;
      z-index: 2;
      padding: 1%;
      overflow: hidden;
      line-height: 1.3em;
    }
    .placeholder {
      position: absolute;
      top: 0;
      left: 0;
      width: 90%;
      font-family: @lessonup-title;
      color: transparent;
      font-size: 50px;
      line-height: 1em;
      padding: 20px;
      pointer-events: none;
      .transform(translate(-9999px, -99999px));
    }
  }
  &.view-mode {
    #word {
      position: absolute;
      padding: 1%;
      left: 0;
      top: 50%;
      width: 100%;
      .transform(translateY(-50%));
      text-align: center;
      font-family: @lessonup-title;
      line-height: 1.3em;
      white-space: pre-line;
    }
  }

  &.preview {
    font-size: @preview-font;
  }

  #bg-image {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: url('@{assetsUrl}/img/icons/white/woordweb-2-wide.png') no-repeat center center;
    background-size: auto 90%;
    opacity: 0.2;
    &.no-circles {
      background-image: none;
    }
  }
  .balloons {
    width: 100%;
    height: 100%;
    position: absolute;
    .balloon {
      position: absolute;
      width: 12%;
      height: 10%;
      background-color: #fff;
      opacity: 0.3;
      .tuutje {
        position: absolute;
        display: block;
        height: 35%;
        width: 24%;
        top: 99%;
        fill: #fff;
      }
      &.arrow-right .tuutje {
        .transform(scaleX(-1));
        right: 5%;
      }
      &.arrow-left .tuutje {
        left: 5%;
      }
    }
  }

  .wordweb-comment {
    bottom: 2%;
  }

  .wordweb-comment-indicator {
    position: absolute;
    bottom: 2%;
    right: 2%;
    height: 20px;
    width: 25px;
    cursor: pointer;
    z-index: 12;
  }
}

.content-type-wordweb {
  #wordweb.preview {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}
.trashcan {
  position: absolute;
  height: 50px;
  width: 50px;
  left: 40px;
  bottom: 20px;
  z-index: 201;
  .lid {
    .transform-origin(2px, 13px);
    .transition(all 0.3s ease-out);
    position: absolute;
    background: url(img/trash-lid.png) no-repeat center;
    background-size: contain;
    height: 20px;
    width: 30px;
    bottom: 32px;
  }
  .can {
    position: absolute;
    background: url(img/trash-can.png) no-repeat center;
    background-size: contain;
    height: 30px;
    width: 30px;
    bottom: 10px;
  }
  &:hover,
  &.open {
    .lid {
      .transform(rotate(-45deg));
    }
  }
}
#wordwebPreview,
.content-type-wordweb {
  .body {
    .title {
      position: absolute;
      width: 100%;
      top: 50%;
      height: auto;
      .transform(translateY(-50%));
      text-align: center;
      font-family: @lessonup-title;
      text-transform: none;
      font-size: 16px;
      line-height: 1.2em;
    }
  }
}

#wordwebAnswers {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;

  #centerOfTheUniverse {
    position: absolute;
    left: 57%;
    top: 50%;
    z-index: 30;
  }

  @keyframes hithere {
    0%,
    7% {
      transform: rotateZ(0);
      transform: scale(0);
    }
    15% {
      transform: rotateZ(-15deg);
      transform: scale(1.5);
    }
    20% {
      transform: rotateZ(10deg);
      transform: scale(0.8);
    }
    25% {
      transform: rotateZ(-10deg);
      transform: scale(1.2);
    }
    30% {
      transform: rotateZ(6deg);
      transform: scale(0.9);
    }
    35% {
      transform: rotateZ(-4deg);
      transform: scale(1.1);
    }
    40%,
    100% {
      transform: rotateZ(0);
      transform: scale(1);
    }
  }

  .word {
    .transform-origin(top, left);
    cursor: pointer;
    .transition(all 0.3s ease-out);
    position: absolute;

    td {
      vertical-align: top;
    }

    &.noWrap {
      white-space: nowrap;
    }

    .bubble-outer {
    }

    .bubble-inner {
      animation: hithere 2s linear;
    }

    .count {
      .transition(all 0.3s ease-out);
      background: #fff;
      font-family: @lessonup-title;
      color: #000;
      font-size: 40px;
      line-height: 1.1em;
      text-align: center;
      padding: 5px 10px;
      border-radius: 5px;

      .placeholder {
        opacity: 0.6;
      }

      .word-count {
        font-size: 80%;
        margin-right: 8px;
        .times {
          opacity: 0.7;
          margin: 0 5px;
        }
      }
      .who {
        font-size: 80%;
        margin-left: 20px;
        opacity: 0.7;
        text-decoration: underline;
      }
      .word-preview {
      }

      #players {
        display: none;
        text-align: right;
        font-size: 60%;
        .player {
          opacity: 0.7;
          line-height: 1.2em;
        }
      }
      &:after {
        transition: none;
        content: '';
        position: absolute;
        display: block;
        border-bottom: 15px solid transparent;
        top: 98%;
      }
      &.arrow-left {
        .word-count {
          /*right: 100%; bottom: -30px;*/
        }
        &:after {
          left: 5%;
          border-left: 15px solid #fff;
          border-right: 15px solid transparent;
        }
      }
      &.arrow-right {
        .word-count {
          /*left: 100%; bottom: -30px;*/
        }
        &:after {
          right: 5%;
          border-left: 15px solid transparent;
          border-right: 15px solid #fff;
        }
      }
      &.col1 {
        background-color: #fff;
        &.arrow-left:after {
          border-left-color: #fff;
        }
        &.arrow-right:after {
          border-right-color: #fff;
        }
      }
      &.col2 {
        background-color: @yellow;
        &.arrow-left:after {
          border-left-color: @yellow;
        }
        &.arrow-right:after {
          border-right-color: @yellow;
        }
      }
      &.col3 {
        background-color: @orange;
        &.arrow-left:after {
          border-left-color: @orange;
        }
        &.arrow-right:after {
          border-right-color: @orange;
        }
      }
      &.col4 {
        color: #fff;
        background-color: @red;
        &.arrow-left:after {
          border-left-color: @red;
        }
        &.arrow-right:after {
          border-right-color: @red;
        }
      }
    }
    &:nth-child(odd) .bubble-outer {
      .animation (fly infinite 4s ease-in-out 0.5s);
    }
    &:nth-child(even) .bubble-outer {
      .animation (fly infinite 5s ease-in-out -1.5s);
    }

    &:nth-child(0) .bubble-outer {
      .animation (fly infinite 5s ease-in-out -1s);
    }
    &:nth-child(1) .bubble-outer {
      .animation (fly infinite 5s ease-in-out -2.3s);
    }
    &:nth-child(2) .bubble-outer {
      .animation (fly infinite 4.5s ease-in-out -0.3s);
    }
    &:nth-child(3) .bubble-outer {
      .animation (fly infinite 4s ease-in-out -2s);
    }
    &:nth-child(4) .bubble-outer {
      .animation (fly infinite 4.5s ease-in-out -1.4s);
    }
    &:nth-child(5) .bubble-outer {
      .animation (fly infinite 5s ease-in-out -1.1s);
    }
    &:nth-child(6) .bubble-outer {
      .animation (fly infinite 5.5s ease-in-out -2.3s);
    }
    &:nth-child(7) .bubble-outer {
      .animation (fly infinite 4s ease-in-out -0.9s);
    }
    &:nth-child(8) .bubble-outer {
      .animation (fly infinite 4.5s ease-in-out -0.3s);
    }
    &:nth-child(9) .bubble-outer {
      .animation (fly infinite 4s ease-in-out -0.7s);
    }

    &:nth-child(10) .bubble-outer {
      .animation (fly infinite 5s ease-in-out -1s);
    }
    &:nth-child(11) .bubble-outer {
      .animation (fly infinite 5s ease-in-out -1s);
    }
    &:nth-child(12) .bubble-outer {
      .animation (fly infinite 4.5s ease-in-out 0.3s);
    }
    &:nth-child(13) .bubble-outer {
      .animation (fly infinite 4s ease-in-out -2s);
    }
    &:nth-child(14) .bubble-outer {
      .animation (fly infinite 4.5s ease-in-out -1.4s);
    }
    &:nth-child(15) .bubble-outer {
      .animation (fly infinite 5s ease-in-out 1.9s);
    }
    &:nth-child(16) .bubble-outer {
      .animation (fly infinite 5.5s ease-in-out -3s);
    }
    &:nth-child(17) .bubble-outer {
      .animation (fly infinite 4s ease-in-out -2.4s);
    }
    &:nth-child(18) .bubble-outer {
      .animation (fly infinite 4.5s ease-in-out -1.1s);
    }
    &:nth-child(19) .bubble-outer {
      .animation (fly infinite 4s ease-in-out -0.7s);
    }

    &:nth-child(20) .bubble-outer {
      .animation (fly infinite 5s ease-in-out -1s);
    }
    &:nth-child(21) .bubble-outer {
      .animation (fly infinite 5s ease-in-out -1.5s);
    }
    &:nth-child(22) .bubble-outer {
      .animation (fly infinite 4.5s ease-in-out -0.5s);
    }
    &:nth-child(23) .bubble-outer {
      .animation (fly infinite 4s ease-in-out -0.8s);
    }
    &:nth-child(24) .bubble-outer {
      .animation (fly infinite 4.5s ease-in-out -1.4s);
    }
    &:nth-child(25) .bubble-outer {
      .animation (fly infinite 5s ease-in-out -1.1s);
    }
    &:nth-child(26) .bubble-outer {
      .animation (fly infinite 5.5s ease-in-out -2s);
    }
    &:nth-child(27) .bubble-outer {
      .animation (fly infinite 4s ease-in-out -2.7s);
    }
    &:nth-child(28) .bubble-outer {
      .animation (fly infinite 4.5s ease-in-out -1.1s);
    }
    &:nth-child(29) .bubble-outer {
      .animation (fly infinite 4s ease-in-out -0.7s);
    }

    &:hover {
      z-index: 200;
      .bubble-outer {
        /*.animation(none);*/
        .count {
          background-color: @cyan !important;
          color: #fff;
          &.arrow-left:after {
            border-left-color: @cyan !important;
          }
          &.arrow-right:after {
            border-right-color: @cyan !important;
          }
        }
      }
    }

    &.dragging {
      .animation(none);
      .transition(none);
      .bubble-outer {
        /*.animation(none);*/
        .count {
          background-color: @cyan !important;
          color: #fff;
          &.arrow-left:after {
            border-left-color: @cyan !important;
          }
          &.arrow-right:after {
            border-right-color: @cyan !important;
          }
        }
      }
    }

    &.show-word {
      .bubble-outer {
        .animation(none);
        .count {
          padding: 8px 20px;
        }
      }
    }
    &.show-players {
      #players {
        display: block;
      }
    }
  }
}

#wordweb.wordweb-color-9 {
  &:not(.has-image) {
    #wordwebAnswers .word .count.col1 {
      background-color: #e3e3e3;
      &.arrow-left:after {
        border-left-color: #e3e3e3;
      }
      &.arrow-right:after {
        border-right-color: #e3e3e3;
      }
    }
  }
  .balloons .balloon {
    background-color: #444444;
    .tuutje {
      fill: #444444;
    }
  }
}

#gameScreen.state-results {
  #wordwebAnswers {
    .bubble-outer {
      .animation(none) !important;
    }
    .word {
      .transition(none) !important;
    }
  }
}

@assetsUrl: /search/public;