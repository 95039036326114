@import (once) url('rootImports.less');

input[type='range'].poll-scale {
  font-size: inherit;
  box-shadow: none;
  background-color: #fff;
  border: solid 1px @dark-grey;
  border-radius: 100px;
  -webkit-appearance: none;
  position: relative;
  left: 0;
  width: 100%;
  height: 0.4em;
  padding: 0;
  min-width: 0;
  background: linear-gradient(to right, @air-blue 50%, #ffffff 50%);

  &:focus {
    outline: none;
  }
  @media (max-width: @mq-xs-max) {
    margin-top: 40px;
    height: 10px;
  }
}
input[type='range'].poll-scale::-webkit-slider-thumb {
  font-size: inherit;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 100%;
  width: 1em;
  height: 1em;
  background: @air-blue;
  box-shadow: none;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

input[type='range'].poll-scale::-moz-range-thumb {
  font-size: inherit;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 100%;
  width: 1em;
  height: 1em;
  background: @air-blue;
  box-shadow: none;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

@assetsUrl: /search/public;