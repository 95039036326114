@import (once) url('rootImports.less');
@outer-padding: @search-results-outer-padding-mobile;
@max-content-width: 500px;

.registration-page {
  @media (min-width: @registration-page-tablet-min) {
    align-items: center;
    justify-content: flex-start;
    padding-top: 6rem;

    .registration-description {
      max-width: @max-content-width;
      width: 100%;
      display: block;
      word-wrap: break-word;
    }
  }
}

.registration-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: @registration-page-tablet-min) {
    flex-direction: row;

    .registration-container-divider {
      height: 200px;
    }

    .registration-container-half-title {
      text-align: center;
    }
  }
}

.registration-container--flex-column {
  flex-direction: column;
}

.registration-terms-container {
  width: 100%;
  max-width: @max-content-width;
}

@assetsUrl: /search/public;