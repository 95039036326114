@import (once) url('./BaseSummaryCard.less');
@import (once) url('../../lessonPlan/css/LessonPlanRoot.less');

@lesson-summary-card-bottom-height: @title-height + @title-margin-bottom + @tags-height + @slide-count-height +
  @slide-count-bottom-margin;
@thumb-banner-bottom-offset: 7px;

@lesson-summary-cards-margin: 15px;

.lesson-summary-card,
.lesson-summary-card-dummy {
  width: 100%;
  min-width: 240px;
  flex: 1;

  &.lesson-summary-card--no-inset {
    padding: 0;

    &:hover {
      background: none;
    }

    .thumb-banner {
      left: -5px;
      top: ~'calc(79% - @{lesson-summary-card-bottom-height} - (@{summary-card-margin-top-bottom} * 2)) ';
    }
  }

  .lesson-summary-card__inner {
    text-decoration: none;
    height: 100%;
    display: flex;
    flex-direction: column;
    &.two-columns {
      flex-direction: row;
    }
    &.three-columns {
      flex-direction: row;
    }
  }

  .lesson-summary-card__content {
    flex: 1;
    margin-left: 10px;
    margin-right: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .lesson-summary-card__preview-pin-count {
    position: absolute;
    bottom: 10px;
    right: 0;
    color: #c3c3c3;
    font-size: 14px;
    width: 40px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-left: 4px;
    padding-right: 4px;
  }

  .lesson-summary-card__rating {
    font-size: 1.45em;
    text-align: right;

    svg {
      margin-left: 2px;
    }
  }

  .lesson-summary-card__top {
    margin-bottom: @preview-margin-bottom;
    flex: 1 1 auto;
  }

  .lesson-summary-card__bottom {
    min-height: @lesson-summary-card-bottom-height;
    overflow: hidden;
  }

  .lesson-summary-card__title {
    font-size: @title-font-size;
    font-weight: 600;
    line-height: @title-line-height;
    font-family: @lessonup-body-new, sans-serif;
    text-decoration: none;
    margin-top: 0;
    margin-bottom: @title-margin-bottom;
    max-height: @title-height;
    overflow: hidden;

    @supports (-webkit-line-clamp: @title-max-lines) {
      display: -webkit-box;
      -webkit-line-clamp: @title-max-lines;
      -webkit-box-orient: vertical;
      height: auto;
    }
  }

  .lesson-summary-card__date_note {
    color: @grey-mid-2;
  }

  .lesson-summary-card__date-date {
    color: @default-text;
  }

  .lesson-summary-card__description {
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 0.62em;
  }

  .lesson-summary-card__properties-list {
    display: flex;
    margin: 5px 0;
    margin-bottom: 7px;
  }

  .lesson-summary-card__properties-element {
    font-size: 0.6em;
    font-weight: 500;
    margin: 0 6px;
  }

  .thumb-banner {
    left: 9px;
    top: ~'calc(77% - @{lesson-summary-card-bottom-height} - (@{summary-card-margin-top-bottom} * 2)) ';
    bottom: auto;
  }

  .lesson-summary-card__meta-info {
    line-height: @slide-count-line-height;
    color: @green-mid;
    font-size: @slide-count-font-size;
    margin-bottom: @slide-count-bottom-margin;
    max-height: @slide-count-height;
    overflow: hidden;

    @supports (-webkit-line-clamp: @slide-count-max-lines) {
      display: -webkit-box;
      -webkit-line-clamp: @slide-count-max-lines;
      -webkit-box-orient: vertical;
      height: auto;
    }
  }

  .lesson-summary-card__creation-date {
    font-weight: bold;
    font-style: italic;
    font-size: 11px;
  }
}

.lesson-summary-card__icon img {
  max-width: 1em;
  max-height: 1em;
  margin-right: 0.5em;
  opacity: 0.5;
  transform: translateY(2px);
}

.lesson-summary-card-date {
  font-size: 13px;
  height: 16px;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.lesson-summary-card-duration {
  font-size: 13px;
  height: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.lesson-summary-card__content-container {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  margin-bottom: 10px;
}

.lesson-summary-card__content-block {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.lesson-summary-card__rating-label {
  text-align: right;
  display: block;
  font-weight: 500;
  font-size: 10px;
  height: 16px;
}

.lesson-summary-card__test-label {
  background-color: @red;
  text-transform: uppercase;
  border-radius: 15px;
  font-weight: 600;
  font-size: 9px;
  vertical-align: middle;
  color: white;
  padding: 0.4em 1em;
}

.lesson-summary-card__tip-label {
  background-color: @red;
  font-weight: 600;
  display: inline-block;
  position: absolute;
  width: 3em;
  height: 3em;
  text-align: center;
  line-height: 2.5em;
  font-size: 0.85em;
  color: @white;
  transform: rotate(-10deg);
  padding: 0.2em;
  border-radius: 2em;
  top: -5px;
  left: -7px;
  text-transform: uppercase;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
}

.lesson-summary-card-dummy {
  margin: 0 @summary-card-margin-side;
  padding: 0 @summary-card-padding;
}

.lesson-summary-cards {
  display: flex;
  flex-direction: row;
  margin-left: @lesson-summary-cards-margin * -1;
  margin-right: @lesson-summary-cards-margin * -1;
  flex-wrap: wrap;
}

.lesson-summary-card__tags {
  height: @tags-height;
  overflow: hidden;
}
.plan-child .base-summary-card {
  width: 254px;
}

@media (min-width: 682px) {
  .plan-child[data-cols='2'] .base-summary-card {
    width: 532px;

    .thumb-banner {
      top: ~'calc(75% - (@{summary-card-margin-top-bottom} * 2)) ';
    }

    .lesson-summary-card__inner {
      display: flex;

      .lesson-summary-card__preview {
        width: 231px;
      }
      .lesson-summary-card__bottom {
        height: 169px;
        padding-top: @lesson-summary-cards-margin;

        & > *:not(:last-child) {
          margin-bottom: @lesson-summary-cards-margin;
        }
      }
    }
  }
  .plan-child .lesson-summary-card__top {
    width: 254px;
  }
}

.plan-lesson {
  float: left;
  width: @column;
  &:hover {
    background-color: @white-2;
  }

  .thumb-banner {
    top: 100px;
  }

  .base-summary-card {
    margin: 0px;
    padding: 0px;
    &:hover {
      background-color: transparent;
    }
  }
  .lesson-summary-card__top,
  .lesson-summary-card__bottom {
    padding: 0 15px;
  }
  .lesson-summary-card__top {
    padding-top: 13px;
  }
  .lesson-summary-card__bottom {
    margin-bottom: 13px;
  }

  &.two-columns {
    width: @column2;
  }

  &.three-columns {
    width: @column3;
  }

  .two-columns {
    width: @column2;
  }

  .three-columns {
    width: @column3;
  }
}

@assetsUrl: /search/public;