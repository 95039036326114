@import (once) url('rootImports.less');

.header-cell {
  &--clickable {
    cursor: pointer;
  }

  &__sort-indicator {
    margin-left: @spacer;
  }
}

@assetsUrl: /search/public;