@import (once) url('_imports.less');

.icon-sign-out {
  background-image: url(../img/icons/black/sign-out.svg);
}

.icon-default-pos {
  background-position: center center;
  min-width: 20px;
  min-height: 20px;
}
.icon-size-25 {
  background-size: 25% auto !important;
  background-position: center center !important;
}
.icon-size-35 {
  background-size: 35% auto !important;
  background-position: center center !important;
}
.icon-size-40 {
  background-size: 40% auto !important;
  background-position: center center !important;
}
.icon-size-50 {
  background-size: 50% auto !important;
  background-position: center center !important;
}
.icon-size-55 {
  background-size: 55% auto !important;
  background-position: center center !important;
}
.icon-size-60 {
  background-size: 60% auto !important;
  background-position: center center !important;
}
.icon-size-70 {
  background-size: 70% auto !important;
  background-position: center center !important;
}
.icon-size-75 {
  background-size: 75% auto !important;
  background-position: center center !important;
}

.button-icon-size-60 {
  background-size: auto 60% !important;
}

.icon-size-1em-foreground {
  width: 1em;
  height: 1em;
  margin: 0.2em;
  vertical-align: middle;
}

/*.after-check-white {
	&:after {
		content:''; display: block; position: absolute;
		width: 12px; height: 12px; right: 3px; top: 3px;
		background: url('@{assetsUrl}/img/icons/white/checkmark.png') no-repeat;
		background-position: right top;
		background-size: contain;
		z-index: 3;
	}
}*/

@assetsUrl: /search/public;