@import (once) url('rootImports.less');

.modal-glass {
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);

  .new-modal {
    .modal-width;
    padding: 2.25rem;
    max-width: 100vw;
    @media (min-width: 640px) {
      max-width: 700px;
    }
    border-radius: 10px;
    background-color: @white;
    margin: 0 auto;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: left;

    .modal-title {
      text-align: left;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .cookie-info {
      text-align: left;
    }
  }
}

.modal-width {
  width: auto;
  &.modal--small {
    width: 400px;
  }
  &.modal--medium {
    width: 500px;
  }
  &.modal--large {
    width: 700px;
  }
}

@assetsUrl: /search/public;