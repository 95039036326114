@border-width: 1px;

//border-types
@small-solid-border: solid @border-width;

//border-characteristics
@border-round-corner: 10px;

@border-drop-shadow: 1px 1px 3px;

@filter-drop-shadow: 1px 2px 2px;

@box-shadow-color-1: #00000014;
@box-shadow-color-2: #0000001f;

// https://www.figma.com/file/xUYcF7IGNbnjEAoFnMsNA0/Design-system?node-id=2623%3A58485
.elevation-level-1 () {
  box-shadow: 0px 4px 4px -2px @box-shadow-color-1, 0px 2px 4px -2px @box-shadow-color-2;
}

.elevation-level-2() {
  box-shadow: 0px 8px 8px -4px @box-shadow-color-1, 0px 4px 6px -4px @box-shadow-color-2;
}
