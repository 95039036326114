@import (once) url('rootImports.less');
@import (once) url('./LessonPlanRoot.less');

.lesson-plan-header {
  clear: both;
  float: unset !important; // The important is necessary to override legacy styling rules.

  .header,
  .content-page-header {
    width: auto;
    max-width: @column3;
    text-align: left;
    padding: 0;
    font-weight: normal;
    .font-odin;
    text-transform: uppercase;
    font-size: 25px;
    line-height: 1.2em;
    color: @default-text;
  }
  & h2 {
    font-weight: bold;
    font-size: 25px;
    margin: 0;
  }
}

@assetsUrl: /search/public;