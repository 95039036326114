@import (once) url('rootImports.less');

@button-bar-height: @spacer*4 + 1.8rem;
@modal-spacer: 8 * @spacer;
@modal-surrounding-content-height: ~'calc(@{modal-title-height} + 2* @{modal-content-padding} + @{button-bar-height} + @{modal-spacer})';

.explorer-content-picker-modal {
  background-color: white;
  &__wrapper {
    display: flex;
  }
  &__explanation {
    margin-top: 0;
  }
  &__explorer {
    background: white;
    height: @location-picker-explorer-height;
    max-height: ~'calc(100vh - @{modal-surrounding-content-height})';
    overflow-y: auto;
  }
  &__actions {
    align-items: center;
    &__pick-folder-button {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

@assetsUrl: /search/public;