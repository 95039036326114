@import (once) url('rootImports.less');

.pin-list-vertical {
  width: 281px;
  display: flex;
  flex-direction: column;
  height: 100%;

  .pins-vertical {
    overflow: auto;
  }
}

@assetsUrl: /search/public;