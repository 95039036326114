@import (once) url('rootImports.less');

.thumb-banner {
  position: absolute;
  display: flex;
  align-items: center;
  background: @yellow;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.2em;
  padding: 4px 12px;
  color: @brown-dark;
  text-decoration: none;
  z-index: 10;
  max-width: ~'calc(100% - 40px)';
  white-space: nowrap;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);

  &.thumb-banner--icon {
    padding-left: 4px;
  }

  &:hover {
    .thumb-banner-text {
      text-decoration: underline;
    }
  }

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    left: 0;
    top: 100%;
    border-style: solid;
    border-width: 0 5px 5px 0;
    border-color: transparent darken(@yellow, 20) transparent transparent;
  }

  .thumb-banner-text {
    .ellipsis;
  }

  .thumb-banner__icon {
    margin-right: 4px;
    flex-shrink: 0;
    cursor: default;
  }
}

@assetsUrl: /search/public;